<template>
  <teleport to="#modals">
    <div
      v-if="show"
      v-touch:swipe.bottom="() => $emit('close')"
      v-touch-options="{ swipeTolerance: 150 }"
      class="photo-viewer"
    >
      <icon-btn
        v-if="hasPrev"
        class="photo-viewer__arrow-btn"
        @click="prev"
      >
        <icon
          :class="{'photo-viewer__arrow--transparent': !hasPrev}"
          :name="IconName.StoryArrowLeft"
          :type="IconType.Contour"
          :color="MainColor.White"
          :size="Size.L"
        />
      </icon-btn>
      <icon-btn
        v-if="hasNext"
        class="photo-viewer__arrow-btn photo-viewer__arrow-btn--right"
        @click="next"
      >
        <icon
          :class="{'photo-viewer__arrow--transparent': !hasNext}"
          :name="IconName.StoryArrowRight"
          :type="IconType.Contour"
          :color="MainColor.White"
          :size="Size.L"
        />
      </icon-btn>
      <div class="photo-viewer__header">
        <icon-btn
          class="photo-viewer__close"
          :size="Size.M"
          :color="MainColor.White"
          @click="$emit('close')"
        >
          <icon
            :type="IconType.Contour"
            :name="IconName.Crosses"
            :size="Size.Xs"
          />
        </icon-btn>

        <txt
          tag="div"
          class="photo-viewer__title"
          :weight="TxtWeight.Medium"
          :leading="Size.S"
        >
          {{ current + 1 }}/{{ sources.length }}
        </txt>
      </div>

      <div
        v-touch:press="handleTouchStart"
        v-touch:release="handleTouchEnd"
        class="photo-viewer__rail"
        :style="{
          transform: `translateX(-${railX}px)`,
          width: `${railWidth}px`,
        }"
      >
        <photo-viewer-item
          v-for="(source, key) in sources"
          :key="source.id"
          :src="source.path"
          @zoom="updateZoom($event, key)"
        />
      </div>
      <slot name="footer" />
    </div>
  </teleport>
</template>

<script lang="ts" src="./PhotoViewer.ts"></script>
<style lang="scss" src="./photo-viewer.scss"></style>
