import { PusherChannel, PusherEvent } from './enums';
import { BasePusher } from './BasePusher';

export class NotificationPusher extends BasePusher {
  public init(profileId: number) {
    this.channel = this.pusher?.subscribe(`${PusherChannel.Notification}.${profileId}`);
  }

  public subscribeToNotificationCreated(cb: () => void | Promise<void>) {
    this.channel?.bind(PusherEvent.NotificationCreated, cb);
    return () => this.channel?.unbind(PusherEvent.NotificationCreated, cb);
  }
}
