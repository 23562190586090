import { Nullable } from 'core/types';
import { computed, ComputedRef, ref } from 'vue';
import { StorageKeys } from './StorageKeys';

type SubscribeModel = {
  id: symbol;
  cb: (data: Nullable<string>) => void
}

const map = new Map();
const subscribeMap = new Map<string, SubscribeModel[]>();

window.addEventListener('storage', () => {
  for (const key of map.keys()) {
    const item = localStorage.getItem(key);
    map.set(key, item);
  }
});

export const storageService = {
  clearStorage() {
    localStorage.clear();
  },
  getItem(key: StorageKeys): Nullable<string> {
    if (map.has(key)) return map.get(key);
    const item = localStorage.getItem(key);
    map.set(key, item);
    return item;
  },
   setItem(key: StorageKeys, value: string): void {
     map.set(key, value);
     localStorage.setItem(key, value);
     const val = subscribeMap.get(key);
     if (val) val.forEach(({ cb }) => cb(value));
   },
   removeItem(key: StorageKeys): void {
     map.delete(key);
     localStorage.removeItem(key);
     const val = subscribeMap.get(key);
     if (val) val.forEach(({ cb }) => cb(null));
   },
  clear() {
    for (const value of subscribeMap.values()) {
      value.forEach((val) => {
        val.cb(null);
      });
    }

    map.clear();
    localStorage.clear();
  },
  subscribe(key: StorageKeys, cb: (data: Nullable<string>) => void) {
    const symbol = Symbol(key);
    const item = {
      id: symbol,
      cb,
    };

    const val = subscribeMap.get(key) ?? [];
    subscribeMap.set(key, val.concat([item]));

    return () => {
      const val = subscribeMap.get(key);
      if (!val) return;
      const index = val.findIndex((v) => v.id === symbol);
      if (index >= 0) {
        subscribeMap.set(key, val.splice(index, 1));
      }
    };
  },
  getReactiveItem(key: StorageKeys): ComputedRef<Nullable<string>> {
    const reactiveItem = ref(this.getItem(key));
    this.subscribe(key, (val) => {
      reactiveItem.value = val;
    });
    return computed(() => reactiveItem.value);
  },
};
