import { AxiosError } from 'axios';
import { i18n } from 'i18n/i18n';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useNotModerated } from 'modules/notModerated';
import { useErrorsPopup } from 'modules/errorsPopup';
import { ErrorsPopupTypes } from 'modules/errorsPopup/types';
import { useRouter } from 'vue-router';
import { RouteNames } from 'router/names';

const checkIsNotAuthenticationError = (e: any) =>
  e?.response?.status === 401 && e.response.data?.message === 'Unauthenticated.';

const checkIsServerUnavailable = (e: any) => !!(!e.response && e.request);

export function useApiErrors(name = 'ApiError') {
  const { showErrorsPopup } = useErrorsPopup();
  const router = useRouter();

  // TODO: Show InsufficientPrompt if not enough money
  function showError(error: unknown) {
    // eslint-disable-next-line no-console
    console.warn(name, error);

    let message = i18n.global.t('errors.api.error');

    const axiosError = (error as AxiosError<{ errors?: Record<string, string>; message?: string }>);

    if (checkIsServerUnavailable(error)) {
      showErrorsPopup(ErrorsPopupTypes.ServerError);
      return;
    }

    // 401 already handled in axios interceptors
    if (checkIsNotAuthenticationError(error)) return;

    const { checkAxiosError } = useNotModerated();
    if (checkAxiosError(error)) return;

    if (axiosError?.response?.data?.message === 'Недостаточно средств' || axiosError?.message === 'Недостаточно средств') {
      router.push({ name: RouteNames.ProfileWallet });
    }

    if (axiosError?.response && axiosError?.response?.status < 500 && axiosError?.response?.data) {
      if (typeof axiosError.response.data.errors === 'object') {
        const keys = Object.keys(axiosError.response.data.errors);
        if (keys.length) {
          if (Array.isArray(axiosError.response.data.errors[keys[0]])) {
            [message] = axiosError.response.data.errors[keys[0]];
          } else {
            message = axiosError.response.data.errors[keys[0]];
          }
        }
      } else if (axiosError?.response?.data?.message) {
        message = axiosError.response.data.message;
      }
    } else if ((error as Error)?.message) {
      message = (error as Error).message;
    }

    if ((axiosError?.response === null) || (axiosError?.response?.status && axiosError?.response?.status >= 500)) {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV === 'development') console.error(axiosError);
      // throw to global error handlers
      throw axiosError;
    }

    toastEmitter.emit('toast', {
      type: ToastType.Error,
      message,
    });
  }

  return {
    showError,
  };
}
