import { AxiosInstance, AxiosResponse } from 'axios';
import { IStory } from 'api/stories/models/story';
import { IHighlight } from 'api/users/models/user';

export class StoriesService {
  static init(restService: AxiosInstance) {
    return new StoriesService(restService);
  }

  constructor(private restService: AxiosInstance) {
  }

  deleteStory() {
    return this.restService.delete<{ result: boolean }>('/public/profile/highlight');
  }

  publicStory(data: {
    content: string;
    appearance: {
      theme?: string;
      text_align?: string;
      text_color?: string;
    };
    audience: [];
  }): Promise<AxiosResponse<{ data: IHighlight }>> {
    return this.restService.post('/public/highlights', data);
  }

  loadStories() {
    return this.restService.get<IStory[]>('/public/highlights');
  }
}
