import { useApiErrors } from 'composables/apiErrors';
import { METAPAGES_STORE_KEY, MetaPagesAction } from 'store/metapages';
import { PAGES_STORE_KEY, PagesAction } from 'store/pages';
import { store } from 'store/store';
import { USER_STORE_KEY, UsersAction, UsersGetter } from 'store/users';
import { RouteLocationNormalized } from 'vue-router';

const { showError } = useApiErrors();

export async function fetchCities(to: RouteLocationNormalized) {
  try {
    const isAuth = store.getters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`];
    const fetchUsers = [];
    if (isAuth) {
      fetchUsers.push(store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchTopUsers}`));
      fetchUsers.push(store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchNewUsers}`));
    } else {
      fetchUsers.push(store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchMaleUsers}`));
      fetchUsers.push(store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchFemaleUsers}`));
    }

    await Promise.all([
      store.dispatch(`${PAGES_STORE_KEY}/${PagesAction.GetPage}`, 'area'),
      store.dispatch(`${PAGES_STORE_KEY}/${PagesAction.GetPage}`, 'home'),
      store.dispatch(`${METAPAGES_STORE_KEY}/${MetaPagesAction.ListPages}`),
    ].concat(fetchUsers));
  } catch (e) {
    showError(e);
  }
}
