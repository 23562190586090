import mitt from 'mitt';
import Toasts from './Toasts';

import { IToast } from './interfaces/IToast';

export const toastEmitter = mitt<{
  toast: string | IToast,
}>();

export * from './enums/ToastType';

export * from './interfaces/IToast';

export default Toasts;
