import { ActionTree } from 'vuex';
import { ISubscriptionsState } from 'store/subscriptions/interfaces/ISubscriptionsState';
import { IStoreState } from 'store/interfaces/IStoreState';
import { SubscriptionAction } from 'store/subscriptions/enums/SubscriptionAction';
import { Api } from 'api/api';
import { SubscriptionMutation } from 'store/subscriptions/enums/SubscriptionMutation';
import { USER_STORE_KEY, UsersAction } from 'store/users';

export const SubscriptionActions: ActionTree<ISubscriptionsState, IStoreState> = {
  [SubscriptionAction.FetchSubscriptions]: async ({ commit }) => {
    const { data: { data } } = await Api.subscriptionsService.fetchSubscriptions();
    commit(SubscriptionMutation.SetSubscriptions, data);
  },
  [SubscriptionAction.RemoveSubscription]: async (_, id) => {
    await Api.subscriptionsService.deleteSubscription(id);
  },
  [SubscriptionAction.CreateSubscription]: async ({ dispatch }, payload) => {
    await Api.subscriptionsService.createSubscription({
      name: '',
      ...payload,
    });
    await dispatch(`${USER_STORE_KEY}/${UsersAction.GetProfile}`, null, { root: true });
  },
  [SubscriptionAction.FetchSubscriptionUsers]: async ({ commit }, { subscriptionId, page }: { subscriptionId: number, page?: number }) => {
    const { data } = await Api.subscriptionsService.fetchSubscriptionUsers(subscriptionId, page);
    if (!page || page <= 1) commit(SubscriptionMutation.ClearSubscriptionUsers, subscriptionId);
    commit(SubscriptionMutation.SetSubscriptionUsers, { subscriptionUsers: data, subscriptionId });
  },
  [SubscriptionAction.ActivateSubscription]: async ({ commit }, id: number) => {
    const { data: { data } } = await Api.subscriptionsService.activateSubscription(id);
    commit(SubscriptionMutation.SetSubscription, data);
  },
  [SubscriptionAction.DeactivateSubscription]: async ({ commit }, id: number) => {
    const { data: { data } } = await Api.subscriptionsService.deactivateSubscription(id);
    commit(SubscriptionMutation.SetSubscription, data);
  },
  [SubscriptionAction.FetchMessageSubscription]: async ({ commit }) => {
    const { data } = await Api.subscriptionsService.checkMessageSubscription();
    commit(SubscriptionMutation.SetMessagingSubscription, data || null);
  },
  [SubscriptionAction.PurchaseMessageSubscription]: async ({ commit }, size) => {
    const { data } = await Api.subscriptionsService.purchaseMessageSubscription(size);
    commit(SubscriptionMutation.SetMessagingSubscription, data || null);
  },

  [SubscriptionAction.ChangeSubscription]: async ({ commit }, { id, payload }) => {
    const { data: { data } } = await Api.subscriptionsService.changeSubscription(id, {
      name: '',
      ...payload,
    });

    commit(`${SubscriptionMutation.UpdateSubscription}`, data);
  },
};
