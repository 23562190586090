<template>
  <label
    :class="{
      'textfield': true,
      'textfield_focus': isFocused,
      'textfield_not-empty': !!internalValue,
      'textfield_cleanable': hasCleanIcon,
      'textfield_has-error': !!errorMessage,
      [`textfield_${style}`]: true,
      'textfield--floating-label': floatingLabel,
    }"
    @click="focus"
  >
    <span
      class="textfield__row textfield-row"
      :class="{
        'textfield__row--cursor-pointer': type === 'file',
      }"
    >
      <template v-if="left">
        <icon
          v-if="left === 'icon'"
          :type="leftIconType"
          :name="leftIconName"
          :size="Size.Xxs"
          class="textfield-row__icon textfield-row__icon_left"
        />

        <txt
          v-if="left === 'text'"
          :weight="TextWeight.Medium"
          class="textfield-row__text textfield-row__text_left"
        >
          {{ lt }}
        </txt>
      </template>

      <span class="textfield-row__input-container">
        <span
          v-if="style === TextfieldStyle.Form"
          class="textfield-row__input-label"
        >
          {{ label }}
        </span>
        <span
          v-if="type === 'file'"
          class="textfield-row__file-input-placeholder"
          :class="{
            'textfield-row__file-input-placeholder--not-empty': internalValue,
          }"
        >
          {{ internalValue || placeholder }}
        </span>

        <textarea
          v-if="textarea"
          ref="inputRef"
          :placeholder="placeholder"
          :value="internalValue"
          :rows="rows"
          class="textfield-row__input textfield-row__area"
          :maxlength="maxLength"

          @input="onInput"

          @focus="isFocused = true"
          @blur="isFocused = false"
        />
        <input
          v-else-if="type === 'tel'"
          ref="inputRef"

          v-mask="mask"
          :type="type"
          :placeholder="placeholder"
          :value="internalValue"
          :inputmode="inputmode"
          class="textfield-row__input"
          :class="{ 'textfield-row__input_phone-unactive': !isPhoneInput }"
          :maxlength="maxLength"
          @input="onInput"

          @focus="isFocused = true"
          @blur="isFocused = false"
        >
        <input
          v-else
          ref="inputRef"

          v-mask="mask"
          :type="type"
          :placeholder="placeholder"
          :value="internalValue"
          :inputmode="inputmode"
          class="textfield-row__input"
          :maxlength="maxLength"
          @input="onInput"

          @focus="isFocused = true"
          @blur="isFocused = false"
        >

        <span
          v-if="floatingLabel"
          class="textfield--floating-label-wrapper"
        >
          {{ floatingLabel }}
        </span>
      </span>

      <template
        v-if="(right || $slots.right) && !hasCleanIcon"
      >
        <slot
          v-if="$slots.right"
          class="textfield-row--shrink-0"
          name="right"
        />

        <icon
          v-else-if="right === 'icon'"
          :type="rightIconType"
          :name="rightIconName"
          :size="Size.Xxs"
          class="textfield-row__icon textfield-row__icon_right"
        />

        <txt
          v-if="right === 'text'"
          :weight="TextWeight.Medium"
          class="textfield-row__text textfield-row__text_right"
        >
          {{ rt }}
        </txt>
      </template>

      <icon-btn
        v-else-if="hasCleanIcon"
        :size="Size.Xxs"
        class="textfield-row__clean"

        @click="handleClean"
      >
        <icon
          :type="IconType.Contour"
          :name="IconName.Crosses"
          :size="Size.Xxs"
          :color="MainColor.Black2"
        />
      </icon-btn>
    </span>

    <span class="textfield__footer">
      <txt
        v-if="errorMessage"
        tag="div"
        :type="TextType.SubText"
        :leading="Size.S"
        class="textfield__error"
      >
        {{ errorMessage }}
      </txt>
      <span class="flex-1" />
      <txt
        v-if="maxLength"
        :type="TextType.SmallText"
      >{{ modelLength }}/{{ maxLength }}
      </txt>
    </span>
  </label>
</template>

<script lang="ts" src="./Textfield.ts"></script>
<style lang="scss" src="./textfield.scss"></style>
