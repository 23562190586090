import { Router } from 'vue-router';
import { useErrorsPopup } from 'modules/errorsPopup';

const isComponentResolveError = (e: Error) => !!(e.message && e.message.match('Couldn\'t resolve component'));

export function useErrorMiddleware(router: Router) {
  const { showErrorsPopup } = useErrorsPopup();

  router.onError((e) => {
    if (isComponentResolveError(e)) {
      window.location.reload();
      return;
    }

    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    else showErrorsPopup();
  });
}
