import { errorsPopupEventBus } from './eventBus';
import { ErrorsPopupTypes } from './types';

export function useErrorsPopup() {
  function showErrorsPopup(type?: ErrorsPopupTypes) {
    errorsPopupEventBus.emit('show', type);
  }

  return {
    showErrorsPopup,
  };
}
