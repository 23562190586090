<template>
  <component
    :is="tag"
    type="button"
    :class="{
      'icon-button': true,
      [`icon-button_size-${size}`]: true,
      [`icon-button_color-${color}`]: true,
    }"
    :disabled="disabled"
    @click.prevent="handleClick"
  >
    <slot />

    <span
      v-if="hasMarker"
      class="icon-button__marker"
    >
      <slot name="marker" />
    </span>
  </component>
</template>

<script lang="ts" src="./IconBtn.ts"></script>
<style lang="scss" src="./icon-btn.scss"></style>
