import { Module } from 'vuex';
import { getDefaultStoriesState } from 'store/stories/getDefaultStoriesState';
import { IStoreState } from '../interfaces/IStoreState';
import { storiesActions } from './actions';
import { storiesGetters } from './getters';
import { IStoriesState } from './interfaces/IStoriesState';
import { storiesMutations } from './mutations';

export const stories: Module<IStoriesState, IStoreState> = {
  namespaced: true,

  state: getDefaultStoriesState(),

  getters: storiesGetters,
  mutations: storiesMutations,
  actions: storiesActions,
};
