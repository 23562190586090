import { noop } from 'core/helpers';
import { MainColor, Size } from 'core/styles';
import type { Nullable } from 'core/types';
import { cond, isEmpty, T } from 'ramda';
import type { InputHTMLAttributes, PropType } from 'vue';
import { computed, defineComponent, ref, watch, toRefs, nextTick } from 'vue';
import { IconName, IconType } from '../Icon';
import { TxtWeight, TxtType } from '../Txt';
import { TextfieldStyle } from './enums/TextfieldStyle';
import type { IIconField } from './interfaces/IIconField';
import type { ISideOptions } from './interfaces/ISideOptions';

const Textfield = defineComponent({
  props: {
    type: {
      type: String as PropType<InputHTMLAttributes['type']>,
      default: 'text',
    },
    style: {
      type: String as PropType<TextfieldStyle>,
      default: TextfieldStyle.Primary,
    },
    cleanable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    mask: {
      type: String,
      default: null,
    },
    err: {
      type: String,
      default: null,
    },
    li: {
      type: Object as PropType<IIconField>,
      default: null,
    },
    ri: {
      type: Object as PropType<IIconField>,
      default: null,
    },
    lt: {
      type: String,
      default: null,
    },
    rt: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: Array as PropType<((v: Nullable<string | number>) => string | true)[]>,
      default: undefined,
    },
    shouldValidate: {
      type: Boolean,
      default: true,
    },
    inputmode: {
      type: String,
      default: undefined,
    },
    floatingLabel: {
      type: String,
      default: undefined,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
      default: undefined,
    },
    rows: {
      type: Number,
      default: 2,
    },
  },

  emits: ['update:modelValue', 'input'],

  setup(props, { emit }) {
    const {
      cleanable,
      modelValue,
      li,
      ri,
      lt,
      rt,
    } = toRefs(props);

    const internalValue = ref<Nullable<string | number>>(props.modelValue);
    const internalErrorMessage = ref<string>();
    const inputRef = ref<HTMLInputElement>();

    const modelLength = computed(() => `${internalValue.value}`.length);

    const isFocused = ref(false);

    const leftIconType = computed(() => li.value?.type);
    const leftIconName = computed(() => li.value?.name);
    const left = computed(cond([
      [() => !!li.value, () => 'icon'],
      [() => !!lt.value, () => 'text'],
      [T, noop],
    ]));

    const rightIconType = computed(() => ri.value?.type);
    const rightIconName = computed(() => ri.value?.name);
    const right = computed<Nullable<ISideOptions>>(cond([
      [() => !!ri.value, () => 'icon'],
      [() => !!rt.value, () => 'text'],
      [T, noop],
    ]));

    const hasCleanIcon = computed(() => cleanable.value && !isEmpty(internalValue.value));

    const errorMessage = computed(() => props.err || internalErrorMessage.value);

    watch(modelValue, (val) => {
      if (props.rules && props.rules.length > 0 && props.shouldValidate) {
        validate(val);
      }

      internalValue.value = val;
    });

    watch(() => props.type, async () => {
      await nextTick();
      focus();
    });

    const handleClean = () => {
      internalValue.value = '';
      emit('update:modelValue', '');
    };

    function focus() {
      inputRef.value?.focus();
    }

    function validate(value: Nullable<string | number>) {
      if (!props.rules) return false;
      const val = value || internalValue.value;
      let message = '';
      for (let index = 0; index < props.rules.length; index++) {
        const rule = props.rules[index];
        const result = rule(val);

        if (typeof result === 'string') {
          message = result;
          break;
        }
      }
      internalErrorMessage.value = message || '';
      return message === '';
    }

    function onInput(e: Event) {
      const target = e.target as HTMLInputElement;
      internalValue.value = target.value;
      emit('update:modelValue', target.value);
      emit('input', e);
    }

    const isPhoneInput = computed(() =>
      props.type === 'tel' && props.mask && internalValue.value !== props.mask.substr(0, 4));

    if (props.type === 'tel' && props.mask && !internalValue.value) {
      internalValue.value = props.mask.substr(0, 4);
    }

    return {
      internalValue,
      modelLength,
      errorMessage,

      left,
      leftIconType,
      leftIconName,

      right,
      rightIconType,
      rightIconName,

      hasCleanIcon,
      isFocused,
      isPhoneInput,

      inputRef,

      focus,
      validate,
      onInput,
      handleClean,

      TextfieldStyle,
      IconName,
      IconType,
      TextWeight: TxtWeight,
      TextType: TxtType,
      Size,
      MainColor,
    };
  },
});

export default Textfield;
