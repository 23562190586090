export enum InterestType {
  Books = 'books',
  Movies = 'movies',
  Music = 'music',
  Art = 'art',
  Architecture = 'architecture',
  Design = 'design',
  ForeignLanguages = 'foreignLanguages',
  Fashion = 'fashion',
  Dancing = 'dancing',
  Cooking = 'cooking',
  Astrology = 'astrology',
  Psychology = 'psychology',
  Space = 'space',
  Sport = 'sport',
  Extreme = 'extreme',
  Games = 'games',
  Travel = 'travel',
  Cars = 'cars',
  Aviation = 'aviation',
  Yachting = 'yachting',
  Business = 'business',
  Politics = 'politicts',
  Investments = 'investments',
  Technology = 'technology',
  Science = 'science',
}
