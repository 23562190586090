import { defineComponent, onMounted, ref } from 'vue';
import { MainPageAliases } from 'router/MainPageAliases';

const isSplashShows = ref(true);

const Splash = defineComponent({
  setup() {
    if (isSplashShows.value && !MainPageAliases.concat(['/']).includes(window.location.pathname) && !window.location.pathname.includes('welcome')) isSplashShows.value = false;

    onMounted(() => {
      if (isSplashShows.value) {
        document.addEventListener('routerAfterEach', () => {
          isSplashShows.value = false;
        }, {
          once: true,
        });
      }
    });
    return {
      isSplashShows,
    };
  },
});

export default Splash;
