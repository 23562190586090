import { MutationTree } from 'vuex';
import { IChatsState } from 'store/chats/interfaces';
import { ChatsMutation } from 'store/chats/enums';
import { Conversation, Message } from 'api/chatsService';
import { IMessageCreated } from 'pusher';
import { getDefaultChatsState } from 'store/chats/getDefaultChatsState';

export const chatsMutations: MutationTree<IChatsState> = {
  [ChatsMutation.setChats]: (state, data) => {
    state.chats = data;
  },
  [ChatsMutation.setUnread]: (state, data) => {
    state.unread = data;
  },
  [ChatsMutation.setMessagesByUserId]: (state, { userId, messages }) => {
    if (!state.messagesByUserId[userId]) {
      messages.sort((a: Message, b: Message) => (new Date(a.created_at) > new Date(b.created_at) ? 1 : -1));
      state.messagesByUserId[userId] = {
        pending: [],
        stored: messages,
      };
    } else {
      const newMessageIds: string[] = messages.map((m: Message) => m.id);
      const pending: Message[] = (state.messagesByUserId[userId].pending || [])
        .filter((m: Message) => !newMessageIds.includes(m.id || ''));

      const { stored } = state.messagesByUserId[userId];
      messages.forEach((message: Message) => {
        /* const index = stored.findIndex((v) => v.id === message.id);
        if (index >= 0) {
          stored.splice(index, 1, message);
        } else if (new Date(message.created_at) < new Date(messages[messages.length - 1].created_at)) {
          stored.unshift(message);
        } else {
          stored.push(message);
        } */

        for (let msgId = 0; msgId < stored.length; msgId++) {
          if (message.id === stored[msgId].id) {
            stored.splice(msgId, 1, message);
            return;
          }
          if (new Date(message.created_at) < new Date(stored[msgId].created_at)) {
            stored.splice(msgId, 0, message);
            return;
          }
        }
        stored.push(message);
      });

      state.messagesByUserId[userId] = {
        ...state.messagesByUserId[userId],
        pending,
        stored,
      };
    }
  },
  [ChatsMutation.setChatPagination]: (state, { userId, pagination }) => {
    // @ts-ignore
    if (!state.messagesByUserId[userId] || state.messagesByUserId[userId].pagination?.current_page > pagination.current_page) return;
    state.messagesByUserId[userId].pagination = pagination;
  },
  [ChatsMutation.deleteMessagesByUserId]: (state, userId) => {
    delete state.messagesByUserId[userId];
  },
  [ChatsMutation.addUnsentMessage]: (state, { userId, message }) => {
    const messages = state.messagesByUserId[userId] || { pending: [], stored: [] };
    messages.pending.push(message);
    state.messagesByUserId[userId] = messages;
  },
  [ChatsMutation.invalidateMessage]: (state, { userId, messageId }) => {
    const messages = state.messagesByUserId[userId].pending;
    const actualMessage = messages.find((m: Message) => m.id === messageId);
    if (!actualMessage) return;
    actualMessage.state = 'error';
  },
  [ChatsMutation.cancelMessage]: (state, { userId, messageId }) => {
    const newMessages = state.messagesByUserId[userId].pending.filter((m) => m.id !== messageId);
    state.messagesByUserId[userId].pending = newMessages;
  },
  [ChatsMutation.increaseMessagesLoadCallsCount]: (state, { userId }) => {
    state.loadMessagesCallsCount[userId] = (state.loadMessagesCallsCount[userId] || 0) + 1;
  },
  [ChatsMutation.readMessagesByUserId]: (state, userId) => {
    const now = (new Date()).toISOString();
    if (state.messagesByUserId[userId]?.stored) {
      state.messagesByUserId[userId].stored = state.messagesByUserId[userId]?.stored.map((message) => {
        if (!message.read_at && message.sender_id === userId) {
          const newMessage = { ...message };
          newMessage.read_at = now;
          return newMessage;
        }
        return message;
      });
    }
  },
  [ChatsMutation.addChat]: (state, chat: Conversation) => {
    const index = state.chats.findIndex((v) => v.id === chat.id);

    if (index >= 0) {
      state.chats.splice(index, 1, chat);
    } else {
      state.chats.unshift(chat);
    }
  },
  [ChatsMutation.ClearState]: (state) => {
    Object.assign(state, getDefaultChatsState());
  },
  [ChatsMutation.DeleteMessagesById]: (state, { userId, messageId }: { userId: number, messageId: string }) => {
    const messages = state.messagesByUserId[userId]?.stored;
    if (!messages) return;
    state.messagesByUserId[userId].stored = messages.filter((msg) => messageId !== msg.id);
  },
  [ChatsMutation.AddNewMessage]: (state, data: IMessageCreated) => {
    if (!state.messagesByUserId?.[data.senderId]?.stored) return;
    state.messagesByUserId[data.senderId].stored.push(data.message);
  },
};
