<template>
  <teleport to="#modals">
    <!--noindex-->
    <transition name="popup">
      <aside
        v-if="show"
        ref="popupRef"
        class="popup"
        :class="classes"
      >
        <slot />
      </aside>
    </transition>
    <!--/noindex-->
  </teleport>
</template>

<script lang="ts" src="./Popup.ts" />
<style lang="scss" src="./popup.scss" />
