import { IProperties } from 'api/users/models/user/interfaces/IProperties';
import { Nullable } from 'core/types';
import { IContact } from 'api/users/models/contact';
import type { IUser } from './interfaces/IUser';

export class User implements IUser {
  age;
  appearance;
  birth;
  city;
  city_id;
  created_at;
  updated_at;
  deleted_at;
  blocked_at;
  online_at;
  contacts: IContact[];
  contacts_exist: Nullable<boolean>;
  contacts_is_open: Nullable<boolean>;

  description;
  gender;
  height;
  weight;
  id;
  is_blocked;

  is_online;
  is_premium;
  is_geolocation_required;
  is_admin;
  is_favorite;

  name;
  notification_settings;
  typical_photo;
  photos;

  properties: IProperties;
  tag;
  top;
  welcome_sent_at;
  common_info;
  paid_params: any[];

  public constructor(raw: any) {
    this.age = raw.age;
    this.appearance = raw.appearance;
    this.birth = raw.birth;
    this.city = raw.city;
    this.city_id = raw.city_id;
    this.created_at = raw.created_at;
    this.updated_at = raw.updated_at;
    this.deleted_at = raw.deleted_at ?? null;
    this.blocked_at = raw.blocked_at ?? null;
    this.online_at = raw.online_at ?? null;

    this.contacts = raw.contacts ?? [];
    this.contacts_is_open = raw.contacts_is_open ?? null;
    this.contacts_exist = raw.contacts_exist ?? null;

    this.description = raw.description ?? null;
    this.gender = raw.gender;
    this.height = raw.height ?? null;
    this.weight = raw.weight ?? null;
    this.id = raw.id;
    this.is_blocked = raw.is_blocked;

    this.is_online = raw.is_online;
    this.is_premium = raw.is_premium;
    this.is_geolocation_required = raw.is_geolocation_required;
    this.is_admin = raw.is_admin;
    this.is_favorite = raw.is_favorite;

    this.name = raw.name;
    this.notification_settings = raw.notification_settings;
    this.typical_photo = raw.typical_photo ?? null;
    this.photos = raw.photos;

    this.properties = raw.properties ?? {};
    // old model support
    if (typeof this.properties.purpose === 'string') this.properties.purpose = [this.properties.purpose];
    if (typeof this.properties.activity === 'string') this.properties.activity = [this.properties.activity];

    this.tag = raw.tag ?? null;
    this.top = raw.top ?? null;
    this.welcome_sent_at = raw.welcome_sent_at ?? null;
    this.common_info = raw.common_info ?? null;
    this.paid_params = raw.paid_params || [];
  }
}
