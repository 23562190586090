export * from './SubscriptionRoutesName';

export enum RouteNames {
  Welcome = 'welcome',
  Subscriptions = 'subscriptions',
  Subscription = 'subscription',
  SubscriptionEdit = 'subscription-edit',
  SubscriptionCreate = 'subscription-create',
  Main = 'app-main',
  MainMeta = 'app-main-meta',
  MainMetaCity = 'app-main-meta-city',
  Blogs = 'blogs',
  BlogsShow = 'blogs-show',
  Catalog = 'catalog',
  SuccessCatalog = 'success-catalog',
  Favorites = 'favorites',
  CatalogFilter = 'catalog-filter',
  User = 'user',
  Profile = 'profile',
  ProfileSettings = 'profile-settings',
  ProfileWallet = 'profile-wallet',
  ProfileWalletTransactions = 'profile-wallet-transactions',
  ProfileWalletRefill = 'profile-wallet-refill',
  PaymentSuccess = 'payment-success',
  PaymentFailed = 'payment-failed',
  ProfilePhoneChange = 'profile-phone-change',
  ProfileEmailChange = 'profile-email-change',
  ProfilePasswordChange = 'profile-password-change',
  ProfileSettingsStyling = 'profile-settings-styling',
  ProfileNotificationSettings = 'profile-notification-settings',
  ProfilePasswordRestore = 'profile-password-restore',
  Rooms = 'rooms',
  Room = 'room',
  Notifications = 'notifications',
  SignUp = 'signup',
  SignIn = 'signin',
  PasswordRestore = 'password-restore',
  PasswordReset = 'password-reset',
  FillData = 'fill-data',
  Agreement = 'agreement',
  AgreementPrivacy = 'agreement-privacy',
  TechnicalSupport = 'technical-support',
  AdminPanel = 'admin-panel',
  AdminPanelServiceSettings = 'admin-panel-service-settings',
  Rules = 'rules',
  Wa = 'wa',
  NotFound = 'not-found',
  SuccessEmailConfirm = 'success-email-confirmation',
  AuthLog = 'auth-log',
  Tutorial = 'tutorial',
}
