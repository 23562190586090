export enum PusherEvent {
  MessageCreated = 'message-created',
  MessageReaded = 'message-readed',
  MessageDeleted = 'message-deleted',
  SubscriptionSucceeded = 'pusher:subscription_succeeded',
  ConversationDropped = 'conversation-dropped',
  ClientIsTyping = 'client-is-typing',
  ClientStoppedTyping = 'client-stopped-typing',
  NotificationCreated = 'notification-created',
  ModeratedChange = 'moderated-change'
}
