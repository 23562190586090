import { AxiosInstance, AxiosResponse } from 'axios';
import { ISubscription } from 'api/users/models/user/interfaces/ISubscription';
import { IUserFilter } from 'api/users/models/user/interfaces/IUserFilter';
import { IApiPaginateResponse } from 'api/api-request';
import { IUser } from 'api/users/models/user';

export interface ICheckMessageSubscription {
  id: number;
  created_at: string;
  updated_at: string;
  expires_at: string;
  user_id: number;
  active: boolean;
}

export interface ICreateSubscription extends IUserFilter {
  name: string;
}

export interface ICreateSubscriptionResponse {
  audience: IUserFilter;
  created_at: string;
  expired_at: string;
  id: number;
  name: string;
  updated_at: string;
}

export class SubscriptionsService {
  static init(restService: AxiosInstance): SubscriptionsService {
    return new SubscriptionsService(restService);
  }

  constructor(private restService: AxiosInstance) {
  }

  fetchSubscriptions() {
    return this.restService.get< { data: ISubscription[] }>('/public/subscriptions');
  }

  fetchSubscriptionUsers(subscriptionId: number, page?: number) {
    return this.restService.get<IApiPaginateResponse<IUser[]>>(`/public/subscriptions/${subscriptionId}/users`, {
      params: {
        page,
      },
    });
  }

  createSubscription(model: ICreateSubscription): Promise<AxiosResponse<ICreateSubscriptionResponse>> {
    return this.restService.post('/public/subscriptions', model);
  }

  changeSubscription(id: number, model: ICreateSubscription): Promise<AxiosResponse<{ data: ISubscription }>> {
    return this.restService.put(`/public/subscriptions/${id}`, model);
  }

  deleteSubscription(id: number): Promise<AxiosResponse<{ success: boolean }>> {
    return this.restService.delete(`/public/subscriptions/${id}`);
  }

  activateSubscription(id: number) {
    return this.restService.post<{ data: ISubscription }>(`/public/subscriptions/${id}/restore`);
  }

  deactivateSubscription(id: number) {
    return this.restService.post<{ data: ISubscription }>(`/public/subscriptions/${id}/deactivate`);
  }

  checkMessageSubscription() {
    return this.restService.get<ICheckMessageSubscription | undefined>('/public/subscriptions/messaging/check');
  }

  purchaseMessageSubscription(size: 'small' | 'medium' | 'max'): Promise<AxiosResponse<ICheckMessageSubscription>> {
    return this.restService.post('/public/subscriptions/messaging/purchase', {
      size,
    });
  }
}
