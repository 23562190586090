import { RouteNames } from 'router/names';
import { RouteLocationNormalized } from 'vue-router';

export function checkVerifyEmail(to: RouteLocationNormalized) {
  if (to.query['verify-email'] === 'true') {
    return {
      name: RouteNames.SuccessEmailConfirm,
    };
  }
  return true;
}
