import { Router } from 'vue-router';
import { usePageLoader } from 'composables/pageLoader';

export function usePageLoaderMiddleware(router: Router) {
  const pageLoader = usePageLoader();

  router.beforeEach(() => {
    pageLoader.startLoading();
  });

  router.afterEach(() => {
    pageLoader.endLoading();
  });
}
