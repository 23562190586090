import { notModeratedPopupEventBus } from 'modules/notModerated/eventBus';
import { ModeratedActions } from 'modules/notModerated/types';
import { AxiosError } from 'axios';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersAction, UsersGetter } from 'store/users';
import { Nullable } from 'core/types';
import { IProfile } from 'api/users/models/user';
import { ModeratedReason } from 'api/users/models/user/enums/ModeratedReason';

function createNotModeratedConfigChecker(error: AxiosError) {
  return (regexp: RegExp, method = 'post') =>
    error?.config?.method === method && error?.config?.url && regexp.test(error.config.url);
}

const regExps: Record<ModeratedActions, RegExp> = {
  [ModeratedActions.SendMessage]: /public\/users\/\d+\/messages/,
  [ModeratedActions.Like]: /public\/users\/\d+\/like/,
  [ModeratedActions.Unlike]: /public\/users\/\d+\/unlike/,
};

export function useNotModerated() {
  const store = useStore();

  function handleNotModeratedError(error: AxiosError) {
    const checker = createNotModeratedConfigChecker(error);
    for (const [key, value] of Object.entries(regExps)) {
      if (checker(value)) {
        // @ts-ignore
        showPopup(key);
        break;
      }
    }
  }

  function checkAxiosError(e: any): boolean {
    if (!(e?.response?.status === 422 && e.response?.data?.message === 'Not moderated')) return false;
    handleNotModeratedError(e as AxiosError);
    store?.dispatch(`${USER_STORE_KEY}/${UsersAction.GetProfile}`);
    return true;
  }

  // @todo убрать дублирующию логику, бэкенд нам и так возвращает когда и где человек на модерации
  function checkModerate(action: ModeratedActions): boolean {
    return true;
    const profile = store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`] as Nullable<IProfile>;
    if (profile?.moderated === ModeratedReason.ModeratedTrue) return true;
    showPopup(action);
    return false;
  }

  function showPopup(action: ModeratedActions) {
    notModeratedPopupEventBus.emit('show', action);
  }

  return {
    checkModerate,
    checkAxiosError,
  };
}
