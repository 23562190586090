import { Module } from 'vuex';
import { ISubscriptionsState } from 'store/subscriptions/interfaces/ISubscriptionsState';
import { IStoreState } from 'store/interfaces/IStoreState';
import { SubscriptionActions } from 'store/subscriptions/actions';
import { SubscriptionGetters } from 'store/subscriptions/getters';
import { SubscriptionMutations } from 'store/subscriptions/mutations';
import { getDefaultSubscriptionsState } from 'store/subscriptions/getDefaultSubscriptionsState';

export const subscriptions: Module<ISubscriptionsState, IStoreState> = {
  namespaced: true,

  state: getDefaultSubscriptionsState(),

  mutations: SubscriptionMutations,
  actions: SubscriptionActions,
  getters: SubscriptionGetters,
};
