import { defineComponent } from 'vue';
import { usePageLoader } from 'composables/pageLoader';

const PageLoader = defineComponent({
  setup() {
    return {
      ...usePageLoader(),
    };
  },
});

export default PageLoader;
