import type { Module } from 'vuex';
import { getDefaultNotificationsState } from 'store/notifications/getDefaultNotificationsState';
import { notificationsGetters } from './getters';
import { notificationsMutations } from './mutations';
import { notificationsActions } from './actions';
import type { IStoreState } from '../interfaces/IStoreState';
import type { INotificationsState } from './interfaces';

export * from './enums';

export * from './hooks';

export const notifications: Module<INotificationsState, IStoreState> = {
  namespaced: true,

  state: getDefaultNotificationsState(),

  getters: notificationsGetters,
  mutations: notificationsMutations,
  actions: notificationsActions,
};
