import { defineComponent, ref } from 'vue';
import { TxtType } from 'components/Txt';
import { BtnType } from 'components/Btn';
import { RouteNames } from 'router/names';
import { MajorityValues, StorageKeys, storageService } from 'storage';
import { Nullable } from 'core/types';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { useRoute } from 'vue-router';
import image from './assets/image.raw.svg';

const exceptionRoutes: any = [RouteNames.Welcome];

const MajorityPopup = defineComponent({
  setup() {
    const route = useRoute();
    const store = useStore();
    const isShows = ref(false);

    document.addEventListener('routerAfterEach', () => {
      if (exceptionRoutes.includes(route.name)) return;
      if (store.getters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`]) {
        storageService.setItem(StorageKeys.IsMajority, MajorityValues.Majority);
        return;
      }

      const isMajority = storageService.getItem(StorageKeys.IsMajority) as Nullable<MajorityValues>;
      switch (isMajority) {
        case MajorityValues.Majority:
          break;
        case MajorityValues.NotMajority:
        default:
          isShows.value = true;
      }
    });

    function handleNotMajorityClick() {
      storageService.setItem(StorageKeys.IsMajority, MajorityValues.NotMajority);
      isShows.value = false;
    }

    function handleMajorityClick() {
      storageService.setItem(StorageKeys.IsMajority, MajorityValues.Majority);
      isShows.value = false;
    }

    return {
      image,
      isShows,
      handleMajorityClick,
      handleNotMajorityClick,

      TxtType,
      BtnType,
      RouteNames,
    };
  },
});

export default MajorityPopup;
