export enum MainColor {
  Black = 'black',
  Black2 = 'black-2',
  DarkGrey = 'dark-grey',
  Grey5 = 'grey-5',
  Grey4 = 'grey-4',
  Grey3 = 'grey-3',
  Grey2 = 'grey-2',
  Grey1 = 'grey-1',
  Grey0 = 'grey-0',
  White = 'white',
  Violet = 'violet',
  Red = 'red',
  Green = 'green',
  Blue = 'dark-blue',
  Gold = 'gold',
}
