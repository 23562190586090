import { AxiosInstance, AxiosResponse } from 'axios';
import { IApiPaginateResponse } from './api-request';
import { IBlog } from './blogs/models';

export class BlogsService {
  static init(restService: AxiosInstance): BlogsService {
    return new BlogsService(restService);
  }

  constructor(private restService: AxiosInstance) {}

  fetchBlogs(page = 1) {
    return this.restService.get<IApiPaginateResponse<IBlog>>('/public/blog', {
      params: { page },
    });
  }

  fetchBlogById(id: number) {
    return this.restService.get<AxiosResponse<IBlog>>(`/public/blog/${id}`);
  }

  fetchBlogBySlug(slug: string) {
    return this.restService.get<AxiosResponse<IBlog>>(`/public/blog/${slug}`);
  }
}
