import { useStore } from 'vuex';
import { APP_STORE_KEY, AppGetter } from 'store/app';
import { computed } from 'vue';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { ServiceSettingsSubject } from 'api/adminService';
import { PaidPackageKeys, PaidPackageParamsType } from 'api/users/models/paidPackages/enums';
import { IProfilePaidParams } from 'api/users/models/user/interfaces/IProfilePaidParams';
import { Nullable } from 'core/types';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';

type ServiceInfo = {
  price: number;
  days: number;
  hours: number;
  isBought: boolean;
  boughtText: string;
}

export enum ServiceNames {
  Highlight = 'Highlight',
  Subscription = 'Subscription',
  TakeMeToTheTop = 'TakeMeToTheTop',
  MessageSubscriptionSmall = 'MessageSubscriptionSmall',
  MessageSubscriptionMedium = 'MessageSubscriptionMedium',
  MessageSubscriptionMax = 'MessageSubscriptionMax',
  RemoveAllMessages = 'RemoveAllMessages',
  Incognito1 = 'Incognito1',
  Incognito6 = 'Incognito6',
  ContactView1 = 'ContactView1',
  ContactView5 = 'ContactView5',
  ContactView10 = 'ContactView10',
  ContactViewUnlim1Month = 'ContactViewUnlim1Month',
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function useServiceSettings(name: ServiceNames, withoutGender = false) {
  const { t } = useI18n();
  const store = useStore();
  const profileParams =
    computed<IProfilePaidParams[]>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.ProfilePaidParams}`]);
  const profile = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);
  const gender = computed<string>(() => (!withoutGender && profile.value && profile.value.gender) || '');

  const timeStr = computed(() => ServiceSettingsSubject[`${name}Time${capitalizeFirstLetter(gender.value)}`]);
  const time = computed(
    () => store.getters[`${APP_STORE_KEY}/${AppGetter.ServiceSettingValue}`](timeStr.value),
  );

  const priceStr = computed(() => ServiceSettingsSubject[`${name}Cost${capitalizeFirstLetter(gender.value)}`]);
  const price = computed(
    () => store.getters[`${APP_STORE_KEY}/${AppGetter.ServiceSettingValue}`](priceStr.value),
  );

  function hasBoughtService(key: PaidPackageKeys): Nullable<IProfilePaidParams> {
    const param = profileParams.value.find((p) => p.key === key);
    if (!param) return null;
    if (param.type === PaidPackageParamsType.Count) return +param.value > 0 ? param : null;

    return (param.expired_at && new Date(param.expired_at) > new Date()) ? param : null;
  }

  const boughtPackage = computed<Nullable<IProfilePaidParams>>(() => {
    switch (name) {
      case ServiceNames.Highlight: return hasBoughtService(PaidPackageKeys.DeadlineAllowFreePublishStories);
      case ServiceNames.Subscription: return hasBoughtService(PaidPackageKeys.DeadlineAllowFreeSubscribeUsers)
        || hasBoughtService(PaidPackageKeys.AllowToSubscribeUsers);
      case ServiceNames.TakeMeToTheTop: return hasBoughtService(PaidPackageKeys.ToTop);
      case ServiceNames.RemoveAllMessages: return hasBoughtService(PaidPackageKeys.DeadlineAllowFreeDeleteChats);
      default: return null;
    }
  });

  const boughtText = computed(() => {
    if (!boughtPackage.value) return '';
    if (boughtPackage.value.type === PaidPackageParamsType.Count) {
      return t(`components.serviceSettings.count.${boughtPackage.value.key}`, {
        n: boughtPackage.value.value,
      }, +boughtPackage.value.value);
    }
    if (boughtPackage.value.type === PaidPackageParamsType.DurationForever) {
      return t(`components.serviceSettings.forever.${boughtPackage.value.key}`);
    }
    if (!boughtPackage.value.expired_at) return '';
    return t(`components.serviceSettings.packages.${boughtPackage.value.key}`, {
      date: dayjs(new Date(boughtPackage.value.expired_at)).format('D MMMM YYYY'),
    });
  });

  return computed<ServiceInfo>(() => {
    const serviceInfo = {
      price: 0,
      hours: 0,
      days: 0,
      isBought: !!boughtPackage.value,
      boughtPackage: boughtPackage.value,
      boughtText: boughtText.value,
    };
    if (time.value?.value) {
      const parsedNum = parseFloat(time.value.value);
      if (!Number.isNaN(parsedNum)) {
        serviceInfo.days = Math.round(parsedNum / 60 / 24);
        serviceInfo.hours = Math.round(parsedNum / 60);
      }
    }
    if (price.value?.value) {
      const parsedNum = parseFloat(price.value.value);
      if (!Number.isNaN(parsedNum)) serviceInfo.price = parsedNum;
    }
    return serviceInfo;
  });
}
