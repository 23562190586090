import { PusherChannel, PusherEvent } from './enums';
import { IMessageCreated, IMessageDeleted, IMessageReaded, IConversationDropped } from './interfaces';
import { BasePusher } from './BasePusher';

export class RoomPusher extends BasePusher {
  public init(profileId: number) {
    this.channel = this.pusher?.subscribe(`${PusherChannel.Room}.${profileId}`);
  }

  public subscribeToMessageCreated(cb: (data: IMessageCreated) => void | Promise<void>) {
    this.channel?.bind(PusherEvent.MessageCreated, cb);
    return () => this.channel?.unbind(PusherEvent.MessageCreated, cb);
  }

  public subscribeToMessageReaded(cb: (data: IMessageReaded) => void | Promise<void>) {
    this.channel?.bind(PusherEvent.MessageReaded, cb);
    return () => this.channel?.unbind(PusherEvent.MessageReaded, cb);
  }

  public subscribeToMessageDeleted(cb: (data: IMessageDeleted) => void | Promise<void>) {
    this.channel?.bind(PusherEvent.MessageDeleted, cb);
    return () => this.channel?.unbind(PusherEvent.MessageDeleted, cb);
  }

  public subscribeToConversationDropped(cb: (data: IConversationDropped) => void | Promise<void>) {
    this.channel?.bind(PusherEvent.ConversationDropped, cb);
    return () => this.channel?.unbind(PusherEvent.ConversationDropped, cb);
  }
}
