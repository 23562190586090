import { computed, defineComponent } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { RouteNames } from 'router/names';
import { useStore } from 'vuex';
import { Nullable } from 'core/types';
import { IProfile } from 'api/users/models/user';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import ExpandedAlert from 'components/ExpandedAlert';

const TutorialTip = defineComponent({
  components: {
    ExpandedAlert,
  },
  setup() {
    const store = useStore();

    const isAuth = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`]);

    const allRewardReceived =
      computed<Nullable<IProfile>>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.AllRewardsReceived}`]);

    return {
      isAuth,
      allRewardReceived,

      IconName,
      IconType,
      RouteNames,
    };
  },
});

export default TutorialTip;
