<template>
  <div
    class="copy-text"
    :class="`copy-text--${activeThemeColor}`"
    @click="handleClick"
  >
    <slot />
  </div>
</template>

<script lang="ts" src="./CopyText.ts" />
<style lang="scss" src="./copyText.scss" />
