export enum FieldType {
  Finance = 'finance',
  Tourism = 'tourism',
  Education = 'education',
  It = 'it',
  Medicine = 'medicine',
  Trade = 'trade',
  Industry = 'industry',
  Sport = 'sport',
  Art = 'art',
  Other = 'other',
}
