import { useStore } from 'vuex';
import { StoreInjectionKey } from '../../constants';
import { STORIES_STORE_KEY } from '../constants';
import { StoriesGetter } from '../enums/StoriesGetter';

export const useStoriesGetter = <R = unknown>(key: StoriesGetter): R => {
  const store = useStore<typeof StoreInjectionKey>();

  return store.getters[`${STORIES_STORE_KEY}/${key}`];
};
