import { Module } from 'vuex';
import { IAppState } from 'store/app/interfaces/IAppState';
import { IStoreState } from 'store/interfaces/IStoreState';
import { AppMutations } from 'store/app/mutations';
import { AppGetters } from 'store/app/getters';
import { AppActions } from 'store/app/actions';
import { getDefaultAppState } from 'store/app/getDefaultAppState';

export const app: Module<IAppState, IStoreState> = {
  namespaced: true,

  state: getDefaultAppState(),
  mutations: AppMutations,
  getters: AppGetters,
  actions: AppActions,
};
