import { Nullable } from 'core/types';

let scrollPosition: Nullable<number> = null;

export function hideScroll(hide: boolean) {
  if (hide) {
    scrollPosition = window.scrollY;
    document.body.classList.add('_no-scrolls');
  } else {
    document.body.classList.remove('_no-scrolls');
    if ((scrollPosition != null) && window.scrollY !== scrollPosition) {
      window.scroll(0, scrollPosition);
    }
    scrollPosition = null;
  }
}
