<template>
  <!-- $route.name === RouteNames.Main - Vue 3 bug workaround  -->
  <expanded-alert
    :is-show="$route.name === RouteNames.Main && !allRewardReceived && isAuth"
    :icon-type="IconType.Contour"
    :icon-name="IconName.LightBulb"
  >
    <router-link
      :to="{ name: RouteNames.Tutorial }"
      class="tutorial-tip__text"
    >
      {{ $t('pages.main.tutorialTip') }}
    </router-link>
  </expanded-alert>
</template>

<script lang="ts" src="./TutorialTip.ts" />
<style lang="scss" src="./tutorialTip.scss" />
