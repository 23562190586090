import { Size, MainColor } from 'core/styles';
import { clamp } from 'ramda';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  toRefs,
  watchEffect,
  watch,
} from 'vue';
import type { FileSource } from 'core/types';
import { hideScroll } from 'core/helpers';
import { IconName, IconType } from '../Icon';
import { TxtType, TxtWeight } from '../Txt';
import { BtnType } from '../Btn';
import PhotoViewerItem from './PhotoViewerItem';

const PhotoViewer = defineComponent({
  components: {
    PhotoViewerItem,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    sources: {
      type: Array as PropType<FileSource[]>,
      required: true,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
  },

  emits: ['close', 'update:activeIndex', 'delete', 'uploaded'],

  setup(props, { emit }) {
    const {
      show,
      sources,
    } = toRefs(props);

    const fileInput = ref<HTMLInputElement>();

    const width = ref(460);
    const current = ref(props.activeIndex);
    const railX = ref(0);

    const railWidth = computed(() => width.value * sources.value.length);

    watch(() => props.activeIndex, (i) => {
      current.value = i;
    });

    watch(current, (i) => {
      emit('update:activeIndex', i);
    });

    const handleResize = (): void => {
      width.value = window.innerWidth;
    };

    const handleSwipe = (d: 'left' | 'right'): void => {
      const dir: Record<typeof d, number> = {
        left: 1,
        right: -1,
      };

      current.value = clamp(0, sources.value.length - 1, current.value + dir[d]);
    };

    const prev = () => {
      current.value = clamp(0, sources.value.length - 1, current.value - 1);
    };
    const next = () => {
      current.value = clamp(0, sources.value.length - 1, current.value + 1);
    };
    const hasPrev = computed(() => current.value > 0);
    const hasNext = computed(() => current.value < sources.value.length - 1);
    watchEffect(() => {
      railX.value = width.value * current.value;
      hideScroll(show.value);
    }, { flush: 'post' });

    onMounted(() => {
      window.addEventListener('resize', handleResize, false);
      handleResize();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize, false);
    });

    const photoItemZooms: number[] = [];
    function updateZoom(zoom: number, key: number) {
      photoItemZooms[key] = zoom;
    }

    const touchEvent: any = {
      x: null,
      y: null,
      time: null,
    };
    function getClientXY(e: any) {
      if (e.touches?.length) {
        return {
          x: e.touches[0].screenX,
          y: e.touches[0].screenY,
        };
      }
      if (e.changedTouches?.length) {
        return {
          x: e.changedTouches[0].screenX,
          y: e.changedTouches[0].screenY,
        };
      }
      return {
        x: e.screenX,
        y: e.screenY,
      };
    }
    function handleTouchStart(e: TouchEvent | MouseEvent) {
      const clientXY = getClientXY(e);
      touchEvent.x = clientXY.x;
      touchEvent.y = clientXY.y;
      touchEvent.time = e.timeStamp;
    }
    function handleTouchEnd(e: TouchEvent | MouseEvent) {
      const windowWidth = window.innerWidth;
      try {
        if (typeof touchEvent.x === null || typeof touchEvent.y === null) return;
        const clientXY = getClientXY(e);
        const dx = touchEvent.x - clientXY.x;
        const dy = touchEvent.y - clientXY.y;
        const time = e.timeStamp - touchEvent.time;
        const speed = (1000 * Math.abs(dx)) / windowWidth / time;
        if (Math.abs(dx) < Math.abs(dy)
          || Math.abs(dx) < 30
          || speed < 0.65
          || Math.abs(photoItemZooms[current.value] - 1) > 0.1
        ) return;

        handleSwipe(dx < 0 ? 'right' : 'left');
      } finally {
        touchEvent.x = null;
        touchEvent.y = null;
        touchEvent.time = null;
      }
    }

    return {
      fileInput,
      current,
      railWidth,
      railX,
      prev,
      next,

      updateZoom,
      handleTouchStart,
      handleTouchEnd,
      hasPrev,
      hasNext,

      IconType,
      IconName,
      TxtWeight,
      TxtType,
      Size,
      MainColor,
      BtnType,
    };
  },
});

export default PhotoViewer;
