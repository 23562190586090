const MAX_SIZE = parseInt(process.env.VUE_APP_MAX_IMAGE_DIMENSION || '2000', 10);

function getResizedImageDimensions(image: HTMLImageElement) {
  const aspectRatio = getAspectRatio(image);
  if (image.naturalWidth < MAX_SIZE && image.naturalHeight < MAX_SIZE) {
 return {
    width: image.naturalWidth,
    height: image.naturalHeight,
  };
}
  if (aspectRatio > 1) {
    return {
      width: MAX_SIZE,
      height: MAX_SIZE / aspectRatio,
    };
  }
  return {
    height: MAX_SIZE,
    width: MAX_SIZE * aspectRatio,
  };
}

function getAspectRatio(image: HTMLImageElement) {
  return image.naturalWidth / image.naturalHeight;
}

export function resizeImage(imageSrc: string): Promise<string> {
  return new Promise((res) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const { width, height } = getResizedImageDimensions(image);

      canvas.width = width;
      canvas.height = height;

      ctx?.drawImage(image, 0, 0, width, height);

      res(canvas.toDataURL('image/png'));
    };
  });
}
