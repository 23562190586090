import { SubscriptionGetter } from 'store/subscriptions/enums/SubscriptionGetter';
import { GetterTree } from 'vuex';
import { ISubscriptionsState } from 'store/subscriptions/interfaces/ISubscriptionsState';
import { IStoreState } from 'store/interfaces/IStoreState';

export const SubscriptionGetters: GetterTree<ISubscriptionsState, IStoreState> = {
  [SubscriptionGetter.Subscriptions]: (state) => state.subscriptions,
  [SubscriptionGetter.Subscription]: (state) =>
    (id: number) => (state.subscriptions && state.subscriptions.find((sub) => sub.id === id)) || null,
  [SubscriptionGetter.SubscriptionUsers]: (state) =>
    (subscriptionId: number) => (state.subscriptionUsers[subscriptionId]) || null,
};
