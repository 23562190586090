import { createGtm } from '@gtm-support/vue-gtm';
import { App } from '@vue/runtime-core';
import { Router } from 'vue-router';

export default function addTagManger(app: App, router: Router) {
  const GTM_CONTAINER_ID = `${process.env.VUE_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID}`;
  if (GTM_CONTAINER_ID.length < 50) return;

  const gtm = createGtm({
    id: GTM_CONTAINER_ID,
    defer: true,
    compatibility: false,
    nonce: `${process.env.VUE_APP_GOOGLE_TAG_MANAGER_NONCE}`,
    enabled: true,
    debug: false,
    loadScript: true,

    // @ts-ignore <- ignore it
    vueRouter: router,

    ignoredViews: [],
    trackOnNextTick: false
  });

  app.use(gtm);
}
