import { MainColor, Size } from 'core/styles';
import type { Nullable } from 'core/types';
import { pathOr } from 'ramda';
import type { PropType } from 'vue';
import { computed, defineComponent, toRefs } from 'vue';
import { IconName } from './enums/IconName';
import { IconType } from './enums/IconType';
import { IconSet } from './IconSet';

const Icon = defineComponent({
  props: {
    name: {
      type: String as PropType<IconName>,
      required: true,
    },
    type: {
      type: String as PropType<IconType>,
      required: true,
    },
    size: {
      type: String as PropType<Size>,
      default: Size.M,
    },
    color: {
      type: String as PropType<Nullable<MainColor>>,
      default: null,
    },
  },

  setup(props) {
    const {
      type,
      name,
    } = toRefs(props);

    const iconId = computed(() => pathOr(null, [type.value, name.value, 'id'], IconSet));

    return { iconId };
  },
});

export default Icon;
