import { defineComponent, h, ref, computed, Teleport, TransitionGroup } from 'vue';
import { useRoute } from 'vue-router';
import { getUid } from 'core/helpers';
import Icon, { IconName, IconType } from 'components/Icon';
import IconBtn from 'components/IconBtn';
import { Size } from 'core/styles';
import NoPrerender from 'components/NoPrerender';
import { IToast } from './interfaces/IToast';
import { ToastType } from './enums/ToastType';
import { toastEmitter } from './index';

import './toasts.scss';

const TIMEOUT = 10000;

export default defineComponent({
  setup() {
    const route = useRoute();
    const toasts = ref<(IToast & { id: number })[]>([]);

    const pageOffset = computed(() => {
      switch (route.name) {
        case 'room': return 52;
        default: return undefined;
      }
    });

    toastEmitter.on('toast', (toast) => {
      const item = typeof toast === 'string' ? {
        type: ToastType.Primary,
        message: toast,
      } : toast;
      addToast(getUid(), item);
    });

    function addToast(id: number, toast: IToast) {
      const toastId = toasts.value.findIndex((item) => item.message === toast.message);
      if (toastId >= 0 && toasts.value[toastId].timeout) {
        clearTimeout(toasts.value[toastId].timeout);
        toasts.value[toastId].timeout = setTimeout(() => {
          removeToast(id);
        }, TIMEOUT) as unknown as number;

        return;
      }

      const timeout = setTimeout(() => {
        removeToast(id);
      }, TIMEOUT) as unknown as number;
      toasts.value.push({ id, ...toast, timeout });
    }

    function removeToast(id: number) {
      const index = toasts.value.findIndex((item) => item.id === id);
      if (index !== -1) {
        toasts.value.splice(index, 1);
      }
    }

    function onClick(id: number) {
      removeToast(id);
    }

    return () => h(Teleport, {
      to: '#toasts',
    }, h(NoPrerender, () => h(TransitionGroup, {
      name: 'toasts',
    }, {
      default: () => toasts.value.map(item => h('div', {
        key: item.id,
        class: {
          toast: true,
          'toast--success': item.type === ToastType.Success,
          'toast--error': item.type === ToastType.Error,
        },
        style: {
          bottom: pageOffset.value ? `${pageOffset.value}px` : (item.offset ? `${item.offset}px` : undefined),
        },
      }, [
        item.message,
        h(IconBtn, {
          onClick: () => onClick(item.id),
          class: 'toast__icon-btn',
          size: Size.L,
        }, () => h(Icon, {
          name: IconName.Crosses,
          type: IconType.Contour,
          size: Size.Xxs,
          class: 'toast__icon',
        })),
      ])),
    })));
  },
});
