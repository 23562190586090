import { GetterTree } from 'vuex';
import { IAppState } from 'store/app/interfaces/IAppState';
import { IStoreState } from 'store/interfaces/IStoreState';
import { AppGetter } from 'store/app/enums/AppGetter';
import { ServiceSettingsSubject } from 'api/adminService';

export const AppGetters: GetterTree<IAppState, IStoreState> = {
  [AppGetter.ServiceSettings]: ({ serviceSettings }) => serviceSettings,
  [AppGetter.ServiceSettingValue]: ({ serviceSettings }) => (subject: ServiceSettingsSubject) => {
    if (!serviceSettings) return null;

    const setting = serviceSettings.find((v) => v.subject === subject);
    return setting ?? null;
  },
  [AppGetter.TabBarOnTop]: ({ tabBarOnTop }) => tabBarOnTop,
};
