type EventCB = (event: any) => void;

export function createEventBus() {
  const allHandlers = new Map<string, EventCB[]>();

  return {
    on(type: string, handler: EventCB) {
      let handlers = allHandlers.get(type);
      if (!handlers) {
        handlers = [handler];
      } else {
        handlers.push(handler);
      }

      allHandlers.set(type, handlers);
    },

    off(type: string, handler: EventCB) {
      const handlers = allHandlers.get(type);
      if (handlers) {
        // eslint-disable-next-line no-bitwise
        handlers.splice(handlers.indexOf(handler) >>> 0, 1);
      }
    },

    emit(type: string, evt?: any) {
      const handlers = allHandlers.get(type);
      if (handlers) {
        handlers.slice().map((handler) => {
          handler(evt);
          return undefined;
        });
      }
    },
  };
}
