import { AxiosInstance, AxiosResponse } from 'axios';

export class DadataService {
  static init(restService: AxiosInstance): DadataService {
    return new DadataService(restService);
  }

  constructor(private restService: AxiosInstance) {
  }

  headers = {
    Accept: 'application/json',
    Authorization: `Token ${process.env.VUE_APP_DADATA_APP_KEY}`,
  };

  searchAddresses(query: string, count = 10): Promise<AxiosResponse<any>> {
    return this.restService.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
      query,
      count,
      language: 'ru',
    }, {
      headers: this.headers,
    });
  }
}
