<template>
  <teleport to="#notifications">
    <transition name="page-loader">
      <div v-if="isLoaderShow" class="page-loader">
        <div
          class="page-loader__progress"
          :style="{ width: `${progress}%` }"
        />
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts" src="./PageLoader.ts" />
<style lang="scss" src="./pageLoader.scss" />
