<template>
  <popup
    class="errors-popup"
    :class="`errors-popup--${type}`"
    :show="isShow"
  >
    <template v-if="type === ErrorsPopupTypes.ServerError">
      <img
        src="./assets/serverError.svg"
        alt="server-error"
      >

      <txt
        class="mt-3"
        :type="TxtType.Heading4"
      >
        {{ $t('pages.serverUpdate.title') }}
      </txt>

      <p class="errors-popup__p">
        {{ $t('pages.serverUpdate.description') }}
      </p>

      <btn
        w="100%"
        @click="reloadPage"
      >
        {{ $t('pages.serverUpdate.btn') }}
      </btn>
    </template>

    <template v-else>
      <router-link
        to="/"
        class="errors-popup__logo"
      >
        <logo />
      </router-link>
      <img
        src="./assets/unknownError.svg"
        alt="error"
      >
      <p class="errors-popup__something-went-wrong">{{ $t('pages.error.title') }}</p>
      <btn
        w="100%"
        @click="reloadPage"
      >
        {{ $t('pages.error.retry') }}
      </btn>
    </template>
  </popup>
</template>

<script lang="ts" src="./Popup.ts" />
<style lang="scss" src="./popup.scss" />
