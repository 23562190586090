import { computed, defineComponent, PropType } from 'vue';
import { PaidPackageKeys } from 'api/users/models/paidPackages/enums';
import DenzlIcon from './assets/denzl.raw.svg';
import DonIcon from './assets/don.raw.svg';
import HearthBreakerIcon from './assets/hearth-breaker.raw.svg';

const PremiumIcon = defineComponent({
  props: {
    paidParams: {
      type: Array as PropType<string[]>,
      required: true,
    },
    size: {
      type: String,
      default: '30px',
    },
  },
  setup(props) {
    const icon = computed(() => {
      if (!props.paidParams || !Array.isArray(props.paidParams)) return null;

      if (props.paidParams.includes(PaidPackageKeys.IconDazle)) return DenzlIcon;
      if (props.paidParams.includes(PaidPackageKeys.IconDon)) return DonIcon;
      if (props.paidParams.includes(PaidPackageKeys.IconHearthBreaker)) return HearthBreakerIcon;
      return null;
    });

    return {
      icon,
    };
  },
});

export default PremiumIcon;
