import { Api } from 'api/api';

import type { Module } from 'vuex';
import type { IMetapagesResponse, IMetapagesCollection } from 'core/types';
import { getDefaultPagesState } from 'store/metapages/getDefaultPagesState';
import type { IStoreState } from '../interfaces/IStoreState';

export const METAPAGES_STORE_KEY = 'metapages';

export interface IMetaPagesState {
  items: IMetapagesResponse[]
}

export enum MetaPagesGetter {
  Pages = 'METAPAGES',
  MetaPageByUrl = 'METAPAGE_BY_URL',
  MetaPageByUrlList = 'METAPAGE_BY_URL_LIST',
  MetaPageAliases = 'METAPAGE_ALIASES',
}
export enum MetaPagesMutation {
  SetPages = 'SET_METAPAGES',
  ClearState = 'CLEAR_STATE'
}
export enum MetaPagesAction {
  ListPages = 'LIST_METAPAGES',
}

export const metapages: Module<IMetaPagesState, IStoreState> = {
  namespaced: true,

  state: getDefaultPagesState(),

  getters: {
    [MetaPagesGetter.Pages]: ({ items }) => items,
    [MetaPagesGetter.MetaPageAliases]: ({ items }) => {
      const urls = items.map(item => item.url);
      return urls;
    },
    [MetaPagesGetter.MetaPageByUrlList]: ({ items }) => (url: string) => items.filter((v) => v.url === url),
    [MetaPagesGetter.MetaPageByUrl]: ({ items }) => (url: string, cities_enabled: boolean|null = null) => items.find((v) => v.url === url && v.cities_enabled === cities_enabled),
  },
  mutations: {
    [MetaPagesMutation.SetPages]: (state, items: IMetapagesCollection) => {
      state.items = items?.data ? items.data : [];
    },
    [MetaPagesMutation.ClearState]: (state) => {
      Object.assign(state, getDefaultPagesState());
    },
  },
  actions: {
    [MetaPagesAction.ListPages]: async ({ commit, state }) => {
      if (state.items.length > 0) return;
      const { data } = await Api.metaPagesService.listPages();

      commit(MetaPagesMutation.SetPages, data);
    },
  },
};
