import { computed, defineComponent, PropType, toRefs, watchEffect, onBeforeUnmount } from 'vue';
import { hideScroll } from 'core/helpers';
import { ModalHeaderDirection } from './enums/ModalHeaderDirection';
import { IModalHeader } from './interfaces/IModalHeader';
import { IModalHeaderAction } from './interfaces/IModalHeaderAction';
import ModalHeader from './ModalHeader.vue';

const Modal = defineComponent({
  components: {
    ModalHeader,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    header: {
      type: Object as PropType<IModalHeader>,
      required: true,
    },
  },

  emits: ['close'],

  setup(props) {
    const { show, header } = toRefs(props);

    const hasAction = computed(() => !!header.value.action);
    const action = computed<IModalHeaderAction>(() => header.value.action as never);

    const dir = computed(() => header.value.dir ?? ModalHeaderDirection.Ltr);

    const actionText = computed(() => (hasAction.value ? action.value.name : undefined));

    watchEffect(() => {
      hideScroll(show.value);
    }, { flush: 'post' });

    onBeforeUnmount(() => {
      hideScroll(false);
    });

    function onActionClick() {
      if (!hasAction.value) return;
      action.value.handler();
    }

    return {
      dir,

      hasAction,
      actionText,

      onActionClick,
    };
  },
});

export default Modal;
