<template>
  <component
    :is="tag"
    :class="{
      'avatar': true,
      [`avatar_size-${size}`]: true,
      'avatar_online': hasOnlineMarker && !hasPlus,
    }"
    @click.prevent="handleClick"
  >
    <span
      :class="{
        'avatar__container': true,
        'avatar__container_story': hasStory,
        [`avatar__container_story-${storyTheme}`]: hasStory,
      }"
    >
      <img
        v-if="hasImage"

        class="avatar__image"
        :src="image.thumbnail || image.path"
        :alt="$t('components.avatar.alt', { n: user.name })"
      >

      <icon
        v-else

        class="avatar__icon"
        :type="IconType.Big"
        :name="iconName"
      />
    </span>

    <icon
      v-if="hasPlus && !hasOnlineMarker && !hasStory"
      class="avatar__plus-marker"
      :type="IconType.Fill"
      :name="IconName.Plus"
      :size="Size.Xs"
      :color="iconColor"
    />
  </component>
</template>

<script lang="ts" src="./Avatar.ts"></script>
<style lang="scss" src="./avatar.scss"></style>
