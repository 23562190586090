<template>
  <teleport to="#modals">
    <transition name="push-notifications">
      <div
        v-if="isShow && lastNotification"
        class="push-notifications"
      >
        <avatar
          v-if="userProfile"
          :user="userProfile"
        />
        <span
          v-else
          class="push-notifications__icon-wrapper"
          :class="{ [`push-notifications__icon-wrapper--${color}`]: color }"
        >
          <icon
            :name="icon.name"
            :type="icon.type"
            :color="MainColor.White"
            :size="Size.L"
          />
        </span>

        <div
          class="push-notifications__content"
        >
          <h6
            ref="textRef"
            class="push-notifications__text"
            :class="{ 'push-notifications__text--expanded': textExpanded }"
          >
            {{ notificationText }}
          </h6>
          <btn
            v-if="isYOverflown && !textExpanded"
            :type="BtnType.Text"
            class="push-notifications__expand-button"
            @click="expandText"
          >
            {{ $t('components.pushNotifications.expand') }}
          </btn>
          <btn
            v-else-if="isActionExist"
            :type="BtnType.Text"
            class="push-notifications__button"
            :class="{ [`push-notifications__button--${color}`]: color }"
            @click="handleClick"
          >
            {{ notificationActionText }}
          </btn>
          <div
            v-if="textExpanded && isEmailNotification && resendTime <= 0"
            class="push-notifications__resend-email-wrapper"
          >
            {{ $t('components.pushNotifications.or') }}
            <btn
              :type="BtnType.Text"
              class="push-notifications__resend-email"
              :disabled="isFetching"
              @click="resendEmail"
            >
              {{ $t('components.pushNotifications.resendEmail') }}
            </btn>
          </div>
        </div>

        <icon-btn
          class="push-notifications__exit"
          @click="closePushNotification"
        >
          <icon
            :name="IconName.Crosses"
            :type="IconType.Contour"
            :color="MainColor.White"
            :size="Size.Xxs"
          />
        </icon-btn>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts" src="./PushNotifications.ts" />
<style lang="scss" src="./pushNotifications.scss" />
