import { CITIES_STORE_KEY, CitiesGetter } from 'store/cities';
import { METAPAGES_STORE_KEY, MetaPagesGetter } from 'store/metapages';
import { store } from 'store/store';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

function checkCitiesEnable(to: RouteLocationNormalized, next: NavigationGuardNext) {
  if (to.params.page) {
    const page = store.getters[`${METAPAGES_STORE_KEY}/${MetaPagesGetter.MetaPageByUrl}`](`/${to.params.page}`, !!to.params?.city);
    if (!page?.cities_enabled) {
      next('/not-found');
    }
  }
}

export async function checkCities(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (to.params.city) {
    const cityName: string = (to.params.city === undefined ? '' : to.params.city) as string;
    const foundedCity = store.getters[`${CITIES_STORE_KEY}/${CitiesGetter.CityBySlug}`](cityName);
    if (foundedCity) {
      checkCitiesEnable(to, next);
    } else {
      next('/not-found');
    }
  }
  next();
}
