import { Router } from 'vue-router';
import { store } from 'store/store';
import { CITIES_STORE_KEY, CitiesAction } from 'store/cities';
import { USER_STORE_KEY, UsersAction } from 'store/users';
import { ADMIN_STORE_KEY, AdminGetter } from 'store/admin';
import { useApiErrors } from 'composables/apiErrors';
import { METAPAGES_STORE_KEY, MetaPagesAction } from 'store/metapages';

export function useFetchMiddleware(router: Router) {
  const { showError } = useApiErrors();

  router.beforeEach(async (to) => {
    let promises: Promise<any>[] = [];
    try {
      promises = await Promise.all([
        store.dispatch(`${CITIES_STORE_KEY}/${CitiesAction.ListCities}`),
        store.dispatch(`${USER_STORE_KEY}/${UsersAction.CheckAuth}`),
        store.dispatch(`${METAPAGES_STORE_KEY}/${MetaPagesAction.ListPages}`),
      ]);
    } catch (e) {
      showError(e);
    }

    const loggedIn = promises[1];

    if (to.meta.authRequired && !loggedIn) {
      return '/signup';
    }
    if (to.meta.authNotRequired && loggedIn) {
      return '/';
    }

    if (to.meta.adminRoute && !store.getters[`${ADMIN_STORE_KEY}/${AdminGetter.IsAdmin}`]) {
      return '/';
    }

    return true;
  });
}
