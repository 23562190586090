const FORCE_UPDATE_TIMER = parseInt(process.env.VUE_APP_FORCE_UPDATE_TIMER || '0', 10) * 1000;
const VISIBILITY_CHANGE_UPDATE_TIMER = parseInt(process.env.VUE_APP_VISIBILITY_CHANGE_UPDATE_TIMER || '0', 10) * 1000;

export const setPageReloadTimer = () => {
  const loadDate = new Date();

  const reload = () => window.location.reload(true);

  if (FORCE_UPDATE_TIMER) setTimeout(reload, FORCE_UPDATE_TIMER);

  if (VISIBILITY_CHANGE_UPDATE_TIMER) {
    document.addEventListener('visibilitychange', () => {
      if (Date.now() - loadDate.getTime() < VISIBILITY_CHANGE_UPDATE_TIMER) return;
      reload();
    });
  }
};
