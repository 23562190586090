import { ContactType } from 'api/users/models/contact';
import { ClaimType } from 'api/users/models/claims';
import {
  BreastSize,
  HairColor,
  HipType,
  FieldType,
  BodyType,
  InterestType,
  PurposeType,
  Sex,
  Tag,
} from 'api/users/models/user';
import { RouteNames, SubscriptionRoutesName } from 'router/names';
import { ServiceNames } from 'composables/serviceSettings';
import { PhotosType } from 'api/users/models/user/enums/PhotosType';
import { CropperErrors } from 'modules/cropper';
import { ModeratedActions } from 'modules/notModerated/types';
import { PaidPackageDuration, PaidPackageKeys, PaidPackageParamsType } from 'api/users/models/paidPackages/enums';

export const ru = {
  App: {
    Notifications: {
      SuccessPayment: 'Пополнение счёта на сумму {amount}.',
      SubscriptionResults: 'Ваша рассылка завершилась. На неё откликнулись {count} девушек.',
      ResetPassword: 'Вы успешно добавили пароль для входа в аккаунт.',
      ProfilePhoneChanged: 'Вы успешно сменили номер телефона.',
      ProfilePasswordChanged: 'Вы успешно сменили пароль.',
      ProfileEmailChanged: 'Вы успешно сменили эл. почту.',
    },
  },

  geolocation: {
    PERMISSION_DENIED: 'Службы геолокации на вашем устройстве запрещены',
    POSITION_UNAVAILABLE: 'Не удалось получить ваше месторасположение',
    TIMEOUT: 'Не удалось получить ваше месторасположение',
    UNKNOWN: 'Произошла неизвестная ошибка',
  },

  pageKeywords: {
    default: 'Содержанки, как найти спонсора, свободные отношения',
    [RouteNames.Welcome]: '',
  },

  pageDescriptions: {
    default: 'Lovista - удобный современный сайт знакомств с красивыми содержанками и щедрыми спонсорами. Более 1000 реальных анкет..',
    [RouteNames.Welcome]: 'Lovista - удобный современный сайт знакомств с красивыми девушками  и обеспеченными мужчинами. Более 3000 реальных анкет.',
    [RouteNames.Main]: 'Lovista - удобный современный сайт знакомств с красивыми содержанками и щедрыми спонсорами. Более 1000 реальных анкет.. | Lovista - удобный современный сайт знакомств в городе {city} с красивыми содержанками и щедрыми спонсорами. Более 1000 реальных анкет..',
  },

  pageTitles: {
    default: 'Lovista - знакомства с красивыми содержанками и щедрыми спонсорами',
    [RouteNames.Main]: 'Lovista - знакомства с красивыми содержанками и щедрыми спонсорами | Lovista - знакомства с красивыми содержанками и щедрыми спонсорами в городе {city}',
    [RouteNames.Welcome]: 'Lovista - знакомства с красивыми девушками и обеспеченными мужчинами',
    [RouteNames.Catalog]: 'Каталог',
    [RouteNames.SuccessCatalog]: 'Каталог успешных людей',
    [RouteNames.Favorites]: 'Избранные',
    [RouteNames.CatalogFilter]: 'Фильтры',
    [RouteNames.Subscriptions]: 'Подписки на типаж',
    [RouteNames.Subscription]: 'Подписка на типаж',
    [RouteNames.SubscriptionCreate]: 'Добавить подписку на типаж',
    [RouteNames.SubscriptionEdit]: 'Редактировать подписку на типаж',
    [RouteNames.User]: 'Пользователь', // выводить имя
    [RouteNames.Profile]: 'Профиль',
    [RouteNames.AuthLog]: 'История входов',
    [RouteNames.ProfileSettings]: 'Настройки',
    [RouteNames.ProfileWallet]: 'Кошелек',
    [RouteNames.ProfileWalletTransactions]: 'История операций',
    [RouteNames.ProfileWalletRefill]: 'Пополнение кошелька',
    [RouteNames.PaymentSuccess]: 'Успешно оплачено!',
    [RouteNames.PaymentFailed]: 'Ошибка при оплате',
    [RouteNames.ProfilePhoneChange]: 'Смена телефона',
    [RouteNames.ProfileEmailChange]: 'Смена почты',
    [RouteNames.ProfilePasswordChange]: 'Смена пароля',
    [RouteNames.ProfileSettingsStyling]: 'Настройка темы',
    [RouteNames.ProfileNotificationSettings]: 'Настройки уведомлений',
    [RouteNames.ProfilePasswordRestore]: 'Восстановление пароля',
    [RouteNames.Rooms]: 'Чаты',
    [RouteNames.Room]: 'Чат',
    [RouteNames.Notifications]: 'Уведомления',
    [RouteNames.SignUp]: 'Регистрация',
    [RouteNames.SignIn]: 'Вход в аккаунт',
    [RouteNames.PasswordRestore]: 'Восстановление пароля',
    [RouteNames.PasswordReset]: 'Восстановление пароля',
    [RouteNames.AgreementPrivacy]: 'Политика конфиденциальности',
    [RouteNames.Agreement]: 'Соглашение',
    [RouteNames.TechnicalSupport]: 'Техническая поддержка',
    [RouteNames.AdminPanel]: 'Панель администрирования',
    [RouteNames.AdminPanelServiceSettings]: 'Настройки платных услуг',
    [RouteNames.Rules]: 'Правила сайта',
    [RouteNames.Wa]: 'WhatsApp',
    [RouteNames.Tutorial]: 'Обучение',
  },

  user: {
    profileDeleted: 'анкета удалена',
    age: 'лет | год | года | лет',
    logout: 'Выйти из аккаунта',
    sex: {
      [Sex.Male]: 'Мужской',
      [Sex.Female]: 'Женский',
    },
    online: {
      short: 'Онлайн',
      long: 'Сейчас онлайн',
    },
    offline: {
      short: 'Оффлайн',
      long: 'Не в сети',
    },
    wasRecently: {
      [Sex.Male]: 'Был недавно',
      [Sex.Female]: 'Была недавно',
    },
    onlineAt: {
      [Sex.Male]: 'Был в сети {d}',
      [Sex.Female]: 'Была в сети {d}',
    },
    typicalPhoto: 'Типажное фото',
    tag: {
      [Tag.Prof]: 'Профи',
      [Tag.Bot]: 'Бот',
      [Tag.Scum]: 'Мошенник',
    },
    height: '{h} см',
    weight: '{w} кг',
    hairColor: {
      [HairColor.None]: {
        [Sex.Male]: 'Нет волос | Нет волос | Нет волос | Нет волос',
        [Sex.Female]: 'Нет волос | Нет волос | Нет волос | Нет волос',
      },
      [HairColor.Blond]: {
        [Sex.Male]: 'Блонд | Блонд | Блонд | Блондов',
        [Sex.Female]: 'Блондинка | Блондинка | Блондинок',
      },
      [HairColor.Brunette]: {
        [Sex.Male]: 'Брюнет | Брюнет | Брюнеты | Брюнетов',
        [Sex.Female]: 'Брюнетка | Брюнетка | Брюнетки | Брюнеток',
      },
      [HairColor.Brown]: {
        [Sex.Male]: 'Шатен | Шатен | Шатены | Шатенов',
        [Sex.Female]: 'Шатенка | Шатенка | Шатенки | Шатенок',
      },
      [HairColor.Fair]: {
        [Sex.Male]: 'Русые | Русые | Русые | Русых',
        [Sex.Female]: 'Русые | Русые | Русые | Русых',
      },
      [HairColor.Red]: {
        [Sex.Male]: 'Рыжие | Рыжие | Рыжие | Рыжих',
        [Sex.Female]: 'Рыжие | Рыжие | Рыжие | Рыжих',
      },
      [HairColor.Colorful]: {
        [Sex.Male]: 'Цветные | Цветные | Цветные | Цветных',
        [Sex.Female]: 'Цветные | Цветные | Цветные | Цветных',
      },
    },
    breast: {
      [BreastSize.One]: '1',
      [BreastSize.Two]: '2',
      [BreastSize.Three]: '3',
      [BreastSize.Four]: '4',
      [BreastSize.FivePlus]: '5+',
    },
    hip: {
      [HipType.Small]: 'Маленькие',
      [HipType.Middle]: 'Средние',
      [HipType.Round]: 'Круглые',
    },
    field: {
      [FieldType.Finance]: 'Финансы',
      [FieldType.Tourism]: 'Досуг и туризм',
      [FieldType.Education]: 'Образование и наука',
      [FieldType.It]: 'IT',
      [FieldType.Medicine]: 'Медицина',
      [FieldType.Trade]: 'Торговля',
      [FieldType.Industry]: 'Промышленность',
      [FieldType.Sport]: 'Спорт',
      [FieldType.Art]: 'Искусство',
      [FieldType.Other]: 'Другое',
    },
    body: {
      [BodyType.Thin]: 'Худой',
      [BodyType.Athletic]: 'Спортивный',
      [BodyType.Paunchy]: 'С животиком',
    },
    purpose: {
      [PurposeType.Sponsor]: 'Я спонсор',
      [PurposeType.ForSponsor]: 'Ищу спонсора',
      [PurposeType.Looking]: 'Просто смотрю',
      [PurposeType.OpenRelationship]: 'Ищу свободные отношения',
      [PurposeType.Relationship]: 'Ищу отношения',
    },
    interest: {
      [InterestType.Books]: 'Книги',
      [InterestType.Movies]: 'Кино',
      [InterestType.Music]: 'Музыка',
      [InterestType.Art]: 'Искусство',
      [InterestType.Architecture]: 'Архитектура',
      [InterestType.Design]: 'Дизайн',
      [InterestType.ForeignLanguages]: 'Иностранные языки',
      [InterestType.Fashion]: 'Мода',
      [InterestType.Dancing]: 'Танцы',
      [InterestType.Cooking]: 'Кулинария',
      [InterestType.Astrology]: 'Астрология',
      [InterestType.Psychology]: 'Психология',
      [InterestType.Space]: 'Космос',
      [InterestType.Sport]: 'Спорт',
      [InterestType.Extreme]: 'Экстрим',
      [InterestType.Games]: 'Игры',
      [InterestType.Travel]: 'Путешествия',
      [InterestType.Cars]: 'Автомобили',
      [InterestType.Aviation]: 'Авиация',
      [InterestType.Yachting]: 'Яхтинг',
      [InterestType.Business]: 'Бизнес',
      [InterestType.Politics]: 'Политика',
      [InterestType.Investments]: 'Инвестиции',
      [InterestType.Technology]: 'Технологии',
      [InterestType.Science]: 'Наука',
    },
    photos: {
      [PhotosType.typicalPhotos]: 'Типажные фотографии',
      [PhotosType.noPhotos]: 'Без фото',
      [PhotosType.required]: 'С фото',
    },
  },

  contacts: {
    [ContactType.Telegram]: 'Телеграм',
    [ContactType.WhatsApp]: 'WhatsApp',
    [ContactType.Phone]: 'Номер телефона',
  },

  claims: {
    [ClaimType.Prostitution]: 'Проституция',
    [ClaimType.ObsceneLanguage]: 'Нецензурная брань',
    [ClaimType.Spam]: 'Спам, реклама',
    [ClaimType.Insults]: 'Оскорбления, некорректное поведение',
    [ClaimType.EroticContent]: 'Изображения эротического содержания',
    [ClaimType.CallToViolence]: 'Призывы к насилию, разжигание розни',
    [ClaimType.Threats]: 'Угрозы, запугивание',
  },

  admin: {
    actions: {
      title: 'Админские действия',
      shadowBan: 'shadow-бан',
      block: 'Блокировка до даты',
      blockedAt: 'по какое число',
      saveBlock: 'Сохранить',
      savingBlock: 'Сохранение ...',
      delete: 'Удалить аккаунт',
      undelete: 'Вернуть из удаленных',
      deleting: 'Выполняется ...',
    },
    changeRights: {
      add: 'Назначить администратором',
      remove: 'Удалить администратора',
    },
    edit: {
      modalTitle: 'Изменить страницу',
      title: 'Изменить заголовок',
      content: 'Изменить контент',
      save: 'Сохранить',
    },
  },

  appNotifications: {
    telegram: {
      message: 'Получайте уведомления о всех событиях на сайте в Telegram',
      action: 'Подробнее',
    },
  },

  errors: {
    api: {
      invalidResponse: 'Вернулся некорректный тип ответа',
      invalidStatusCode: 'Пришел ответ со статусом {s}',
      userNotFound: 'Пользователь не найден',
      error: 'Что-то пошло не так',
      photoUploadError: 'Не удалось загрузить файл',
    },
  },

  components: {
    contactList: {
      empty: 'У пользователя еще нет добавленных контактов',
    },
    activeServices: {
      title: 'Ваши активные услуги:',
      raiseProfile: 'Поднятие анкеты в топ',
      highlight: 'Платная рассылка',
      package: 'Пакет "{package}"',
      forever: 'навсегда',
      date: 'до {date}',
    },
    serviceSettings: {
      packages: {
        [PaidPackageKeys.DeadlineAllowFreeDeleteChats]: 'Вы можете неограниченно удалять диалоги до {date}, так как у вас куплен пакет услуг.',
        [PaidPackageKeys.ToTop]: 'Вы можете неограниченно поднимать аккаунт в топ до {date}, так как у вас куплен пакет услуг.',
        [PaidPackageKeys.DeadlineAllowFreePublishStories]: 'Вы можете неограниченно публиковать истории до {date}, так как у вас куплен пакет услуг.',
        [PaidPackageKeys.DeadlineAllowFreeSubscribeUsers]: 'Вы можете неограниченно подписываться на типаж до {date}, так как у вас куплен пакет услуг.',
        [PaidPackageKeys.AllowToSubscribeUsers]: 'Вы можете неограниченно подписываться на типаж до {date}, так как у вас куплен пакет услуг.',
      },
      forever: {
        [PaidPackageKeys.DeadlineAllowFreeDeleteChats]: 'Вы можете неограниченно удалять диалоги, так как у вас куплен пакет услуг.',
        [PaidPackageKeys.ToTop]: 'Вы можете неограниченно поднимать аккаунт в топ, так как у вас куплен пакет услуг.',
        [PaidPackageKeys.DeadlineAllowFreePublishStories]: 'Вы можете неограниченно публиковать истории, так как у вас куплен пакет услуг.',
        [PaidPackageKeys.DeadlineAllowFreeSubscribeUsers]: 'Вы можете неограниченно подписываться на типаж, так как у вас куплен пакет услуг.',
        [PaidPackageKeys.AllowToSubscribeUsers]: 'Вы можете неограниченно подписываться на типаж, так как у вас куплен пакет услуг.',
      },
      count: {
        [PaidPackageKeys.DeadlineAllowFreeDeleteChats]: 'У вас осталось {n} бесплатных удалений диалога. | У вас осталось {n} бесплатное удаление диалога. | У вас остались {n} бесплатных удаления диалога. | У вас осталось {n} бесплатных удалений диалога.',
        [PaidPackageKeys.ToTop]: 'У вас осталось {n} бесплатных поднятий аккаунта в топ. | У вас осталось {n} бесплатное поднятие аккаунта в топ. | У вас остались {n} бесплатных поднятия аккаунта в топ. | У вас осталось {n} бесплатных поднятий аккаунта в топ.',
        [PaidPackageKeys.DeadlineAllowFreePublishStories]: 'У вас осталось {n} бесплатных публикаций историй. | У вас осталась {n} бесплатная публикация истории. | У вас остались {n} бесплатные публикации историй. | У вас осталось {n} бесплатных публикаций историй.',
        [PaidPackageKeys.DeadlineAllowFreeSubscribeUsers]: 'У вас осталось {n} бесплатных подписок на типаж. | У вас осталась {n} бесплатная подписка на типаж. | У вас остались {n} бесплатные подписки на типаж. | У вас осталось {n} бесплатных подписок на типаж.',
        [PaidPackageKeys.AllowToSubscribeUsers]: 'У вас осталось {n} бесплатных подписок на типаж. | У вас осталась {n} бесплатная подписка на типаж. | У вас остались {n} бесплатные подписки на типаж. | У вас осталось {n} бесплатных подписок на типаж.',
      },
    },
    deletionReasonPopup: {
      title: 'Выберите причину удаления:',
      reasons: ['Не удобно пользоваться сайтом', 'Появились отношения', 'Больше не интересует данный вид знакомств', 'Нет подходящих людей на сайте'],
      other: 'Другая',
      otherLabel: 'Напишите причину удаления',
      submit: 'Удалить анкету',
    },
    majorityPopup: {
      title: 'Просмотр данного сайта разрешен только лицам, достигшим возраста 18 лет!',
      areYouOld: 'Вам исполнилось 18 лет?',
      yes: 'Да, мне есть 18 лет',
      no: 'Мне меньше 18 лет',
    },

    notModeratedPopup: {
      cannotActions: {
        [ModeratedActions.SendMessage]: 'Вы не можете отправить сообщение пользователю',
        [ModeratedActions.Like]: 'Вы не можете добавить пользователя в избранное',
        [ModeratedActions.Unlike]: 'Вы не можете убрать пользователя из избранного',
      },
      notModerated: {
        message: '{cannotAction}, так как в данный момент вы находитесь на модерации.',
        action: 'Написать в техподдержку',
      },
      emailNotModerated: {
        message: '{cannotAction}, так как еще не подтвердили эл. почту.',
        action: 'Написать в техподдержку',
      },
      profileNotFilled: {
        message: '{cannotAction}, так как еще не заполнили свой профиль.',
        action: 'Заполнить профиль',
      },
      moderateBlocked: {
        message: '{cannotAction}, так как вы не прошли модерацию.',
        reason: 'Причина: {reason}',
        action: 'Написать в техподдержку',
      },
    },

    pushNotifications: {
      emailNotValidated: 'Подтвердите эл. почту! Перейдите по ссылке из письма. Если вы не правильно ввели почту при регистрации, вы можете ее изменить:',
      emailNotValidatedAction: 'Изменить почту',
      expand: 'Показать',
      resendEmail: 'Отправить письмо повторно',
      successResend: 'Письмо успешно отправлено!',
      show: 'Показать',
      or: 'или',
    },
    unauthorizedPrompt: {
      signUp: 'Создайте анкету',
      signIn: 'Войдите',
      or: 'или',
    },
    captcha: {
      textfieldPlaceholder: 'Сколько будет?',
      enterTheCaptcha: 'Введите правильный код с картинки',
    },
    copyText: {
      success: 'Успешно скопировано!',
      error: 'Произошла ошибка при копировании',
    },
    createSubscription: {
      title: 'Данная услуга является платной, стоимость составляет {price}',
      confirm: 'Подтверждаю',
    },
    aboutTelegram: {
      title: 'Получайте уведомления о всех событиях на сайте в Telegram',
      submit: 'Перейти в телеграм',
      li1: '1. Скопируйте уникальный токен {token}',
      li2: '2. Перейдите по ссылке на наш телеграм-бота и вставьте уникальный токен.',
      description: 'Вы будете получать уведомления о новых сообщениях, лайках, посетителях и многое другое',
    },
    aboutSubscriptions: {
      title: 'Подписка на типаж',
      description: 'Это подписка по фильтрам на новых пользователей сайта. В подписку будут попадать, только те, которые действительно достойны твоего внимания.',
      listTitle: 'Всё что нужно, это:',
      li1: 'Выбрать фильтры, по которым ты ищешь себе партнёра',
      li2: 'Оплатить подписку',
      li3: 'Подождать, пока появятся новые пользователи на сайте',
      li4: 'Начать просматривать пользователей, которые подходят по твоим предпочтениям',
      price: 'Стоимость подписки {n} рублей | Стоимость подписки {n} рубль | Стоимость подписки {n} рубля | Стоимость подписки {n} рублей',
      duration: 'Срок действия {n} дней | Срок действия {n} день | Срок действия {n} дня | Срок действия {n} дней',
      submit: 'Подписаться на типаж',
    },
    aboutRaise: {
      title: 'Поднятие анкеты в топ',
      description1: 'Поднимите анкету в топ, чтобы Вашу анкету увидели на 60% больше людей. Анкета отобразится на главной странице и точно не останется незамеченной.',
      description2: 'P.S. Не забудь поставить свое самое шикарное фото',
      price: 'Стоимость поднятия анкеты {n} рублей | Стоимость поднятия анкеты {n} рубль | Стоимость поднятия анкеты {n} рубля | Стоимость поднятия анкеты {n} рублей',
      duration: 'Срок действия {n} дней | Срок действия {n} день | Срок действия {n} дня | Срок действия {n} дней',
      submit: 'Поднять анкету в топ',
    },
    aboutStory: {
      title: 'Платная рассылка',
      description: 'Миллионы девушек и парней ждут пока их заметят и напишут, не упусти свой шанс выделиться с услугой сторис',
      listTitle: 'Всё что нужно, это:',
      li1: 'Рассказать немного о себе',
      li2: 'Выбрать самый крутой цвет фона',
      li3: 'Выбрать аудиторию (город и пол)',
      li4: {
        [Sex.Male]: 'Отвечать новым поклонницам',
        [Sex.Female]: 'Отвечать новым поклонникам',
      },
      price: 'Стоимость рассылки {n} рублей | Стоимость рассылки {n} рубль | Стоимость рассылки {n} рубля | Стоимость рассылки {n} рублей',
      duration: 'Срок действия {n} часов | Срок действия {n} час | Срок действия {n} часа | Срок действия {n} часов',
      submit: 'Создать рассылку',
    },
    insufficientPrompt: {
      title: 'На вашем счету недостаточно средств, пополните баланс',
      submit: 'Пополнить',
    },
    avatar: {
      none: 'Нет фото',
      alt: 'Фотография профиля {n}',
    },
    alert: {
      hide: 'Скрыть',
    },
    listLoader: {
      loading: 'Вжух!',
      end: {
        male: 'Теперь ты видел все.',
        female: 'Теперь ты видела все.',
        title: 'Это все.',
      },
    },
    photoEdit: {
      save: 'Сохранить',
      ready: 'Готово',
      adjust: 'Яркость/контраст',
      blur: 'Размытие',
      crop: 'Обрезка/поворот',
      cropMode: {
        original: 'ОРИГИНАЛ',
        square: 'КВАДРАТ',
        custom: 'СВОБОДНАЯ',
      },
      blurMode: {
        none: 'БЕЗ РАЗМЫТИЯ',
        spot: 'ПЯТНО',
        line: 'ЛИНИЯ',
      },
      adjustMode: {
        exposure: 'Свет',
        brightness: 'Яркость',
        contrast: 'Контраст',
      },
    },
    photoPreview: {
      uploadImages: 'Загрузить фотографии',
      close: 'Закрыть',
    },
    cropImage: {
      save: 'Сохранить',
      ready: 'Готово',
      square: 'КВАДРАТ',
      cropAndRotate: 'Обрезка и поворот',
      error: 'Не удалось загрузить изображение',
      upToN: 'Можно загрузить не более {n} фотографий',
      [CropperErrors.OnlySquare]: 'Загрузить можно только квадратное изображение!',
      [CropperErrors.ImageTooSmall]: 'Вы выбрали слишком маленькое изображение!',
    },
    paidMessages: {
      submitPrompt: {
        title: 'Данная услуга является платной, стоимость составляет {price} рублей',
        submit: 'Подтверждаю',
      },
      title: 'Оплатите подписку, чтобы отправить сообщение',
      hours: 'на {hours} часов | на {hours} час | на {hours} часа | на {hours} часов',
      month: 'на 0 месяцев | на месяц | на {month} месяца | на {month} месяцев',
      profitably: 'Выгодно',
      disabled: '',
      radioTitle: 'Премиум на {days} дней - {price} | Премиум на {days} день - {price} | Премиум на {days} дня - {price} | Премиум на {days} дней - {price}',
      radioSubtitle: 'Безлимитная отправка сообщений и ответы на сториз',
      submit: 'Оплатить {price}',
      cancel: 'Отмена',
      success: 'Теперь вы можете писать сообщения!',
    },
  },

  empty: {
    noResult: 'По вашему запросу ничего не найдено',
    goToCatalog: 'Перейти в каталог',
  },

  placeholder: {
    phone: '+7 999 999 9999',
    city: 'Город',
    street: 'Улица',
    house: 'Дом',
    flat: 'Квартира',
    cityShort: 'г.',
    streetShort: 'ул.',
    houseShort: 'д.',
    flatShort: 'кв.',
  },

  mask: {
    phone: '+7 (###) ### ####',
  },

  rule: {
    incorrect: 'Укажите корректное значение.',
    required: 'Заполните обязательное поле',
    legal: {
      younger: 'Мы не регистрируем лиц младше 18 лет',
      incorrect: 'Проверьте корректность заполнения поля даты рождения',
    },
    registerLegal: 'Регистрация доступна для людей старше 18 лет',
    invalidDateFormat: 'Некорректный формат даты',
    min: 'Минимально допустимое значение: {n}.',
    passwordNotMatch: 'Пароли не совпадают',
    select: 'Пожалуйста, выберите из списка',
    minLength: 'Минимально допустимая длина: {n}.',
    maxLength: 'Максимально допустимая длина: {n}.',
  },

  prompt: {
    ok: 'Ok',
    cancel: 'Отмена',
  },

  layouts: {
    mainLayout: {
      signIn: 'Войти',
      signUp: 'Создать аккаунт',
    },
    subscriptionsLayout: {
      titles: {
        [SubscriptionRoutesName.Subscriptions]: 'Подписка на типаж',
        [SubscriptionRoutesName.Subscription]: 'Подписка',
        [SubscriptionRoutesName.SubscriptionCreate]: 'Выберите типаж',
      },
    },
    userLayout: {
      logoutWarning: 'Вы не привязали к аккаунту эл. почту или телефон, при выходе доступ к аккаунту будет потерян безвозвратно',
      cancel: 'Отмена',
      exit: 'Выход',
      exitTitle: 'Выход из аккаунта',
      claimReason: 'Выберите причину жалобы',
      toClaim: 'Пожаловаться',
      balance: 'в кошельке',
      settings: 'настройки аккаунта',
      changeInfo: 'изменить информацию',
      unauthorized: {
        like: 'Вы не можете добавить пользователя в избранное',
        message: 'Вы не можете отправить сообщение пользователю',
      },

      telegramLink: 'Ссылка на телеграм бота',
      tgToken: 'Токен авторизации',
    },
  },

  pages: {
    clean: 'Очистить',
    claimSuccess: 'Ваша жалоба принята. Мы разберемся с ситуацией и ответим вам на почту.',

    tutorial: {
      title: 'Обучение',
      header: 'Спасибо за регистрацию!',
      rewardTitle: 'Выполняйте задания и получайте бонусы:',
      successReceived: 'Награда успешно получена!',
      alreadyReceived: 'Награда уже получена!',
      message: 'Для того, чтобы пользователи узнали о всех функциях на сайте Lovista.ru мы придумали обучение и дарим бесплатные подарки 🥳',
      item: {
        to: 'Перейти',
      },
    },

    serverUpdate: {
      title: 'В настоящий момент на сайте идут технические работы. Мы стараемся сделать сайт лучше для вас!',
      description: 'Попробуйте обновить страницу через несколько минут',
      btn: 'Обновить страницу',
    },

    paymentSuccess: {
      title: 'Оплачено',
      content: `Деньги поступят на\xa0ваш кошелёк
      в\xa0течение 5\xa0минут.`,
      ok: 'Хорошо',
    },

    notFound: {
      description: 'Произошла ошибка, попробуйте начать сначала',
      back: 'Вернуться на главную',
    },

    paymentFailed: {
      title: 'Оплата не удалась',
      content: 'Пожалуйста, повторите попытку или свяжитесь с\xa0{support}.',
      technicalSupport: 'технической поддержкой',
      retry: 'Повторить оплату',
    },

    error: {
      title: 'Что-то пошло не так...',
      retry: 'Попробовать снова',
    },

    form: {
      sex: 'Пол',
      age: 'Возраст',
      city: 'Город',
      purpose: 'Цель',
      params: 'Параметры',
      online: 'Сейчас онлайн',
      hairColor: 'Цвет волос',
      height: 'Рост, см',
      breastSize: 'Размер груди',
      hipType: 'Бёдра',
      fieldType: 'Сфера деятельности',
      bodyType: 'Телосложение',
      interests: 'Интересы',
      photo: 'Личные фото',
      withoutPhoto: 'Без фото',
      typicalPhoto: 'Типажные фото',
      about: 'О себе',

      contactsTitle: {
        telegram: 'Telegram',
        whatsapp: 'WhatsApp',
        phone: 'Номер телефона',
        address: 'Адрес',
      },

      years: 'лет',
      from: 'От',
      to: 'До',
      or: 'или',

      typeSubsTitle: 'Подписка на типаж',
      typeSubsDesc: {
        [Sex.Female]: 'Подпишитесь на красивых {txt}, чтобы не упустить новых девушек на сайте.',
        [Sex.Male]: 'Подпишитесь на красивых {txt}, чтобы не упустить новых парней на сайте.',
      },
      gender: {
        female: 'женщин',
        male: 'мужчин',
      },
      typeSubsAction: 'Подписаться на типаж',
    },

    adminPanel: {
      title: 'Административная панель',
      users: 'пользователей',
      transactions: 'пополнение счетов',
    },

    serviceSettings: {
      title: 'Настройки услуг',
      [ServiceNames.TakeMeToTheTop]: 'Поднятие анкеты',
      [ServiceNames.Highlight]: 'Публикация истории',
      [ServiceNames.Subscription]: 'Подписка на типаж',
      [ServiceNames.MessageSubscriptionSmall]: 'Премиум для мужчин на {n} дней | Премиум для мужчин на {n} день | Премиум для мужчин на {n} дня | Премиум для мужчин на {n} дней',
      [ServiceNames.MessageSubscriptionMedium]: 'Премиум для мужчин на {n} дней | Премиум для мужчин на {n} день | Премиум для мужчин на {n} дня | Премиум для мужчин на {n} дней',
      [ServiceNames.MessageSubscriptionMax]: 'Премиум для мужчин на {n} дней | Премиум для мужчин на {n} день | Премиум для мужчин на {n} дня | Премиум для мужчин на {n} дней',
      [ServiceNames.RemoveAllMessages]: 'Удаление всех сообщений в чате',
      forMen: 'Для мужчин',
      forWomen: 'Для женщин',
      price: 'Стоимость, {currency}',
      period: 'Период, сут',
      publishDuration: 'Время публикации, ч',
    },

    successEmailConfirm: {
      message: 'Вы успешно подтвердили электронную почту!',
      submit: 'Вернуться на главную',
    },

    profile: {
      today: 'Сегодня',
      yesterday: 'Вчера',
      settingsTitle: 'Настройки профиля',
      changePassword: 'Сменить пароль ',
      changePhoneNumber: 'Сменить номер телефона',
      changeEmail: 'Сменить эл.почту',
      notificationSettings: 'Уведомления',
      authLog: 'История входов',
      changeStyling: 'Оформление',
      deleteProfile: 'Удалить анкету',
      deleteProfileConfirm: {
        title: 'Удалить анкету?',
        ok: 'Да, удалить',
        content: 'Если вы удалите анкету, то потеряете доступ ко всем контактам.',
      },
      deleteProfileTimer: {
        title: 'Удаление',
        cancel: 'Не удаляйте',
        content: 'Удаляем вашу анкету через\xa0',
      },
      goToWallet: 'Мой кошелёк',
      addPhotoTitle: 'Добавьте фотографии',
      addPhotoSubtitle: 'Вы можете сохранить анонимность, отредактировав фото при добавлении.',
      addPhotoUpload: 'Добавить фото',
      edit: 'Редактировать профиль',
      editTitle: 'Заполните анкету',
      write: 'Написать сообщение',
      save: 'Сохранить',
      password: 'Пароль',

      wallet: {
        title: 'Кошелёк',
        balance: 'Ваш баланс',
        topUp: 'Пополнить',
        local: 'Пополнение без оплаты',
        card: 'Банковской картой онлайн',
        cancel: 'Отменить',

        packageDiscount: {
          title: 'Успейте купить пакет со скидкой {percentage}%',
          msg: 'Осталось времени до окончания скидки:',
          hours: 'часов | час | часа | часов',
          minutes: 'минут | минута | минуты | минут',
        },

        packageOfServices: {
          title: 'Пакеты услуг',
          buy: 'Купить за {price}',
          cancel: 'Отмена',
          forever: 'Без ограничений (навсегда):',
          goodPrice: 'Выгодно',

          prompt: {
            title: 'Вы действительно хотите купить пакет "{package}" за {price}?',
            submit: 'Купить',
          },
          success: 'Успешно!',

          package: 'Пакет "{package}" {duration} - ',
          duration: {
            [PaidPackageDuration.DurationDays]: 'на {n} дней | на {n} день | на {n} дня | на {n} дней',
            [PaidPackageDuration.DurationMinutes]: 'на {n} минут | на {n} минуту | на {n} минуты | на {n} минут',
            [PaidPackageDuration.DurationMonths]: 'на {n} месяцев | на {n} месяц | на {n} месяца | на {n} месяцев',
            [PaidPackageDuration.DurationForever]: 'безлимит',
          },
          durationType: {
            [PaidPackageParamsType.DurationDays]: 'на {n} дней | на {n} день | на {n} дня | на {n} дней',
            [PaidPackageParamsType.DurationMinutes]: 'на {n} минут | на {n} минуту | на {n} минуты | на {n} минут',
            [PaidPackageParamsType.DurationMonths]: 'на {n} месяцев | на {n} месяц | на {n} месяца | на {n} месяцев',
            [PaidPackageParamsType.DurationForever]: '',
          },
          count: '{n} использований | {n} использование | {n} использования | {n} использований',
        },
      },

      walletTransactions: {
        title: 'История операций',
      },

      walletRefill: {
        title: 'Кошелёк',
        balance: 'Ваш баланс',
        topUp: 'Пополнить',
        amount: {
          label: 'Введите сумму пополнения',
        },
        method: {
          label: 'Выберите способ оплаты',
          card: 'Банковской картой',
        },
        success: 'Баланс пополнен',
      },

      phoneChange: {
        email: {
          title: 'Смена номера телефона',
          subtitle: 'На указанную почту придет сообщение с кодом для смены телефона',
          placeholder: 'Введите вашу эл.почту',
          submit: 'Отправить письмо с кодом',
        },
        emailCode: {
          title: 'Подтверждение номера',
          subtitle: 'Введите код из письма, который пришел на вашу почту',
          submit: 'Подтвердить код',
          requestAgain: 'Запросить код еще раз',
          successMessage: 'Код отправлен',
        },
        phone: {
          title: 'Смена номера телефона',
          subtitle: 'Введите ваш новый номер телефона',
          placeholder: 'Номер телефона',
          submit: 'Отправить код',
        },
        phoneCode: {
          title: 'Подтверждение номера ',
          subtitle: 'Введите код из сообщения, который пришел на ваш телефон',
          submit: 'Подтвердить код',
          requestAgain: 'Запросить код еще раз',
          successMessage: 'Код отправлен',
        },
        success: {
          message: 'Вы успешно сменили номер телефона',
          submit: 'Вернуться в настройки',
        },
      },

      emailChange: {
        title: 'Смена почты',
        placeholder: 'Эл.почта',
        success: {
          message: 'Вы успешно сменили электронную почту! Для подтверждения новой почты перейдите по ссылке из письма.',
          submit: 'Вернуться в настройки',
        },
      },

      passwordChange: {
        title: 'Смена пароля',
        success: 'Вы успешно сменили пароль',
        currentPassword: 'Текущий пароль',
        newPassword: 'Новый пароль',
        repeatPassword: 'Повторите пароль',
        forgotCurrentPassword: 'Забыли текущий пароль?',
        changePassword: 'Сменить пароль',
        back: 'Вернуться в настройки',
      },

      notifications: {
        title: 'Настройки уведомлений',
        unreadMessages: 'О новых сообщениях',
        userActions: 'О действиях пользователей',
        payments: 'Об оплатах',
      },

      styling: {
        title: 'Оформление',
        choose: 'Выберите цвет оформления',
        incomingMessageText: 'Привет! Ты тоже ничего, если это твоё настоящее фото 😉😉😉. Наверное, в среду освобожусь в 19:00. Заедешь за мной?',
        outgoingMessageText: 'Привет, красотка! Увидел твоё фото, и моё сердце забилось чаще. Хочу с тобой познакомиться. Какие планы на вечер среды? 😉',
      },

      addPhoto: {
        selectAsMain: 'Выбрать основным',
        mainPhoto: 'Основное фото',
      },

      form: {
        name: 'Ваше имя *',
        sex: 'Пол *',
        birthdate: 'Дата рождения *',
        birthdatePlaceholder: 'ДД.ММ.ГГГГ',
        city: 'Город *',
        height: 'Рост, см *',
        weight: 'Вес, кг *',
        hair: 'Цвет волос *',
        breast: 'Размер груди *',
        hip: 'Бёдра *',
        field: 'Сфера деятельности',
        body: 'Телосложение *',
        purpose: 'Цель регистрации на сайте *',
        interests: 'Ваши интересы',
        about: 'О себе *',
        typicalPhoto: 'Типажные фото',

        privatePhoto: {
          title: 'Добавьте фотографии в приватный альбом, которые не видны посторонним людям и при этом их легко можно отправить тем, кто этого достоин.',
        },

        contacts: {
          desc: 'Скрытые контакты можно отправлять в чате. Контакты не будут доступны основной части пользователей сайта.',
          required: 'Необходимо указать как минимум один контакт',
          attention: 'Внимание! Пользователи, которые оплатили дополнительные услуги смогут увидеть ваши контакты на странице профиля.',
          add: {
            whatsApp: 'Добавить WhatsApp',
            telegram: 'Добавить телеграм',
            phone: 'Добавить номер телефона',
            address: 'Добавить адрес',
          },
        },
        geolocationRequired: 'Показывать мою геолокацию',
      },
      telegramPlaceholder: 'Username',
      addressPlaceholder: 'Адрес',
      saved: 'Изменения сохранены',
    },

    room: {
      spend1Contact: {
        title: 'Вы точно хотите открыть контакты? Потратится одно действие',
        ok: 'Открыть контакты',
        success: 'Успешно',
      },
      emptyContacts: {
        title: 'У данного пользователя нет добавленных контактов',
        ok: 'Ок',
      },
      needPackage: {
        ok: 'Купить',
        title: 'Для того, чтобы воспользоваться услугой просмотра контактов, приобретите любой из платных пакетов',
      },
      expandedAlert: {
        title: 'Понравилась девушка?\nПосмотрите все её контакты',
      },
      expandedPopup: {
        success: 'Успешно',
        description: 'При покупке любого платного пакета становится доступна функция покупки просмотра контактов.',
        button: {
          limited: 'Купить {n} контактов | Купить {n} контакт | Купить {n} контакта | Купить {n} контактов',
          unlimited: 'Купить безлимит на {n} месяцев | Купить безлимит на месяц | Купить безлимит на {n} месяца | Купить безлимит на {n} месяцев',
        },
        title: 'Покупка просмотра контактов',
        limited: '{n} контактов - {price} | {n} контакт - {price} | {n} контакта - {price} | {n} контактов - {price}',
        unlimited: 'Безлимит на {n} месяцев - {price} | Безлимит на месяц - {price} | Безлимит на {n} месяца - {price} | Безлимит на {n} месяцев - {price}',
      },
      privatePhotos: 'Личные фотографии',
      addPrivatePhotos: {
        title: 'Добавьте приватные фото',
        content: 'Отправляйте альбом только с самым достойным. Вы можете ограничить доступ если передумаете.',
        submit: 'Сохранить',
      },
      addContacts: {
        title: 'Добавьте ссылки на мессенджеры',
        content: 'Их не будет видно в вашем профиле, но вы сможете отправить ссылку на них в личных сообщениях.',
        submit: 'Сохранить',
      },
      toSuccessUsers: {
        [Sex.Male]: 'Каталог успешных мужчин',
        [Sex.Female]: 'Каталог красоток',
      },
      sendPrivatePhotos: 'Отправить личные фотографии',
      photo: 'фото',
      storyRefer: 'Ответ на рассылку',
      closeAccess: 'Закрыть доступ',
      closeAccessConfirm: {
        title: 'Действительно хотите удалить сообщение у собеседника?',
        ok: 'Да',
        cancel: 'Нет',
      },
      userRemovedChat: 'Пользователь удалил переписку',
      contact: 'Контакт',
      chooseComplainReason: 'Выберите причину жалобы',
      yourMessage: 'Ваше сообщение',
      upToNPhotos: 'Фото до {n} шт',
      addressCopied: 'Адрес скопирован',
      orderTaxi: 'Заказать такси',
      deleteChat: 'Да, уверен',
      doNotDeleteChat: 'Отменить',
      confirmDeleteChat: 'Вы уверены, что хотите удалить переписку?',
      unavailableMessage: 'Вы не можете написать, пользователь удалил анкету',
      cancel: 'Отменить',
      removeChat: {
        text: 'Вы хотите очистить все сообщения в чате? Восстановить будет невозможно.',
        noFreeDeletionsLeft: 'У вас закончились бесплатные удаления у собеседника.',
        self: 'Очистить только у меня',
        removeAndBlock: 'Очистить у меня и заблокировать',
        all: 'Очистить у меня и {name}',
        nRemoves: '{n} удалений | {n} удаление | {n} удаления | {n} удалений',
        description: 'у собеседника бесплатно, далее {price}.',
        success: 'Чат успешно удален!',
      },
      paidRemoveChat: {
        title: 'Подтвердите оплату',
        content: 'Удалить всю историю сообщений у вас и у {name}?',
        confirm: 'Да, списать {price}',
        success: 'Чат успешно удален!',
      },
      blockUser: {
        text: 'Вы не будете получать сообщения от {name}, пока {sex} находится в блоке.',
        question: 'Вы хотите {sex} заблокировать?',
        block: 'Да, заблокировать',
        [Sex.Male]: 'он',
        [Sex.Female]: 'она',
        whom: {
          [Sex.Male]: 'его',
          [Sex.Female]: 'её',
        },
        success: 'Пользователь успешно заблокирован',
      },
      unblockUser: {
        success: 'Пользователь успешно разблокирован',
        failure: 'Что-то пошло не так',
      },
      context: {
        removeFromFavorite: 'Удалить из избранного',
        addToFavorite: 'Добавить в избранное',
        block: 'Заблокировать',
        unblock: 'Разблокировать',
        complain: 'Пожаловаться',
        removeChat: 'Удалить чат',
      },
    },

    rooms: {
      title: 'Сообщения',
      info: 'Хотите привлечь внимание \n' +
        'к своему аккаунту? Создайте сообщение, которое увидят все девушки.',
      hour: 'часов | час | часа | часов',
      minute: 'минут | минуту | минуты | минут',
      age: 'лет | года | лет | лет',
      resetSearch: 'Вернутся ко всем диалогам',
      search: 'Поиск',
      mailing: 'Рассылка',
      empty: {
        [Sex.Male]: 'Начните свой первый диалог, выбрав понравившуюся красотку',
        [Sex.Female]: 'Начните свой первый диалог, выбрав понравившегося красавчика',
      },
      message: {
        you: 'Вы',
        photo: '📸 Фотография',
        privatePhoto: '🎭 Альбом с фотографиями',
      },
      blockedMessage: {
        deleted: 'Аккаунт удален',
        blocked: 'Аккаунт заблокирован',
      },
    },

    story: {
      remove: 'Удалить объявление',
      deletePrompt: {
        title: 'Удаление рассылки',
        content: 'Вы действительно хотите удалить размещённое объявление?',
        ok: 'Да',
      },
    },

    createStory: {
      ready: 'Готово',
      goToAudience: 'Перейти к выбору аудитории',
      whoWillSee: 'Кому покажем сообщение?',
      city: 'Город',
      hideFromContacts: 'Скрыть от своих контактов',
      submit: 'Опубликовать на {n} часов | Опубликовать на {n} час | Опубликовать на {n} часа | Опубликовать на {n} часов',
      submitPrompt: {
        title: 'Данная услуга является платной, стоимость составляет {price} рублей',
        confirm: 'Подтверждаю',
      },
    },

    blog: {
      readMore: 'Читать',
      readMoreHide: 'Скрыть',
    },

    catalog: {
      title: 'Анкеты',
      signUpToSee: 'Зарегистрируйтесь, чтобы увидеть больше анкет!',
      filter: {
        title: 'Фильтр',
        buyOnline: 'Включите фильтр на 4 часа за 100 ₽',
        submit: 'Показать анкеты',

        fillOutProfile: {
          title: 'Заполните профиль, чтобы пользоваться фильтром',
          content: 'Добавьте информацию о себе, чтобы ваш профиль было легче найти',
          fillOut: 'Заполнить профиль',
          notNow: 'Не сейчас',
        },
      },

      bonusState: {
        message: 'Заходи на сайт 5 дней подряд и получи пакет "{package}" на {duration} - Бесплатно!',
        successMessage: 'Ты заходил на сайт 5 дней подряд и получил пакет "{package}" на {duration} - Бесплатно!',
        duration: '{n} дней | {n} день | {n} дня | {n} дней',
      },

      profileStory: 'Ваша рассылка',
      km: 'км',
      m: 'м',
      loading: {
        loading: 'Вжух!',
        end: {
          male: 'Теперь ты видел все.',
          female: 'Теперь ты видела все.',
        },
      },
      storyMessageSuccess: 'Сообщение отправлено',
      storyMessageError: `Сообщение не отправлено,
      попробуйте позже
      `,
      user: {
        write: 'Написать',
        cannotWrite: 'Вы не можете начать диалог',
      },
      raiseProfile: {
        title: 'Поднимите анкету в топ',
        subtitle: 'Вашу анкету увидят на 60% больше людей всего за {price} на',
        days: '{days} дней | {days} день | {days} дня | {days} дней',
        success: 'Анкета успешно поднята!',
        submitPrompt: {
          title: 'Данная услуга является платной, стоимость составляет {price} рублей',
          insufficient: 'На вашем счету недостаточно средств, пополните баланс',
          topUp: 'Пополнить',
          confirm: 'Подтверждаю',
        },
      },
    },

    subscription: {
      title: 'Подписка',
      filters: {
        hair: {
          [HairColor.None]: {
            [Sex.Male]: 'Без волос',
            [Sex.Female]: 'Без волос',
          },
          [HairColor.Blond]: {
            [Sex.Male]: 'Блонды',
            [Sex.Female]: 'Блондинки',
          },
          [HairColor.Brunette]: {
            [Sex.Male]: 'Брюнеты',
            [Sex.Female]: 'Брюнетки',
          },
          [HairColor.Brown]: {
            [Sex.Male]: 'Шатены',
            [Sex.Female]: 'Шатенки',
          },
          [HairColor.Fair]: {
            [Sex.Male]: 'Русые',
            [Sex.Female]: 'Русые',
          },
          [HairColor.Red]: {
            [Sex.Male]: 'Рыжие',
            [Sex.Female]: 'Рыжие',
          },
          [HairColor.Colorful]: {
            [Sex.Male]: 'Цветные',
            [Sex.Female]: 'Цветные',
          },
        },
        age: {
          from: 'от {n} лет',
          to: 'до {n} лет',
        },
        height: {
          from: 'от {n} см',
          to: 'до {n} см',
        },
        interests: {
          [InterestType.Books]: 'Книги',
          [InterestType.Movies]: 'Кино',
          [InterestType.Music]: 'Музыка',
          [InterestType.Art]: 'Искусство',
          [InterestType.Architecture]: 'Архитектура',
          [InterestType.Design]: 'Дизайн',
          [InterestType.ForeignLanguages]: 'Иностранные языки',
          [InterestType.Fashion]: 'Мода',
          [InterestType.Dancing]: 'Танцы',
          [InterestType.Cooking]: 'Кулинария',
          [InterestType.Astrology]: 'Астрология',
          [InterestType.Psychology]: 'Психология',
          [InterestType.Space]: 'Космос',
          [InterestType.Sport]: 'Спорт',
          [InterestType.Extreme]: 'Экстрим',
          [InterestType.Games]: 'Игры',
          [InterestType.Travel]: 'Путешествия',
          [InterestType.Cars]: 'Автомобили',
          [InterestType.Aviation]: 'Авиация',
          [InterestType.Yachting]: 'Яхтинг',
          [InterestType.Business]: 'Бизнес',
          [InterestType.Politics]: 'Политика',
          [InterestType.Investments]: 'Инвестиции',
          [InterestType.Technology]: 'Технологии',
          [InterestType.Science]: 'Наука',
        },
        activity: {
          [FieldType.Finance]: 'Финансы',
          [FieldType.Tourism]: 'Досуг и туризм',
          [FieldType.Education]: 'Образование и наука',
          [FieldType.It]: 'IT',
          [FieldType.Medicine]: 'Медицина',
          [FieldType.Trade]: 'Торговля',
          [FieldType.Industry]: 'Промышленность',
          [FieldType.Sport]: 'Спорт',
          [FieldType.Art]: 'Искусство',
          [FieldType.Other]: 'Другое',
        },
        photos: {
          [PhotosType.typicalPhotos]: 'Типажные фотографии',
          [PhotosType.noPhotos]: 'Без фото',
          [PhotosType.required]: 'С фото',
        },
        breast: {
          title: 'Грудь',
          [BreastSize.One]: '1',
          [BreastSize.Two]: '2',
          [BreastSize.Three]: '3',
          [BreastSize.Four]: '4',
          [BreastSize.FivePlus]: '5+',
        },
        hips: {
          title: 'Бедра',
          [HipType.Small]: 'Маленькие',
          [HipType.Middle]: 'Средние',
          [HipType.Round]: 'Круглые',
        },
        body: {
          title: 'Телосложение',
          [BodyType.Thin]: 'Худой',
          [BodyType.Athletic]: 'Спортивный',
          [BodyType.Paunchy]: 'С животиком',
        },
        gender: {
          [Sex.Male]: 'Мужчины',
          [Sex.Female]: 'Женщины',
        },
      },
    },

    createSubscription: {
      title: 'Выберите типаж',
      subscribe: 'Подписаться на типаж',
      edit: 'Изменить подписку на типаж',
      subscriptionName: 'Название подписки',
      subscriptionNamePlaceholder: 'Введите название подписки',
    },

    subscriptions: {
      title: 'Подписка на типаж',
      showMore: 'Показать еще',
      create: 'Добавить подписку',
      emptyUsers: 'По выбранным фильтрам ещё не зарегистрировались новые пользователи',
      empty: {
        title: 'Подпишитесь на типаж',
        [`${Sex.Male}Description`]: 'Мы покажем вам новые анкеты красоток вашего типажа',
        [`${Sex.Female}Description`]: 'Мы покажем вам новые анкеты красавчиков вашего типажа',
        selectType: 'Выбрать типаж',
      },
      subscriptionHeader: {
        more: 'еще {n} фильтров | еще {n} фильтр | еще {n} фильтра | еще {n} фильтров',
        active: 'Подписка активна',
        inactive: 'Подписка неактивна',
        hips: 'Бедра',
        breast: 'Грудь',
        edit: 'Редактировать подписку',
        body: 'Телосложение',
        removePrompt: {
          title: 'Вы действительно хотите удалить подписку?',
          ok: 'Удалить подписку',
        },
        deactivatePrompt: {
          title: 'Вы действительно хотите отключить подписку? При включении повторно оплачивается стоимость подписки.',
          ok: 'Отключить подписку',
        },
        activatePrompt: {
          title: 'Вы действительно хотите включить подписку? Данная услуга является платной, стоимость составляет {price} рублей.',
          ok: 'Включить подписку',
        },
      },
    },

    signup: {
      title: 'Регистрация',
      name: 'Имя',
      email: 'Email',
      password: 'Пароль',
      promo: 'Промокод',
      repeatPassword: 'Повтор пароля',
      birthdate: 'Дата рождения',
      birthdateFormat: 'ДД.ММ.ГГГГ',
      city: 'Город',
      male: 'Я мужчина',
      female: 'Я женщина',
      registerNow: 'Зарегистрироваться',
      haveAccount: 'У меня уже есть аккаунт',
      agreement: 'Нажимая кнопку «Зарегистрироваться», вы принимаете условия {policy} и\xa0{privacy}. {legal}',
      policy: 'Пользовательского соглашения',
      privacy: 'Политики конфиденциальности',
      legalAge: 'А\xa0также подтверждаете, что вы достигли возраста 18\xa0лет.',
    },

    signin: {
      title: 'Авторизация',
      login: 'Телефон или эл.почта',
      password: 'Пароль',
      logIn: 'Войти',
      registerNow: 'Зарегистрироваться',
      forgotPassword: 'Забыли пароль?',
      errorMessage: 'Неправильный логин или пароль',
    },

    passwordRestore: {
      title: 'Восстановление пароля',
      login: 'Телефон или эл.почта',
      restorePassword: 'Восстановить пароль',
      rememberedPassword: 'Вспомнил пароль',
      successMessage: 'Мы выслали вам инструкции по восстановлению пароля',
      errorMessage: 'Пользователя с введёнными данными не найдено',
    },

    newPassword: {
      title: 'Смена пароля',
      newPassword: 'Новый пароль',
      repeatPassword: 'Повторите пароль',
      setNewPassword: 'Установить новый пароль',
      successMessage: 'Пароль изменён',
    },

    fillData: {
      phone: {
        title: 'Добавление телефона',
        subtitle: 'На указанный номер телефона придет сообщение с кодом для добавления телефона',
        placeholder: 'Введите ваш номер телефона',
        submit: 'Отправить код',
      },
      phoneCode: {
        title: 'Подтверждение номера',
        subtitle: 'Введите код из смс, который пришел на ваш телефон',
        submit: 'Подтвердить код',
        requestAgain: 'Запросить код еще раз',
        successMessage: 'Код отправлен',
      },
      email: {
        title: 'Добавление почты',
        subtitle: 'На указанную эл.почту придет сообщение с кодом для смены почты',
        placeholder: 'Эл.почта',
        submit: 'Отправить письмо с кодом',
      },
      emailCode: {
        title: 'Подтверждение почты',
        subtitle: 'Введите код из письма, который пришел на вашу почту',
        submit: 'Подтвердить код',
        requestAgain: 'Запросить код еще раз',
        successMessage: 'Код отправлен',
      },
      passwordReset: {
        title: 'Придумайте пароль для входа',
        newPassword: 'Новый пароль',
        repeatPassword: 'Повторите пароль',
        submit: 'Сменить пароль',
        successMessage: 'Пароль изменён',
      },
    },

    main: {
      incognitoPopup: {
        title: 'Подписка на Инкогнито',
        description: 'Инкогнито - новая услуга, для тех, кто хочет остаться незамеченными.\n' +
          'Вы можете заходить на страницы к другим пользователям и они об этом не узнают!\n' +
          'Ваш статус онлайна никто не увидит, кроме вас.\n' +
          '\n' +
          'Пользуйтесь и оставайтесь незамеченными😎😎😎',
        price: 'Стоимость {price} на {n} месяцев | Стоимость {price} на {n} месяц | Стоимость {price} на {n} месяца | Стоимость {price} на {n} месяцев',
        buy: 'Купить подписку за {price}',

        promptTitle: 'Вы действительно хотите купить подписку на Инкогнито за {price}?',
        promptOk: 'Да',
        success: 'Пакет успешно куплен!',
      },
      tutorialTip: 'Пройдите обучение и получите бесплатные бонусы',
      lookingFor: {
        male: 'Ищу спонсора',
        female: 'Ищу девушку',
        title: 'Ищу',
      },
      [Sex.Male]: 'Мужчину',
      [Sex.Female]: 'Женщину',
      age: 'Возраст',
      inCity: 'В городе',
      search: 'Найти',
      adminPanel: 'Административная панель',
      rules: 'Правила сайта',
      blogs: 'Блог',
      agreement: 'Пользовательское соглашение',
      agreementPrivacy: 'Политика конфиденциальности',
      technicalSupport: 'Техническая поддержка',
      footnote: 'Сайт предназначен для пользователей 18+',
      showMore: 'Показать еще',
      goToCatalog: 'Перейти в каталог',
      topProfiles: 'Топ анкет',
      newProfiles: 'Новые анкеты',
      maleProfiles: 'Мужчины',
      femaleProfiles: 'Девушки',
      mainPageBalance: {
        refill: 'Пополнить',
        balance: 'Ваш баланс',
      },
      alert: 'Скоро на сайте будет много девушек и мужчин. Мы только запустились, поэтому что-то может работать не корректно. Всё будет, оставайся с нами и следи за новостями :)',
      lovistaIs: {
        title: 'Lovista - знакомства с красивыми содержанками и поиск щедрых спонсоров',
        inCity: 'в городе {city}',
      },
      incognitoAlert: {
        title: 'Привет! У нас появилась новая услуга - ',
        name: 'Инкогнито.',
        about: 'Узнать об услуге подробнее',
      },
    },

    welcome: {
      maleProfiles: 'Мужчины',
      femaleProfiles: 'Девушки',
      title: 'Lovista - знакомства с красивыми девушками и достойными мужчинами',
      description: [
        'Lovista — это современная платформа для общения и знакомств.',
        'Администрация сайта уделяет большое внимание качеству и целевому соответствию публики, поэтому здесь практически невозможно встретить фейк, мошенников или любителей дружить.',
        'Так что если вы серьезно намерены найти себе вторую половинку, то вам непременно сюда!',
      ],
      inCities: 'Lovista работает в городах',
    },

    notifications: {
      title: 'Уведомления',
      new: 'Новые',
      today: 'Сегодня',
      yesterday: 'Вчера',
      before: 'Раньше',
      markAllAsRead: 'Отметить все как прочитанные',
      empty: 'У вас пока нет уведомлений',
    },

    rules: {
      title: 'Правила сайта',
    },

    agreement: {
      title: 'Соглашение',
    },

    agreementPrivacy: {
      title: 'Политика конфиденциальности',
    },

    technicalSupport: {
      title: 'Техническая поддержка',
      fileTooLarge: 'Файл слишком большой',
      topicsLabel: 'Тема обращения *',
      file: 'Файл',
      topics: {
        addCity: 'Добавить город',
        siteErrors: 'Ошибки в работе сайта',
        paymentErrors: 'Проблемы с оплатой',
        others: 'Другое',
      },
      nameLabel: 'Имя *',
      messageLabel: 'Cообщение *',
      emailLabel: 'E-mail *',
      note: 'Ответ поступит на электронную почту',
      submit: 'Отправить',
      success: 'Спасибо! Будем разбираться.',
    },

    favorites: {
      note: 'Здесь отображаются понравившиеся пользователи',
      btnViewCatalog: 'Посмотреть анкеты',
    },

    successUsers: {
      note: 'Здесь отображаются успешные пользователи',
      btnViewCatalog: 'Посмотреть анкеты',
    },
  },

  colors: {
    red: 'Красный',
    blue: 'Синий',
    lavender: 'Лавандовый',
  },
};
