import { useStore } from 'vuex';
import { StorageKeys, storageService } from 'storage';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { Nullable } from 'core/types';
import { IProfile } from 'api/users/models/user';
import { ymSuccessfulTelegramLinked } from 'core/helpers';
import { computed, watchEffect } from 'vue';

export function useTgSubscribeWatcher() {
  const store = useStore();
  const profile = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`] as Nullable<IProfile>);
  const storageTgSubscribed = storageService.getItem(StorageKeys.TgSubscribed);

  watchEffect(() => {
    if (profile.value?.tg_subscribed === false && !storageTgSubscribed) {
      storageService.setItem(StorageKeys.TgSubscribed, 'false');
    } else if (profile.value?.tg_subscribed === true && storageTgSubscribed === 'false') {
      storageService.removeItem(StorageKeys.TgSubscribed);
      ymSuccessfulTelegramLinked();
    }
  });
}
