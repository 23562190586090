<template>
  <transition name="splash">
    <div
      v-if="isSplashShows"
      class="splash"
    >
      <transition
        name="fade"
        appear
      >
        <logo class="splash-logo" />
      </transition>
    </div>
  </transition>

  <transition name="splash-slot">
    <div v-show="!isSplashShows">
      <slot />
    </div>
  </transition>
</template>

<script lang="ts" src="./Splash.ts" />
<style lang="scss" src="./splash.scss" />
