import { getMetapageData } from 'composables/metapage';
import { store } from 'store/store';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

function updateMeta(to: RouteLocationNormalized) {
  const page = getMetapageData(store, to);
  const title = page?.meta_title;
  const description = page?.meta_description;
  const og = {
    title,
    description,
    image: `${window.location.protocol}//${process.env.VUE_APP_SENTRY_TRACKING_ORIGIN}/img/icons/favicon-32x32.png`,
  };
  const schemaJsonLd = {
    name: title,
    description,
    url: `${window.location.protocol}//${process.env.VUE_APP_SENTRY_TRACKING_ORIGIN}${to.fullPath}`,
  };
  // eslint-disable-next-line no-param-reassign
  to.meta.title = title; to.meta.description = description; to.meta.og = og; to.meta.schemaJsonLd = schemaJsonLd;
}

export async function updateMainMeta(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (to.params?.city || to.params?.page || to.path === '/') {
    updateMeta(to);
  }
  next();
}
