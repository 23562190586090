import { defineComponent, h, ref, onMounted, onUnmounted } from 'vue';
import PinchZoom from './pinch-zoom';

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  emits: ['zoom'],
  setup(props, { emit }) {
    const naturalWidth = ref<number>();
    const naturalHeight = ref<number>();
    const maxScale = ref<number>();
    const containerWidth = ref<number>(343);
    const containerHeight = ref<number>(343);
    const elementRef = ref<HTMLElement>();
    const elementWidth = ref<number>();
    const elementHeight = ref<number>();
    const imageRef = ref<HTMLImageElement>();
    let pz: PinchZoom | undefined;

    function genImage() {
      return h('img', {
        ref: imageRef,
        class: 'photo-viewer__image',
        src: props.src,
        onload,
      });
    }

    function onload(e: Event) {
      const target = e.target as HTMLImageElement;
      naturalWidth.value = target.naturalWidth;
      naturalHeight.value = target.naturalHeight;
      maxScale.value = Math.max(
        Math.round((naturalWidth.value / containerWidth.value) * 100) / 100,
        Math.round((naturalHeight.value / containerHeight.value) * 100) / 100,
      );
      elementWidth.value = Math.round(naturalWidth.value / maxScale.value);
      elementHeight.value = Math.round(naturalHeight.value / maxScale.value);

      const options = {
        maxZoom: maxScale.value,
        minZoom: 0.8,
        onZoomUpdate(a: any) {
          emit('zoom', a.zoomFactor);
        },
      };
      pz = new PinchZoom(target, options);
      pz.enable();
    }

    onMounted(() => {
      if (elementRef.value) {
        containerWidth.value = elementRef.value.clientWidth;
        containerHeight.value = elementRef.value.clientHeight;
      }
      emit('zoom', 1);
    });

    onUnmounted(() => {
      if (pz) {
        pz.disable();
        pz = undefined;
      }
    });

    return () => h('div', {
      ref: elementRef,
      class: 'photo-viewer__image-container',
    }, genImage());
  },
});
