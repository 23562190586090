import { GetterTree } from 'vuex';
import { IProfile } from 'api/users/models/user';
import { IStoreState } from '../interfaces/IStoreState';
import { StoriesGetter } from './enums/StoriesGetter';
import { IStoriesState } from './interfaces/IStoriesState';
import { USER_STORE_KEY } from '../users/constants';
import { UsersGetter } from '../users/enums/UsersGetter';

export const storiesGetters: GetterTree<IStoriesState, IStoreState> = {
  [StoriesGetter.List]: ({ list }, getters, rootState, rootGetters) => {
    const profile = rootGetters[`${USER_STORE_KEY}/${UsersGetter.Profile}`] as IProfile;
    const profileStory = profile?.highlight as any;
    const items = profileStory ? [{
      ...profileStory,
      user: profile,
    }].concat(list) : list;
    return items;
  },
  [StoriesGetter.Profile]: ({ profile }) => profile,
};
