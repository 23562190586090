import { MutationTree } from 'vuex';
import { IAppState } from 'store/app/interfaces/IAppState';
import { AppMutation } from 'store/app/enums/AppMutation';
import { getDefaultAppState } from 'store/app/getDefaultAppState';

export const AppMutations: MutationTree<IAppState> = {
  [AppMutation.SetServiceSettings]: (state, serviceSettings) => {
    // make service settings visible if value = 0
    state.serviceSettings = serviceSettings.map((serviceSetting: any) => {
      if (serviceSetting?.value === '') {
        return {
          ...serviceSetting,
          value: '1',
        };
      }

      return serviceSetting;
    });
  },
  [AppMutation.UpdateSetting]: (state, { subject, value }) => {
    const setting = state.serviceSettings?.find((v) => v.subject === subject);
    if (setting) setting.value = value;
  },
  [AppMutation.ClearState]: (state) => {
    Object.assign(state, getDefaultAppState());
  },
  [AppMutation.SetTabBarOnTop]: (state, flag: boolean) => {
    state.tabBarOnTop = flag;
  },
};
