import { createStore } from 'vuex';
import { IStoreState } from './interfaces/IStoreState';
import { stories } from './stories';
import { users } from './users';
import { notifications } from './notifications';
import { chatsModule as chats } from './chats';
import { pages } from './pages';
import { metapages } from './metapages';
import { cities } from './cities';
import { subscriptions } from './subscriptions';
import { admin } from './admin';
import { app } from './app';

export const store = createStore<IStoreState>({
  modules: {
    admin,
    users,
    stories,
    notifications,
    chats,
    pages,
    metapages,
    cities,
    app,
    subscriptions,
  },
});

export * from './constants';
