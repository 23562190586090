export enum ChatsAction {
  LoadChats = 'LOAD_CHATS',
  LoadUnread = 'LOAD_UNREAD',
  LoadMessagesByUserId = 'LOAD_MESSAGES_BY_USER_ID',
  SendMessageToUser = 'SEND_MESSAGE_TO_USER',
  ScheduleNewMessage = 'SCHEDULE_NEW_MESSAGE',
  DeleteMessage = 'DELETE_MESSAGE',
  CancelMessage = 'CANCEL_MESSAGE',
  RetryMessage = 'RETRY_MESSAGE',
  DeleteSelfChat = 'DELETE_SELF_CHAT',
  DeleteAllChat = 'DELETE_ALL_CHAT',
  PurchaseDeletion = 'PURCHASE_DELETION',
  ReadMessagesByUserId = 'READ_MESSAGES_BY_USER_ID',
  LoadChatByUserId = 'LOAD_CHAT_BY_USER_ID',
  ResetChat = 'RESET_CHAT',
}
