import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { App } from '@vue/runtime-core';
import { Router } from 'vue-router';

export default function addSentry(app: App, router: Router) {
  const dsn = `${process.env.VUE_APP_SENTRY_DSN}`;
  const trackingOrigin = `${process.env.VUE_APP_SENTRY_TRACKING_ORIGIN}`;
  if (dsn.length < 50 || !trackingOrigin) return;

  let sampleRate = 1.0;
  try {
    sampleRate = parseFloat(`${process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE}`);
  } catch (_) {
    sampleRate = 1.0;
  }

  Sentry.init({
    app,
    dsn,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [trackingOrigin, /^\//],
      }),
    ],
    tracesSampleRate: sampleRate,
  });
}
