import { AxiosInstance, AxiosResponse } from 'axios';
import { IUser } from 'api/users/models/user';
import { Nullable } from 'core/types';
import { IApiPaginateResponse } from 'api/api-request';
import { IGeolocaton } from 'api/users/models/contact';

export interface Message {
  sender_id?: any;
  recipient_id?: any;
  id: string;
  type: string;
  refer: Nullable<string>;
  read_at: Nullable<string>;
  created_at: string;
  updated_at: string;
  content: Record<any, any>;
  message_dialog_id: string;
  state?: string;
}

export interface Conversation {
  opposite_user: IUser;
  last_message: Nullable<Message>;
  id: string;
  unread_messages: number;
}

export interface SendMessageData {
  type: string;
  text?: string;
  photo?: File[];
  id?: string;
  geolocation?: Nullable<IGeolocaton>;
}

export interface IUploadPhoto {
  status: string;
  url: string;
  error?: string;
}

export interface IPublishPhotoMessage {
  status: string;
  error?: string;
}

export class ChatsService {
  static init(restService: AxiosInstance) {
    return new ChatsService(restService);
  }

  constructor(private restService: AxiosInstance) {}

  async loadConversations() {
    const conversations = await this.restService.get<{ data: Conversation[] }>('/public/conversations');
    return conversations.data;
  }

  loadUnread() {
    return this.restService.get<number>('public/unread');
  }

  loadMessagesByChatId(chatId: string, page = 1) {
    return this.restService.get<IApiPaginateResponse<Message[]>>(`public/conversations/messages/${chatId}`, {
      params: {
        page,
      },
    });
  }

  sendMessageToUser(userId: number, data: SendMessageData): Promise<AxiosResponse<Message>> {
    const formData = new FormData();
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        if (key === 'geolocation') {
          if (data[key]?.lat) formData.append('geolocation[lat]', `${data[key]?.lat}`);
          if (data[key]?.lng) formData.append('geolocation[lng]', `${data[key]?.lng}`);
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    return this.restService.post(`public/users/${userId}/messages`, formData);
  }

  readMessage(userId: number, messageId: string) {
    return this.restService.put(`public/users/${userId}/messages/${messageId}`);
  }

  deleteMessage(userId: number, messageId: string) {
    return this.restService.delete<{ result: boolean }>(`public/users/${userId}/messages/${messageId}`);
  }

  deleteSelfChat(userId: number) {
    return this.restService.post(`public/conversations/drop/${userId}/self`);
  }

  deleteAllChat(userId: number) {
    return this.restService.post(`public/conversations/drop/${userId}/all`);
  }

  purchaseDeletion() {
    return this.restService.post('public/conversations/drop/purchase', {
      amount: 1,
    });
  }

  loadConversationByUserId(userId: number) {
    return this.restService.get(`/public/conversations/get-dialog/${userId}`);
  }

  uploadPhoto(messageId: string, photo: File): Promise<AxiosResponse<IUploadPhoto>> {
    const formData = new FormData();
    formData.append('photo', photo);
    return this.restService.post(`/public/upload-photo-message/${messageId}`, formData);
  }

  publishPhotoMessage(messageId: string) {
    return this.restService.post<IPublishPhotoMessage>(`/public/publish-photo-message/${messageId}`);
  }
}
