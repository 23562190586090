<template>
  <icon-btn
    class="notification-icon"
    :size="Size.S"
    :color="MainColor.Grey4"
    @click="$router.push({ name: 'notifications' })"
  >
    <icon
      :type="IconType.Fill"
      :name="IconName.Bell"
      :size="Size.S"
    />
    <template #marker>
      <div
        v-if="hasUnviewed"
        class="notification-icon__dot"
      />
    </template>
  </icon-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useNotificationsGetter, NotificationsGetter } from 'store/notifications';
import { MainColor, Size } from 'core/styles';
import { IconType, IconName } from 'components/Icon';

export default defineComponent({
  setup() {
    const { result: hasUnviewed } = useNotificationsGetter<boolean>(NotificationsGetter.HasUnviewed);

    return {
      hasUnviewed,
    };
  },

  data: () => ({
    MainColor,
    Size,
    IconType,
    IconName,
  }),
});
</script>

<style lang="scss">
.notification-icon {
  .icon-button__marker {
    .notification-icon__dot {
      @include square(6px);
      position: absolute;
      top: 2px;
      right: -8px;
      background: $red;
      border-radius: 9999px;
    }
  }
}
</style>
