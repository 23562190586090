import { Router } from 'vue-router';
import { nextTick } from 'vue';

export function useDispatchAfterEachMiddleware(router: Router) {
  const dispatchAfterEach = router.afterEach(() => {
    nextTick(() => document.dispatchEvent(new Event('routerAfterEach')));
    setTimeout(() => document.dispatchEvent(new Event('routerAfterEachWithTimeout')), 5000);

    dispatchAfterEach();
  });
}
