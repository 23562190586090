<template>
  <section>
    <label
      v-for="value in radioValues"
      class="radio-group"
      :class="[{ 'radio-group--active': isChecked(value) }, { 'radio-group--disabled': isDisabled(value) }, { 'radio-group--no-border': !border }]"
    >
      <input
        :name="groupName"
        type="radio"
        class="radio-group__input"
        :value="value"
        :disabled="isDisabled(value)"
        :checked="isChecked(value)"
        @change="onChange(value)"
      >
      <span class="radio-group__check" />

      <span class="radio-group__txt-wrapper">
        <slot
          :value="value"
          :checked="isChecked(value)"
        >{{ value }}</slot>
      </span>
      <span class="flex-1" />
      <Icon
        v-if="expandable"
        class="radio-group__expand"
        :class="{ 'radio-group__expand--rotated': isChecked(value) }"
        :name="IconName.Expand"
        :type="IconType.Fill"
        :size="Size.M"
      />
    </label>
  </section>
</template>

<script lang="ts" src="./RadioGroup.ts" />
<style lang="scss" src="./radioGroup.scss" />
