import { ref } from 'vue';
import { useEmailNotValidatedNotification } from 'composables/emailNotValidatedNotification';
import { useRoute, useRouter } from 'vue-router';
import { RouteNames } from 'router/names';

const TIMEOUT = 5000;

let timeout: any;

const isShow = ref(false);
const closePushNotification = () => {
  isShow.value = false;
};
const showPushNotification = () => {
  isShow.value = true;
  timeout = setTimeout(() => closePushNotification(), TIMEOUT);
};

let afterEach: undefined | (() => void);

export function usePushNotifications() {
  const router = useRouter();
  const route = useRoute();

  if (!afterEach) {
    const { emailNotification } = useEmailNotValidatedNotification();

    afterEach = router.afterEach(() => {
      if (emailNotification.value) {
        if (timeout) clearTimeout(timeout);
        isShow.value = route.name !== RouteNames.ProfileEmailChange;
      }
    });
  }

  return {
    isShow,
    showPushNotification,
    closePushNotification,
  };
}
