import { defineComponent, ref, computed, h } from 'vue';
import logoLavender from 'assets/images/svg/logo_lavender.svg';
import logoRed from 'assets/images/svg/logo_red.svg';
import logoBlue from 'assets/images/svg/logo_blue.svg';
import Incognito from 'assets/images/svg/incognito.raw.svg';

// import { UsersGetter, useUsersGetter } from 'store/users';
import { IProfile, ThemeColor } from 'api/users/models/user';

import './logo.scss';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';

const Logo = defineComponent({
  props: {
    showIncognito: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const profile = computed<IProfile>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);
    const isIncognito = computed(() => profile.value?.incognito_until && (new Date() < new Date(profile.value?.incognito_until)));

    const activeThemeColor = ref(window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--theme-color-name') as ThemeColor || ThemeColor.Lavender);

    const observer = new MutationObserver(mutationList => setTimeout(
      () => {
        activeThemeColor.value =
          window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--theme-color-name') as ThemeColor;
      },
      50,
    ));
    observer.observe(document.documentElement, {
      attributes: true,
    });

    const logoPreview = computed(() => {
      switch (activeThemeColor.value) {
        case ThemeColor.Lavender: return logoLavender;
        case ThemeColor.Red: return logoRed;
        case ThemeColor.Blue: return logoBlue;
        default: throw new Error('Invalid color');
      }
    });

    return () => h('div', { class: 'logo-container' }, [
      h('img', {
        src: logoPreview.value,
        class: 'logo-container__logo',
      }),
      props.showIncognito && isIncognito.value ? h('div', { class: 'logo-container__incognito', innerHTML: Incognito }) : null,
    ]);
  },
});

export default Logo;
