import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import { useStore } from 'vuex';
import { NOTIFICATIONS_STORE_KEY } from 'store/notifications/constants';
import { NotificationsAction, NotificationsGetter } from 'store/notifications';
import { Nullable } from 'core/types';
import { INotification, NotificationType } from 'api/notifications/models';
import { useRouter } from 'vue-router';
import { RouteNames } from 'router/names';
import { useApiErrors } from 'composables/apiErrors';
import { getColor, getIconByType } from 'core/helpers/notifications';
import { BtnType } from 'components/Btn';
import { usePushNotifications } from 'composables/pushNotifications';
import { useEmailNotValidatedNotification } from 'composables/emailNotValidatedNotification';
import { useI18n } from 'vue-i18n';
import { Api } from 'api/api';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useFetching } from 'composables/fetching';

const RESEND_EMAIL_TIMER = parseInt(process.env.VUE_APP_EMAIL_CONFIRMATION_RESEND_TIME || '0', 10);

const PushNotifications = defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { showError } = useApiErrors();
    const textRef = ref<HTMLElement>();

    const { isShow, closePushNotification } = usePushNotifications();
    const { emailNotification } = useEmailNotValidatedNotification();

    const isEmailNotification = computed(() => !!emailNotification.value);

    const lastNotification = computed<Nullable<INotification>>(() =>
      emailNotification.value ?? store.getters[`${NOTIFICATIONS_STORE_KEY}/${NotificationsGetter.LastNotification}`]);

    function welcomeAction() {
      router.push({ name: RouteNames.Room, params: { id: 0 } });
    }

    function likeAction(id: number) {
      router.push({ name: RouteNames.User, params: { id } });
    }

    function emailNotValidatedAction() {
      router.push({ name: RouteNames.ProfileEmailChange });
    }

    function paidServiceAction() {
      router.push({ name: RouteNames.ProfileWalletTransactions });
    }

    function successPaymentAction() {
      router.push({ name: RouteNames.ProfileWalletTransactions });
    }

    const notificationAction = computed(() => {
      switch (lastNotification.value?.type) {
        case NotificationType.Welcome:
          return welcomeAction;
        case NotificationType.NewLike:
          return likeAction.bind(null, lastNotification.value.data?.object?.id);
        case NotificationType.NewVisitor:
          return likeAction.bind(null, lastNotification.value.data?.object?.id);
        case NotificationType.PaidService:
          return paidServiceAction;
        case NotificationType.SuccessPayment:
          return successPaymentAction;
        case NotificationType.EmailNotValidated:
          return emailNotValidatedAction;
        default:
          return undefined;
      }
    });

    const userProfile = computed(() => {
      switch (lastNotification.value?.type) {
        case NotificationType.NewLike:
          return lastNotification.value.data.object || undefined;
        case NotificationType.NewVisitor:
          return lastNotification.value.data.object || undefined;
        default: return undefined;
      }
    });

    const isActionExist = computed(() => !!notificationAction.value);
    const handleClick = () => {
      if (!notificationAction.value) return;
      if (lastNotification.value?.type === NotificationType.EmailNotValidated) {
        notificationAction.value();
        return;
      }
      store.dispatch(`${NOTIFICATIONS_STORE_KEY}/${NotificationsAction.ReadById}`, lastNotification.value?.id)
        .catch((e) => showError(e));
      notificationAction.value();
      closePushNotification();
    };

    const notificationText = computed(() => lastNotification.value?.data?.text || '');
    const notificationActionText = computed(() => lastNotification.value?.data?.actionText || t('components.pushNotifications.show'));

    const icon = computed(() => getIconByType(lastNotification.value?.type));
    const color = computed(() => getColor(lastNotification.value?.type));

    const isYOverflown = ref(false);

    const checkIsYOverflown = () => {
      if (!textRef.value) {
        isYOverflown.value = false;
      } else {
        isYOverflown.value = textRef.value.scrollHeight > textRef.value.clientHeight;
      }
    };

    const textExpanded = ref(false);
    const expandText = () => { textExpanded.value = true; };

    const setExpandedOverflown = () => {
      nextTick(checkIsYOverflown);
      textExpanded.value = false;
    };
    watch(lastNotification, setExpandedOverflown);
    watch(isShow, setExpandedOverflown);
    onMounted(setExpandedOverflown);

    let interval: any;
    const resendTime = ref(0);

    const { isFetching, fetch } = useFetching();

    async function resendEmail() {
      if (resendTime.value > 0) return;
      try {
        await fetch(Api.userService.resendEmailConfirmation());
        resendTime.value = RESEND_EMAIL_TIMER;
        interval = setInterval(() => {
          if (resendTime.value > 0) resendTime.value--;
          else clearInterval(interval);
        }, 1000);
        toastEmitter.emit('toast', {
          type: ToastType.Success,
          message: t('components.pushNotifications.successResend'),
        });
      } catch (e) {
        showError(e);
      }
    }

    return {
      textRef,
      isShow,
      closePushNotification,
      lastNotification,
      isYOverflown,
      textExpanded,
      expandText,
      isEmailNotification,
      resendEmail,
      resendTime,

      isActionExist,
      userProfile,
      handleClick,
      notificationText,
      notificationActionText,
      icon,
      color,
      isFetching,

      IconName,
      IconType,
      MainColor,
      Size,
      BtnType,
    };
  },
});

export default PushNotifications;
