<template>
  <short-popup
    v-model="isShows"
    class="text-center"
    :closable="false"
    overlay-class="majority-popup"
  >
    <txt :type="TxtType.Heading4">{{ $t('components.majorityPopup.title') }}</txt>
    <div v-html="image" />
    <txt :type="TxtType.Subheading">{{ $t('components.majorityPopup.areYouOld') }}</txt>

    <btn
      w="100%"
      class="my-4"
      @click="handleMajorityClick"
    >
      {{ $t('components.majorityPopup.yes') }}
    </btn>

    <btn
      w="100%"
      :type="BtnType.Secondary"
      :to="{ name: RouteNames.Rules }"
      @click="handleNotMajorityClick"
    >
      {{ $t('components.majorityPopup.no') }}
    </btn>
  </short-popup>
</template>

<script lang="ts" src="./MajorityPopup.ts" />
<style lang="scss" src="./majorityPopup.scss" />
