import { defineComponent, PropType, ref, watch } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { useRoute } from 'vue-router';

const ExpandedAlert = defineComponent({
  props: {
    isShow: {
      type: Boolean,
      default: true,
    },
    iconName: {
      type: String as PropType<IconName>,
      required: true,
    },
    iconType: {
      type: String as PropType<IconType>,
      required: true,
    },
  },
  setup() {
    const route = useRoute();

    const isExpand = ref(false);
    const toggleExpand = () => {
      isExpand.value = !isExpand.value;
    };

    watch(() => route.name, () => {
      isExpand.value = false;
    });

    return {
      isExpand,
      toggleExpand,
    };
  },
});

export default ExpandedAlert;
