import { computed, defineComponent } from 'vue';
import { MajorityValues, StorageKeys, storageService } from 'storage';

const IsMajorityWrapper = defineComponent({
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  setup() {
    const isMajority = storageService.getReactiveItem(StorageKeys.IsMajority);

    const isShow = computed(() => isMajority.value === MajorityValues.Majority);
    return {
      isShow,
    };
  },
});

export default IsMajorityWrapper;
