import { Api } from 'api/api';
import { Story } from 'api/stories/models/story';
import { ActionTree } from 'vuex';
import { USER_STORE_KEY, UsersAction, UsersGetter, UsersMutation } from 'store/users';
import { IStoreState } from '../interfaces/IStoreState';
import { StoriesAction } from './enums/StoriesAction';
import { StoriesMutation } from './enums/StoriesMutation';
import { IStoriesState } from './interfaces/IStoriesState';

export const storiesActions: ActionTree<IStoriesState, IStoreState> = {
  [StoriesAction.GetStories]: async ({ commit, rootGetters }) => {
    if (!rootGetters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`]) return;
    const { data: stories } = await Api.storiesService.loadStories();

    if (stories) {
      commit(StoriesMutation.SetStories, stories);
    }
  },
  [StoriesAction.SetStory]: ({ commit }, story: Story) => {
    commit(StoriesMutation.SetStory, story);
  },
  [StoriesAction.PublicStory]: async ({ commit, dispatch }, story) => {
    const { data, status } = await Api.storiesService.publicStory(story);

    if (status === 201) {
      commit(`${USER_STORE_KEY}/${UsersMutation.UpdateProfile}`, {
        highlight: data.data,
      }, { root: true });

      dispatch(`${USER_STORE_KEY}/${UsersAction.GetProfile}`, undefined, { root: true });
    }

    return data;
  },

  [StoriesAction.DeleteStory]: async ({ commit }) => {
    const { data } = await Api.storiesService.deleteStory();

    if (data.result) {
      commit(`${USER_STORE_KEY}/${UsersMutation.UpdateProfile}`, {
        highlight: null,
      }, { root: true });
    }

    return data;
  },
};
