import { RouteLocationNormalized, RouteLocationNormalizedLoaded, useRoute } from 'vue-router';
import { ComputedRef, computed, ref } from 'vue';
import sanitizeHtml from 'sanitize-html';
import { IMetapagesResponse } from 'core/types';
import { Store, useStore } from 'vuex';
import { METAPAGES_STORE_KEY, MetaPagesAction, MetaPagesGetter } from 'store/metapages';
import { isString } from 'core/guards';
import { getCity, useCity } from './city';
import { useApiErrors } from './apiErrors';

export function useMetapage() {
  const route = useRoute();
  const store = useStore();
  let pageKey = `/${route.params.page}`;

  const isHomeAndCity = route.params.city && !route.params.page;
  const isOnlyHome = route.path === '/';
  if (isHomeAndCity || isOnlyHome) {
    pageKey = '/';
  }
  const routeCitiesEnabled = !!route.params?.city;
  const getterMetapage = () => store.getters[`${METAPAGES_STORE_KEY}/${MetaPagesGetter.MetaPageByUrl}`](pageKey, routeCitiesEnabled);
  const currentMetapage = computed<IMetapagesResponse>(getterMetapage);

  return {
    currentMetapage,
  };
}

export function getMetapage(_store: Store<any>|null = null, _route: RouteLocationNormalizedLoaded|RouteLocationNormalized|null = null) {
  const route = _route === null ? useRoute() : _route;
  const store = _store === null ? useStore() : _store;
  let pageKey = `/${route.params.page}`;

  const isHomeAndCity = route.params.city && !route.params.page;
  const isOnlyHome = route.path === '/';
  if (isHomeAndCity || isOnlyHome) {
    pageKey = '/';
  }
  const routeCitiesEnabled = !!route.params?.city;

  return store.getters[`${METAPAGES_STORE_KEY}/${MetaPagesGetter.MetaPageByUrl}`](pageKey, routeCitiesEnabled);
}

export function useMetapageList() {
  const route = useRoute();
  const store = useStore();
  let pageKey = `/${route.params.page}`;

  const isHomeAndCity = route.params.city && !route.params.page;
  const isOnlyHome = route.path === '/';
  if (isHomeAndCity || isOnlyHome) {
    pageKey = '/';
  }
  const getterMetapage = () => store.getters[`${METAPAGES_STORE_KEY}/${MetaPagesGetter.MetaPageByUrlList}`](pageKey);
  const currentMetapageList = computed<IMetapagesResponse[]>(getterMetapage);

  return {
    currentMetapageList,
  };
}

export function useMetapageData() {
  const store = useStore();
  const loading = ref(false);
  const { showError } = useApiErrors();
  const item = useMetapage().currentMetapage;
  const cities_enabled = item.value?.cities_enabled ? item.value?.cities_enabled : false;

  let title: ComputedRef<string>;
  let content: ComputedRef<string>;

  if (cities_enabled) {
    const { currentCity } = useCity();
    const cityName = currentCity.value && isString(currentCity.value.name) ? currentCity.value.name : '';
    content = computed(() => {
      const rawContent = item.value?.content ? item.value.content : '';
      return rawContent.replace(/@city/g, cityName);
    });

    title = computed(() => {
      const rawTitle = item.value?.title ? sanitizeHtml(item.value.title) : '';
      return rawTitle.replace(/@city/g, cityName);
    });
  } else {
    title = computed(() => (item.value?.title ? sanitizeHtml(item.value.title) : ''));
    content = computed(() => (item.value?.content ? sanitizeHtml(item.value.content) : ''));
  }

  async function fetchPageContent() {
    try {
      loading.value = true;
      await store.dispatch(`${METAPAGES_STORE_KEY}/${MetaPagesAction.ListPages}`);
    } catch (e) {
      showError(e);
    } finally {
      loading.value = false;
    }
  }

  return {
    loading,
    title,
    content,
    cities_enabled,
    fetchPageContent,
  };
}

export function getMetapageData(_store: Store<any>|null = null, _route: RouteLocationNormalizedLoaded|RouteLocationNormalized|null = null) {
  const route = _route === null ? useRoute() : _route;
  const store = _store === null ? useStore() : _store;
  const loading = ref(false);
  const { showError } = useApiErrors();
  const item = getMetapage(store, route);
  const cities_enabled = item?.cities_enabled ? item.cities_enabled : false;

  let title: string;
  let content: string;
  let meta_title: string;
  let meta_description: string;

  if (cities_enabled) {
    const currentCity = getCity(store, route);
    const cityName = currentCity && isString(currentCity.name) ? currentCity.name : '';
    const rawContent = item?.content ? item.content : '';
    content = rawContent.replace(/@city/g, cityName);
    const rawTitle = item?.title ? sanitizeHtml(item.title) : '';
    title = rawTitle.replace(/@city/g, cityName);

    const rawMetaTitle = item?.meta_title ? sanitizeHtml(item.meta_title) : '';
    const rawMetaDescription = item?.meta_description ? sanitizeHtml(item.meta_description) : '';

    meta_title = rawMetaTitle.replace(/@city/g, cityName);
    meta_description = rawMetaDescription.replace(/@city/g, cityName);
  } else {
    title = item?.title ? sanitizeHtml(item.title) : '';
    content = item?.content ? sanitizeHtml(item.content) : '';
    meta_title = item?.meta_title ? sanitizeHtml(item.meta_title) : '';
    meta_description = item?.meta_description ? sanitizeHtml(item.meta_description) : '';
  }

  async function fetchPageContent() {
    try {
      loading.value = true;
      await store.dispatch(`${METAPAGES_STORE_KEY}/${MetaPagesAction.ListPages}`);
    } catch (e) {
      showError(e);
    } finally {
      loading.value = false;
    }
  }

  return {
    loading,
    title,
    meta_title,
    meta_description,
    content,
    cities_enabled,
    fetchPageContent,
  };
}
