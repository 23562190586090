<template>
  <header
    :class="{
      'modal-header': true,
      [`modal-header_${dir}`]: true,
      'modal-header_relative': relative,
    }"
  >
    <icon-btn
      class="modal-header__close"
      :size="Size.M"
      @click="$emit('close')"
    >
      <slot name="close-icon">
        <icon
          :type="IconType.Contour"
          :name="IconName.Crosses"
          :size="Size.Xs"
        />
      </slot>
    </icon-btn>

    <txt
      class="modal-header__title"
      :type="TxtType.Heading4"
    >
      {{ title }}
    </txt>

    <slot
      v-if="'action' in $slots"
      name="action"
    />
    <button
      v-else-if="actionText"
      class="modal-header__action"
      @click="$emit('action', $event)"
    >
      {{ actionText }}
    </button>
  </header>
</template>

<script lang="ts" src="./ModalHeader.ts"></script>
<style lang="scss" src="./modal-header.scss"></style>
