<template>
  <span
    v-if="icon"
    :style="`width: ${size}; height: ${size};`"
    class="premium-icon"
    v-html="icon"
  />
</template>

<script lang="ts" src="./PremiumIcon.ts" />
<style lang="scss" src="./premiumIcon.scss" />
