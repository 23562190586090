import { IProfile, IUser, ThemeColor } from 'api/users/models/user';
import { MutationTree } from 'vuex';
import { IUserFilter } from 'api/users/models/user/interfaces/IUserFilter';
import { getDefaultUsersState } from 'store/users/getDefaultUsersState';
import { StorageKeys, storageService } from 'storage';
import { AuthLog } from 'api/users/models/authLog/interfaces';
import { UsersMutation } from './enums/UsersMutation';
import { IUsersState } from './interfaces/IUsersState';

type IdData = Array<{ id: number } & Record<string, any>>

function mergeIdData(stateUsers: IdData, newUsers: IdData) {
  newUsers.forEach(user => {
    const index = stateUsers.findIndex(x => x?.id === user?.id);

    if (index === -1) {
      stateUsers.push(user);
    } else {
      stateUsers.splice(index, 1, user);
    }
  });
}

export const usersMutations: MutationTree<IUsersState> = {
  [UsersMutation.SetAuthToken]: (state, token: string) => {
    state.authToken = token;
    storageService.setItem(StorageKeys.AuthToken, token);
  },
  [UsersMutation.ClearAuthToken]: (state) => {
    state.authToken = null;
    storageService.removeItem(StorageKeys.AuthToken);
  },
  [UsersMutation.ClearState]: (state) => {
    Object.assign(state, getDefaultUsersState());
  },
  [UsersMutation.SetProfile]: (state, profile: IProfile) => {
    state.profile = profile;
  },
  [UsersMutation.UpdateProfile]: (state, payload: Partial<IProfile>) => {
    if (!payload || !state.profile) return;

    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        state.profile[key] = payload[key];
      }
    }
  },

  [UsersMutation.SetMainPhoto]: (state) => {
    if (!state.profile || !state.profile.photos.length) return;

    const mainPhoto = state.profile.photos.find((photo) => photo.main);
    if (!mainPhoto) {
      state.profile.photos[0].main = true;
    }
  },

  [UsersMutation.SetUserList]: (state, list: IUser[]) => {
    mergeIdData(state.list, list);
  },
  [UsersMutation.SetUserListPagination]: (state, data) => {
    state.listPagination = {
      meta: data.meta,
      links: data.links,
    };
  },
  [UsersMutation.SetFavoritesPagination]: (state, data) => {
    state.favoritesPagination = {
      meta: data.meta,
      links: data.links,
    };
  },
  [UsersMutation.SetSuccessUsersPagination]: (state, data) => {
    state.successUsersPagination = {
      meta: data.meta,
      links: data.links,
    };
  },
  [UsersMutation.SetAuthLogPagination]: (state, data) => {
    state.authLogPagination = {
      meta: data.meta,
      links: data.links,
    };
  },
  [UsersMutation.ClearUserList]: (state) => {
    state.list = [];
    state.listPagination = null;
    state.listCityId = null;
  },

  [UsersMutation.ClearFavorites]: (state) => {
    state.favorites = [];
    state.favoritesPagination = null;
  },

  [UsersMutation.ClearSuccessUsers]: (state) => {
    state.successUsers = [];
    state.successUsersPagination = null;
  },

  [UsersMutation.ClearAuthLog]: (state) => {
    state.authLog = [];
    state.authLogPagination = null;
  },

  [UsersMutation.SetUser]: (state, user: IUser) => {
    const index = state.list.findIndex(x => x?.id === user?.id);

    if (index < 0) {
      state.list.push(user);
    } else {
      state.list[index] = user;
    }
  },

  [UsersMutation.SetFavorites]: (state, users: IUser[]) => {
    mergeIdData(state.favorites, users);
  },

  [UsersMutation.SetSuccessUsers]: (state, users: IUser[]) => {
    mergeIdData(state.successUsers, users);
  },

  [UsersMutation.SetAuthLog]: (state, data: AuthLog[]) => {
    mergeIdData(state.authLog, data);
  },

  [UsersMutation.SetFilter]: (state, filter: Partial<IUserFilter>) => {
    if (!state) return;

    state.filter = Object.assign(state.filter || {}, filter);
  },

  [UsersMutation.ClearFilter]: (state) => {
    if (!state) return;

    state.filter = null;
  },

  [UsersMutation.InitGeolocationRequired]: (state) => {
    if (state.profile) {
      const geolocation = storageService.getItem(StorageKeys.UserGeolocationDenied);
      state.geolocationRequired = !geolocation;
    }
  },
  [UsersMutation.SetGeolocationRequired]: (state, payload: boolean) => {
    if (state.profile) {
      state.geolocationRequired = payload;
      if (payload) {
        storageService.removeItem(StorageKeys.UserGeolocationDenied);
      } else {
        storageService.setItem(StorageKeys.UserGeolocationDenied, '1');
      }
    }
  },
  [UsersMutation.SetGeolocationCoords]: (state, payload: GeolocationCoordinates | null) => {
    if (state.profile) {
      state.profile.geolocation = payload;
    }
  },
  [UsersMutation.UpdateThemeColor]: (state, color: ThemeColor) => {
    if (state.profile?.appearance) {
      state.profile.appearance.color = color;
    }
  },
  [UsersMutation.ClearMainPagePhotos]: (state) => {
    state.topUsers = [];
    state.topUsersTotal = 0;
    state.newUsers = [];
    state.newUsersTotal = 0;
    state.maleUsers = [];
    state.maleUsersTotal = 0;
    state.femaleUsers = [];
    state.femaleUsersTotal = 0;
  },
  [UsersMutation.SetTopUsers]: (state, users: IUser[]) => {
    mergeIdData(state.topUsers, users);
  },
  [UsersMutation.SetNewUsers]: (state, users: IUser[]) => {
    mergeIdData(state.newUsers, users);
  },
  [UsersMutation.SetFemaleUsers]: (state, users: IUser[]) => {
    mergeIdData(state.femaleUsers, users);
  },
  [UsersMutation.SetMaleUsers]: (state, users: IUser[]) => {
    mergeIdData(state.maleUsers, users);
  },
  [UsersMutation.SetTotalNewUsers]: (state, num) => {
    state.newUsersTotal = num;
  },
  [UsersMutation.SetTotalTopUsers]: (state, num) => {
    state.topUsersTotal = num;
  },
  [UsersMutation.SetTotalFemaleUsers]: (state, num) => {
    state.femaleUsersTotal = num;
  },
  [UsersMutation.SetTotalMaleUsers]: (state, num) => {
    state.maleUsersTotal = num;
  },
  [UsersMutation.DecrementConversationDeletions]: (state) => {
    if (state.profile?.conversation_deletions) {
      state.profile.conversation_deletions--;
    }
  },
  [UsersMutation.SetAsMain]: (state, photoId: number) => {
    if (!state.profile) return;
    let photoKey;
    const mappedPhotos = state.profile.photos.map((photo, key) => {
      if (photo.id === photoId) {
        photoKey = key;
        return {
          ...photo,
          main: true,
        };
      }
      return {
        ...photo,
        main: false,
      };
    });
    if (photoKey) {
      mappedPhotos.unshift(mappedPhotos[photoKey]);
      mappedPhotos.splice(photoKey + 1, 1);
    }
    state.profile.photos = mappedPhotos;
  },

  [UsersMutation.UpdateUsersFavorite]: (state, { userId, isFavorite }) => {
    let user;
    user = state.list.find((user) => user.id === userId);
    if (user) user.is_favorite = isFavorite;

    user = state.favorites.find((user) => user.id === userId);
    if (user) user.is_favorite = isFavorite;
  },

  [UsersMutation.SetPaidPackages]: (state, packages) => {
    state.paidPackages = packages;
  },
  [UsersMutation.SetPaidPackageDiscount]: (state, discount) => {
    state.paidPackageDiscount = discount;
  },
};
