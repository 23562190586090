<template>
  <teleport to="#modals">
    <aside
      v-if="isShow"
      class="expanded-alert"
    >
      <div
        class="expanded-alert__wrapper"
        :class="{ 'expanded-alert__wrapper--expanded': isExpand }"
      >
        <icon
          :name="iconName"
          :type="iconType"
          class="expanded-alert__icon"
          @click="toggleExpand"
        />

        <slot />
      </div>
    </aside>
  </teleport>
</template>

<script lang="ts" src="./ExpandedAlert.ts" />
<style lang="scss" src="./expandedAlert.scss" />
