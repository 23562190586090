
import { defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    // @ts-ignore
    const isPrerender = ref(window && !!window.__PRERENDER_INJECTED);

    return {
      isPrerender,
    };
  },
});
