import { defineComponent, nextTick, ref, watch } from 'vue';
import 'cropperjs/dist/cropper.css';
import { CropperErrors } from '../CropperErrors';
import { Cropper } from './Cropper';

const MIN_IMG_WIDTH = parseInt(process.env.VUE_APP_MIN_IMAGE_DIMENSION || '400', 10);

const CropImage = defineComponent({
  props: {
    image: {
      type: String,
      default: null,
    },
  },
  emits: ['close', 'save', 'image-ready-change', 'is-crop-started', 'error'],
  setup(props, { emit }) {
    const imageRef = ref<HTMLImageElement>();
    const isCropStarted = ref(false);
    const isCropped = ref(false);
    let cropper: Cropper;

    watch(isCropStarted, (value) => {
      emit('is-crop-started', value);
    });

    const imageSrc = ref<string>(props.image);

    function handleCropStart() {
      cropper.crop();
      isCropStarted.value = true;
    }

    function handleReady() {
      isCropStarted.value = !isCropped.value;
      emit('image-ready-change', true);
    }

    watch(imageSrc, () => {
      emit('image-ready-change', false);
    });

    function checkDimensions() {
      const naturalWidth = imageRef.value?.naturalWidth;
      const naturalHeight = imageRef.value?.naturalHeight;
      const clientWidth = imageRef.value?.clientWidth;
      if (!clientWidth || !naturalHeight || !naturalWidth) throw new Error('Invalid image');
      if (naturalHeight < MIN_IMG_WIDTH || naturalWidth < MIN_IMG_WIDTH) {
        emit('error', new Error(CropperErrors.ImageTooSmall));
        back();
      }
    }

    function handleLoad() {
      nextTick(() => {
        checkDimensions();
        const clientWidth = imageRef.value?.clientWidth ?? 0;
        const naturalWidth = imageRef.value?.naturalWidth ?? 0;
        cropper = new Cropper(<HTMLImageElement>imageRef.value, (MIN_IMG_WIDTH * clientWidth) / naturalWidth, !isCropped.value);
      });
    }

    function handleImageError(err?: any) {
      emit('error', err);
    }

    function crop() {
      const image = cropper.getDataUrl();
      if (imageSrc.value !== image) {
        cropper.destroy();
        imageSrc.value = image;
      } else {
        cropper.clear();
      }

      isCropStarted.value = false;
      isCropped.value = true;
    }

    function cancel() {
      cropper.clear();
      cropper.rotateTo(0);
      isCropStarted.value = false;
    }

    function back() {
      emit('close');
    }

    function startCrop() {
      cropper.crop();
      isCropStarted.value = true;
    }

    async function save() {
      if (imageRef.value?.width !== imageRef.value?.height) {
        emit('error', new Error(CropperErrors.OnlySquare));
      } else {
        try {
          const data = await cropper.getBlobData();
          emit('save', data);
        } catch (e) {
          emit('error', e);
        }
      }
    }

    function rotate() {
      cropper?.rotate90();
    }

    return {
      imageSrc,
      imageRef,
      isCropStarted,
      isCropped,
      crop,
      cancel,
      rotate,
      back,
      startCrop,
      save,

      handleLoad,
      handleCropStart,
      handleReady,
      handleImageError,
    };
  },
});

export default CropImage;
