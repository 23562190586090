import { computed, defineComponent, ref, watch } from 'vue';
import { Nullable } from 'core/types';
import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import { BtnType } from 'components/Btn';
import { useRoute } from 'vue-router';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useI18n } from 'vue-i18n';
import { hideScroll } from 'core/helpers';
import { CropperErrors } from '../CropperErrors';
import { resizeImage } from '../resizeImage';
import { ICropImage } from '../types';
import CropImage from '../CropImage';
import Spinner from '../Spinner.vue';
import { cropperEventBus } from '../eventBus';

const Cropper = defineComponent({
  components: {
    CropImage,
    Spinner,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const isCropStarted = ref(false);
    const isShow = ref(false);

    const isImageReady = ref(false);

    const opts = ref<Partial<ICropImage>>({});

    const image = computed<Nullable<string>>(() => opts.value.image ?? null);
    function clearState() {
      isShow.value = false;
      isImageReady.value = false;
      opts.value = {};
    }
    function handleClose() {
      if (opts.value.onClose) opts.value.onClose();
      clearState();
    }

    function handleSave(blob: Blob) {
      if (opts.value.onCrop) opts.value.onCrop(blob);
      clearState();
    }

    function handleImageReadyChange(flag: boolean) {
      isImageReady.value = flag;
    }

    function handleIsCropStarted(value: boolean) {
      isCropStarted.value = value;
    }

    function handleError(e: any) {
      if (e?.message && Object.values(CropperErrors).includes(e.message)) {
        toastEmitter.emit('toast', {
          type: ToastType.Error,
          message: t(`components.cropImage.${e.message}`),
        });
      } else if (opts.value.onError) {
        opts.value.onError(e);
      } else {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    }

    cropperEventBus.on('crop', async (options: ICropImage) => {
      isShow.value = true;
      const resizedImage = await resizeImage(options.image);
      isImageReady.value = false;
      opts.value = { ...options, image: resizedImage };
    });

    const cropImageRef = ref();
    const isBusy = ref(false);
    function back() {
      cropImageRef.value?.back();
    }
    function crop() {
      isBusy.value = true;
      cropImageRef.value?.crop();
      isBusy.value = false;
    }
    async function save() {
      isBusy.value = true;
      try {
        cropImageRef.value?.save();
      } catch (e) {
        if (opts.value.onError) opts.value.onError(e);
        else {
          // eslint-disable-next-line no-console
          console.warn(e);
        }
      } finally {
        isBusy.value = false;
      }
    }
    function rotate() {
      cropImageRef.value?.rotate();
    }
    function startCrop() {
      cropImageRef.value?.startCrop();
    }

    watch(isShow, (value) => {
      hideScroll(value);
    }, { flush: 'post' });

    watch(() => route.path, handleClose);

    return {
      cropImageRef,
      isImageReady,
      isCropStarted,
      isShow,
      image,
      handleClose,
      handleSave,
      handleImageReadyChange,
      handleIsCropStarted,
      handleError,

      isBusy,
      back,
      crop,
      save,
      rotate,
      startCrop,

      BtnType,
      MainColor,
      Size,
      IconName,
      IconType,
    };
  },
});

export default Cropper;
