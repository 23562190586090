export const usersFilterValueDefaults = {
  age: {
    from: 18,
    to: 70,
  },
  height: {
    from: 150,
    to: 210,
  },
};
