import { useStore } from 'vuex';
import { StoreInjectionKey } from '../../constants';
import { USER_STORE_KEY } from '../constants';
import { UsersMutation } from '../enums/UsersMutation';

export const useUsersMutation = <T = unknown>(key: UsersMutation): (payload: T) => void => {
  const store = useStore<typeof StoreInjectionKey>();

  function commit(payload: T) {
    store.commit(`${USER_STORE_KEY}/${key}`, payload);
  }

  return commit;
};
