import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { store } from 'store/store';
import { USER_STORE_KEY, UsersAction, UsersGetter, UsersMutation } from 'store/users';
import { CHATS_STORE_KEY, ChatsAction } from 'store/chats';
import { useApiErrors } from 'composables/apiErrors';
import { SubscriptionAction, SUBSCRIPTIONS_STORE_KEY } from 'store/subscriptions';
import { METAPAGES_STORE_KEY, MetaPagesGetter } from 'store/metapages';
import { NOTIFICATIONS_STORE_KEY } from 'store/notifications/constants';
import { NotificationsAction } from 'store/notifications';
import { i18n } from 'i18n/i18n';
import { routerFetch } from 'router/routerFetch';
import { ymSuccessfulPayment } from 'core/helpers';
import { useMajorityMiddleware } from 'router/middlewares/majorityMiddleware';
import { usePageLoaderMiddleware } from 'router/middlewares/pageLoaderMiddleware';
import { useMetaMiddleware } from 'router/middlewares/metaMiddleware';
import { useDispatchAfterEachMiddleware } from 'router/middlewares/dispatchAfterEachMiddleware';
import { usePopStateMiddleware } from 'router/middlewares/popStateMiddleware';
import { useFetchMiddleware } from 'router/middlewares/fetchMiddleware';
import { useErrorMiddleware } from 'router/middlewares/errorMiddleware';
import { checkVerifyEmail } from 'router/routeMiddlewares/checkVerifyEmail';
import { Sex } from 'api/users/models/user';
import { updateMainMeta } from 'router/routeMiddlewares/updateMainMeta';
import { checkCities } from 'router/routeMiddlewares/checkCities';
import { fetchCities } from 'router/routeMiddlewares/fetchCities';
import { RouteNames, SubscriptionRoutesName } from './names';

const { showError } = useApiErrors();

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: RouteNames.Main,
    meta: { title: i18n.global.tc(`pageTitles.${RouteNames.Main}`, 0), description: i18n.global.tc(`pageDescriptions.${RouteNames.Main}`, 0), keepAlive: true },
    beforeEnter: [
      checkVerifyEmail,
      fetchCities,
      updateMainMeta,
    ],
    component: () => import(/* webpackChunkName: 'main' */ 'views/Main/pages/Main'),
    children: [
      {
        name: RouteNames.MainMeta,
        meta: { title: i18n.global.tc(`pageTitles.${RouteNames.Main}`, 0), description: i18n.global.tc(`pageDescriptions.${RouteNames.Main}`, 0), keepAlive: true },
        path: ':page/:city?',
        beforeEnter: [
          (to, from, next) => {
            const page = store.getters[`${METAPAGES_STORE_KEY}/${MetaPagesGetter.MetaPageByUrl}`](`/${to.params.page}`, !!to.params?.city);

            if (page === undefined) {
              next({ name: RouteNames.MainMetaCity, params: { city: to.params.page } });
              return true;
            }
            const isCityEnabled = page.cities_enabled && !to.params.city;
            const isNotCityEnabled = !page.cities_enabled && to.params.city;
            if (isCityEnabled || isNotCityEnabled) {
              next('/not-found');
              return false;
            }
            next();
            return true;
          },
          checkCities,
          fetchCities,
          updateMainMeta,
        ],
        component: () => import(/* webpackChunkName: 'main' */ 'views/Main/pages/Main'),
      },
      {
        name: RouteNames.MainMetaCity,
        meta: { title: i18n.global.tc(`pageTitles.${RouteNames.Main}`, 0), description: i18n.global.tc(`pageDescriptions.${RouteNames.Main}`, 0), keepAlive: true },
        path: ':city?',
        beforeEnter: [
          checkCities,
          fetchCities,
          updateMainMeta,
        ],
        component: () => import(/* webpackChunkName: 'main' */ 'views/Main/pages/Main'),
      },
    ],
  },
  {
    name: RouteNames.Blogs,
    path: '/blogs',
    meta: { keepAlive: true },
    component: () => import(/* webpackChunkName: 'blogs' */ 'views/Blogs'),
  },
  {
    name: RouteNames.BlogsShow,
    path: '/blogs/:slug',
    meta: { title: false, keepAlive: true },
    component: () => import(/* webpackChunkName: 'blogs' */ 'views/Blog'),
  },
  {
    path: '/catalog',
    component: () => import('views/Catalog'),
    children: [
      {
        path: '',
        name: RouteNames.Catalog,
        beforeEnter: [(to) => {
          const filter: any = {};
          if (Object.values(Sex).includes(to.query?.gender as Sex)) {
            filter.gender = to.query.gender;
          }
          if (['top', 'new'].includes(to.query?.sort as string)) {
            filter.sort = to.query.sort;
          }
          if (Object.keys(filter).length) store.commit(`${USER_STORE_KEY}/${UsersMutation.SetFilter}`, filter);
          else {
            const filter = store.getters[`${USER_STORE_KEY}/${UsersGetter.Filter}`];
            if (filter?.gender || filter?.sort) {
              return {
                path: to.path,
                query: {
                  gender: filter?.gender,
                  sort: filter?.sort,
                },
              };
            }
          }
          return true;
        }, (to) => routerFetch(to,
          () => !store.getters[`${USER_STORE_KEY}/${UsersGetter.List}`]?.length,
          () => Promise.all([
            store.dispatch(`${USER_STORE_KEY}/${UsersAction.GetCatalog}`),
            store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadChats}`),
          ]))],
        meta: { title: i18n.global.t(`pageTitles.${RouteNames.Catalog}`), keepAlive: true },
        component: () => import(/* webpackChunkName: 'user' */ 'views/Catalog/UserList'),
        children: [],
      },
      {
        path: 'favorites',
        name: RouteNames.Favorites,
        beforeEnter: (to) => routerFetch(to,
          () => !store.getters[`${USER_STORE_KEY}/${UsersGetter.Favorites}`]?.length,
          () => Promise.all([
            store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchFavorites}`),
            store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadChats}`),
          ])),
        meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.Favorites}`), keepAlive: true },
        component: () => import(/* webpackChunkName: 'user' */ 'views/Catalog/Favorites'),
      },
      {
        path: 'success-users',
        name: RouteNames.SuccessCatalog,
        beforeEnter: (to) => routerFetch(to,
          () => !store.getters[`${USER_STORE_KEY}/${UsersGetter.SuccessUsers}`]?.length,
          () => Promise.all([
            store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchSuccessUsers}`),
            store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadChats}`),
          ])),
        meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.SuccessCatalog}`), keepAlive: true },
        component: () => import(/* webpackChunkName: 'user' */ 'views/Catalog/SuccessUsers'),
      },
    ],
  },
  {
    path: '/catalog/filter',
    name: RouteNames.CatalogFilter,
    meta: { title: i18n.global.t(`pageTitles.${RouteNames.CatalogFilter}`) },
    component: () => import(/* webpackChunkName: 'user' */ 'views/Catalog/Filter'),
  },
  {
    path: '/subscriptions',
    beforeEnter: async () => {
      try {
        await store.dispatch(`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionAction.FetchSubscriptions}`);
        return true;
      } catch (e) {
        showError(e);
        return '/';
      }
    },
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.Subscriptions}`), keepAlive: true },
    component: () => import(/* webpackChunkName: 'subscriptions' */ 'layouts/SubscriptionsLayout'),
    children: [
      {
        path: '',
        name: RouteNames.Subscriptions,
        meta: { authRequired: true, keepAlive: true, title: i18n.global.t(`pageTitles.${SubscriptionRoutesName.Subscriptions}`) },
        component: () => import(/* webpackChunkName: 'subscriptions' */ 'views/Subscriptions/Subscriptions'),
      },
      {
        path: ':id',
        name: RouteNames.Subscription,
        meta: { authRequired: true, keepAlive: true, title: i18n.global.t(`pageTitles.${SubscriptionRoutesName.Subscription}`) },
        component: () => import(/* webpackChunkName: 'subscriptions' */ 'views/Subscriptions/Subscription'),
      },
    ],
  },
  {
    path: '/subscriptions/create',
    name: RouteNames.SubscriptionCreate,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${SubscriptionRoutesName.SubscriptionCreate}`) },
    component: () => import(/* webpackChunkName: 'subscriptions' */ 'views/Subscriptions/Create'),
  },
  {
    path: '/subscriptions/edit',
    name: RouteNames.SubscriptionEdit,
    beforeEnter: async (to: RouteLocationNormalized) => {
      if (!to.params.id) return '/subscriptions';
      try {
        await store.dispatch(`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionAction.FetchSubscriptions}`);
        return true;
      } catch (e) {
        showError(e);
        return '/';
      }
    },
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${SubscriptionRoutesName.SubscriptionEdit}`) },
    component: () => import(/* webpackChunkName: 'subscriptions' */ 'views/Subscriptions/Create'),
  },
  {
    path: '/user/:id',
    name: RouteNames.User,
    meta: { title: false, keepAlive: true },
    component: () => import(/* webpackChunkName: 'user' */ 'views/User'),
  },
  {
    path: '/profile',
    name: RouteNames.Profile,
    beforeEnter: checkVerifyEmail,
    alias: '/success',
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.Profile}`), keepAlive: true },
    component: () => import(/* webpackChunkName: 'user' */ 'views/Profile'),
  },
  {
    path: '/profile/settings',
    name: RouteNames.ProfileSettings,
    meta: { authRequired: true, keepAlive: true, title: i18n.global.t(`pageTitles.${RouteNames.ProfileSettings}`) },
    component: () => import(/* webpackChunkName: 'user' */ 'views/Profile/Settings.vue'),
  },
  {
    path: '/profile/wallet',
    name: RouteNames.ProfileWallet,
    beforeEnter: (to) => routerFetch(to,
      () => !store.getters[`${USER_STORE_KEY}/${UsersGetter.PaidPackages}`]?.length,
      () => Promise.all([
        store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchPaidPackages}`),
      ])),
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.ProfileWallet}`) },
    component: () => import(/* webpackChunkName: 'user' */ 'views/Profile/Wallet.vue'),
  },
  {
    path: '/profile/wallet/transactions',
    name: RouteNames.ProfileWalletTransactions,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.ProfileWalletTransactions}`) },
    component: () => import(/* webpackChunkName: 'user' */ 'views/Profile/WalletTransactions.vue'),
  },
  {
    path: '/profile/wallet/refill/:type?',
    name: RouteNames.ProfileWalletRefill,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.ProfileWalletRefill}`) },
    component: () => import(/* webpackChunkName: 'user' */ 'views/Profile/WalletRefill.vue'),
  },
  {
    path: '/payment/success',
    redirect: () => {
      ymSuccessfulPayment();
      return { name: RouteNames.PaymentSuccess };
    },
  },
  {
    path: '/payment/success-view',
    name: RouteNames.PaymentSuccess,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.PaymentSuccess}`) },
    component: () => import(/* webpackChunkName: 'payment' */ 'views/Payment/Success.vue'),
  },
  {
    path: '/payment/failed',
    name: RouteNames.PaymentFailed,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.PaymentFailed}`) },
    component: () => import(/* webpackChunkName: 'payment' */ 'views/Payment/Failed.vue'),
  },
/*  {
    path: '/profile/phone-change',
    name: RouteNames.ProfilePhoneChange,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.ProfilePhoneChange}`) },
    component: () => import(/!* webpackChunkName: 'user' *!/ 'views/Profile/PhoneChange.vue'),
  }, */
  {
    path: '/profile/email-change',
    name: RouteNames.ProfileEmailChange,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.ProfileEmailChange}`) },
    component: () => import(/* webpackChunkName: 'user' */ 'views/Profile/EmailChange.vue'),
  },
  {
    path: '/profile/password-change',
    name: RouteNames.ProfilePasswordChange,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.ProfilePasswordChange}`) },
    component: () => import(/* webpackChunkName: 'user' */ 'views/Profile/PasswordChange.vue'),
  },
  {
    path: '/profile/styling',
    name: RouteNames.ProfileSettingsStyling,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.ProfileSettingsStyling}`) },
    component: () => import(/* webpackChunkName: 'user' */ 'views/Profile/Styling.vue'),
  },
  {
    path: '/profile/notification-settings',
    name: RouteNames.ProfileNotificationSettings,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.ProfileNotificationSettings}`) },
    beforeEnter: (to) => routerFetch(to,
      () => false,
      () => store.dispatch(`${NOTIFICATIONS_STORE_KEY}/${NotificationsAction.FetchNotificationSettings}`)),
    component: () => import(/* webpackChunkName: 'user' */ 'views/Profile/NotificationSettings.vue'),
  },
  {
    path: '/profile/password-restore',
    name: RouteNames.ProfilePasswordRestore,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.ProfilePasswordRestore}`) },
    props: { profile: true },
    component: () => import(/* webpackChunkName: 'auth' */ 'views/PasswordRestore'),
  },
  {
    path: '/profile/auth-log',
    name: RouteNames.AuthLog,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.AuthLog}`), keepAlive: true },
    props: { profile: true },
    beforeEnter: (to) => routerFetch(to,
      () => !store.getters[`${USER_STORE_KEY}/${UsersGetter.AuthLog}`]?.length,
      () => store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchAuthLog}`)),
    component: () => import(/* webpackChunkName: 'auth' */ 'views/Profile/AuthLog.vue'),
  },
  {
    path: '/rooms',
    name: RouteNames.Rooms,
    beforeEnter: async () => {
      try {
        await Promise.all([
          store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadChats}`),
          store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadUnread}`),
        ]);
        return true;
      } catch (e) {
        showError(e);
        return '/';
      }
    },
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.Rooms}`), keepAlive: true },
    component: () => import(/* webpackChunkName: 'rooms' */ 'views/Rooms'),
  },
  {
    path: '/rooms/:id',
    name: RouteNames.Room,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.Room}`) },
    component: () => import(/* webpackChunkName: 'rooms' */ 'views/Room'),
  },
  {
    path: '/notifications',
    name: RouteNames.Notifications,
    meta: { authRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.Notifications}`) },
    component: () => import(/* webpackChunkName: 'notifications' */ 'views/Notifications'),
  },
  {
    path: '/signup',
    name: RouteNames.SignUp,
    meta: { authNotRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.SignUp}`) },
    component: () => import(/* webpackChunkName: 'auth' */ 'views/Singup'),
  },
  {
    path: '/signin',
    name: RouteNames.SignIn,
    meta: { authNotRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.SignIn}`) },
    component: () => import(/* webpackChunkName: 'auth' */ 'views/Signin'),
  },
  {
    path: '/password-restore',
    name: RouteNames.PasswordRestore,
    meta: { authNotRequired: true, title: i18n.global.t(`pageTitles.${RouteNames.PasswordRestore}`) },
    component: () => import(/* webpackChunkName: 'auth' */ 'views/PasswordRestore'),
  },
  {
    path: '/password/reset',
    name: RouteNames.PasswordReset,
    meta: { title: i18n.global.t(`pageTitles.${RouteNames.PasswordReset}`) },
    beforeEnter: (to) => {
      if (!to.query.login || !to.query.token) {
        return { name: 'signin' };
      }
      return true;
    },
    component: () => import(/* webpackChunkName: 'auth' */ 'views/NewPassword'),
  },
  {
    path: '/fill-data',
    name: RouteNames.FillData,
    meta: { authRequired: true },
    component: () => import(/* webpackChunkName: 'auth' */ 'views/FillData'),
  },
  {
    path: '/agreement',
    name: RouteNames.Agreement,
    meta: {
      title: i18n.global.t(`pageTitles.${RouteNames.Agreement}`),
      keepAlive: true,
    },
    component: () => import(/* webpackChunkName: 'agreement' */ 'views/Agreement'),
  },
  {
    path: '/agreement/privacy',
    name: RouteNames.AgreementPrivacy,
    meta: {
      title: i18n.global.t(`pageTitles.${RouteNames.AgreementPrivacy}`),
      keepAlive: true,
    },
    component: () => import(/* webpackChunkName: 'agreement' */ 'views/AgreementPrivacy'),
  },
  {
    path: '/technical-support',
    name: RouteNames.TechnicalSupport,
    meta: {
      title: i18n.global.t(`pageTitles.${RouteNames.TechnicalSupport}`),
    },
    component: () => import(/* webpackChunkName: 'technical-support' */ 'views/TechnicalSupport'),
  },
  {
    path: '/admin-panel',
    name: RouteNames.AdminPanel,
    meta: {
      adminRoute: true,
      title: i18n.global.t(`pageTitles.${RouteNames.AdminPanel}`),
    },
    component: () => import(/* webpackChunkName: 'admin-panel' */ 'views/AdminPanel'),
  },
  {
    path: '/admin-panel/service-settings',
    name: RouteNames.AdminPanelServiceSettings,
    meta: {
      adminRoute: true,
      title: i18n.global.t('pageTitles.admin-panel-service-settings'),
    },
    component: () => import(/* webpackChunkName: 'admin-panel-service-settings' */ 'views/AdminPanel/pages/ServiceSettings'),
  },
  {
    path: '/rules',
    name: RouteNames.Rules,
    meta: {
      title: i18n.global.t(`pageTitles.${RouteNames.Rules}`),
      keepAlive: true,
    },
    component: () => import(/* webpackChunkName: 'rules' */ 'views/Rules'),
  },
  {
    path: '/tutorial',
    name: RouteNames.Tutorial,
    beforeEnter: () => store.dispatch(`${USER_STORE_KEY}/${UsersAction.GetProfile}`),
    meta: {
      title: i18n.global.t(`pageTitles.${RouteNames.Tutorial}`),
      authRequired: true,
      keepAlive: true,
    },
    component: () => import(/* webpackChunkName: 'tutorial' */ 'views/Tutorial'),
  },
  {
    path: '/wa',
    name: RouteNames.Wa,
    meta: {},
    component: () => import(/* webpackChunkName: 'admin-panel' */ 'views/Wa/Wa.vue'),
  },
  {
    path: '/not-found',
    name: RouteNames.NotFound,
    component: () => import(/* webpackChunkName: 'admin-panel' */ 'views/NotFound'),
  },
  {
    path: '/success-email-confirmation',
    name: RouteNames.SuccessEmailConfirm,
    component: () => import(/* webpackChunkName: 'user' */ 'views/SuccessEmailConfirm'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: RouteNames.NotFound },
  },
];

export const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!savedPosition || !to.meta.keepAlive) {
      return { left: 0, top: 0 };
    }
    return savedPosition;
  },
});

useErrorMiddleware(router);
useFetchMiddleware(router);
usePageLoaderMiddleware(router);
usePopStateMiddleware(router);
useDispatchAfterEachMiddleware(router);
useMajorityMiddleware(router);
useMetaMiddleware(router);
