<template>
  <span
    class="context-menu-activator"
    @click="handleOpen"
  >
    <slot />
  </span>

  <teleport to="#context">
    <div
      v-if="isShow"
      :class="['context-menu', `${cssClass}`]"
      :style="{
        top: `${position[1]}px`,
        [pos === ContextMenuPosition.Left ? 'left' : 'right']: `${position[0]}px`,
      }"
    >
      <button
        v-for="item in enabledItems"
        :key="item.text"
        class="context-menu__button"
        @click="handleAction(item.action)"
      >
        <txt class="context-menu__text">
          {{ item.text }}
        </txt>

        <icon
          :type="item.iconType"
          :name="item.iconName"
        />
      </button>
    </div>
  </teleport>
</template>

<script lang="ts" src="./ContextMenu.ts"></script>
<style lang="scss" src="./context-menu.scss"></style>
