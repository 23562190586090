export enum TxtType {
  Heading1 = 'h1',
  Heading2 = 'h2',
  Heading3 = 'h3',
  Heading4 = 'h4',
  Subheading = 'sh',
  MainText = 'main',
  SmallText = 'small',
  SubText = 'sub',
}
