import { IUser, Sex } from 'api/users/models/user';
import { noop } from 'core/helpers';
import { Size, MainColor } from 'core/styles';
import type { FileSource } from 'core/types';
import { cond, head, ifElse, or, T } from 'ramda';
import type { PropType } from 'vue';
import { computed, defineComponent, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { IconName, IconType } from '../Icon';

const Avatar = defineComponent({
  props: {
    toStory: {
      type: Boolean,
      default: false,
    },

    toProfile: {
      type: Boolean,
      default: false,
    },

    hasPlus: {
      type: Boolean,
      default: false,
    },

    hasOnlineMarker: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String as PropType<Size>,
      default: Size.M,
    },

    user: {
      type: Object as PropType<IUser>,
      required: true,
    },

    story: {
      type: Object,
      default: null,
    },

    iconColor: {
      type: String as PropType<MainColor>,
      default: MainColor.Violet,
    },
  },

  setup(props) {
    const {
      toProfile,
      toStory,
      user,
      story,
    } = toRefs(props);

    const router = useRouter();

    const image = computed(() => {
      if (user.value.photos?.length) return user.value.photos[0];
      return head<FileSource>(user.value.photos ?? []);
    });
    let iconName = computed(ifElse(
      () => user.value.gender === Sex.Male,
      () => IconName.Male,
      () => IconName.Female,
    ));
    if (user.value.deleted_at) {
      iconName = computed(() => IconName.Ghost);
    }
    if (user.value.blocked_at) {
      iconName = computed(() => IconName.Lock);
    }
    const hasImage = computed(() => !!image.value?.id && !user.value.deleted_at);

    const storyTheme = computed(() => story.value?.appearance?.theme ?? null);
    const hasStory = computed(() => !!storyTheme.value);

    const tag = computed(ifElse(
      () => or(toProfile.value, toStory.value),
      () => 'a',
      () => 'span',
    ));

    const href = computed(cond([
      [() => toStory.value, () => story.value.getHref()],
      [() => toProfile.value, () => `/user/${user.value.id}?to=profile`],
      [T, noop],
    ]));

    const handleClick = ifElse(
      () => !!href.value,
      () => router.push(href.value),
      noop,
    );

    return {
      image,
      iconName,
      hasImage,

      storyTheme,
      hasStory,

      tag,
      href,

      handleClick,
    };
  },

  data() {
    return {
      IconType,
      IconName,
      Size,
      MainColor,
    };
  },
});

export default Avatar;
