<template>
  <short-popup
    v-model="isShows"
    class="not-moderated-popup"
  >
    <p class="not-moderated-popup__p">
      {{ text }}
    </p>
    <btn
      w="100%"
      :to="actionTo"
    >
      {{ actionText }}
    </btn>
  </short-popup>
</template>

<script lang="ts" src="./NotModeratedPopup.ts" />
<style lang="scss" src="./notModeratedPopup.scss" />
