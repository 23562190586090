import { AxiosInstance } from 'axios';
import { ICity } from 'core/types';

export class CitiesService {
  static init(restService: AxiosInstance): CitiesService {
    return new CitiesService(restService);
  }

  constructor(private restService: AxiosInstance) {
  }

  searchCities(search?: string) {
    return this.restService.get<ICity[]>('/public/cities', { params: { search } });
  }

  listCities() {
    return this.restService.get<ICity[]>('/public/cities');
  }
}
