<template>
  <component
    :is="tagName"
    :class="{
      txt: true,
      [`txt_style-${type}`]: !!type,
      [`txt_leading-${leading}`]: !!leading,
      [`txt_weight-${weight}`]: !!weight,
      [`txt_color-${color}`]: !!color,
    }"
  >
    <slot />
  </component>
</template>

<script lang="ts" src="./Txt.ts"></script>
<style lang="scss" src="./txt.scss"></style>
