import CropperJS from 'cropperjs';

const MAX_SIZE = parseInt(process.env.VUE_APP_MAX_IMAGE_DIMENSION || '2000', 10);

export class Cropper extends CropperJS {
  constructor(image: HTMLImageElement, minCropBox: number, autoCrop: boolean) {
    super(image, {
      aspectRatio: 1,
      zoomable: false,
      background: false,
      viewMode: 1,
      toggleDragModeOnDblclick: false,
      modal: true,
      guides: false,
      center: false,
      dragMode: 'none',
      autoCrop,
      autoCropArea: 1,
      minCropBoxWidth: minCropBox,
    });
  }

  private alignCanvas() {
    const canvasData = this.getCanvasData();
    const containerData = this.getContainerData();

    const naturalCanvasAspectRatio = canvasData.naturalWidth / canvasData.naturalHeight;
    const containerAspectRatio = containerData.width / containerData.height;
    let newCanvasData;
    if (naturalCanvasAspectRatio < containerAspectRatio) {
      const width = containerData.height * naturalCanvasAspectRatio;
      const offset = (containerData.width - width) / 2;
      newCanvasData = {
        top: 0,
        height: containerData.height,
        width,
        left: offset,
      };
    } else {
      const height = containerData.width / naturalCanvasAspectRatio;
      const offset = (containerData.height - height) / 2;
      newCanvasData = {
        top: offset,
        height,
        width: containerData.width,
        left: 0,
      };
    }
    this.setCanvasData(newCanvasData);
  }

  private alignCropBox() {
    const canvasData = this.getCanvasData();
    const minDim = Math.min(canvasData.width, canvasData.height);
    this.setCropBoxData({
      width: minDim,
      height: minDim,
      left: (canvasData.width - minDim) / 2,
      top: (canvasData.height - minDim) / 2,
    });
  }

  public rotate90() {
    this.clear();
    this.rotate(90);
    this.alignCanvas();
    this.crop();
    this.alignCropBox();
  }

  public getBlobData(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.getCroppedCanvas({
        maxWidth: MAX_SIZE,
        maxHeight: MAX_SIZE,
        fillColor: '#000',
        imageSmoothingEnabled: false,
      }).toBlob((data) => {
        if (data) {
          resolve(data);
        } else {
          reject();
        }
      }, 'image/jpeg');
    });
  }

  public getDataUrl() {
    const canvas = this.getCroppedCanvas();
    return canvas
      .toDataURL('image/png');
  }
}
