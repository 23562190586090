export enum SubscriptionAction {
  FetchSubscriptions = 'FETCH_SUBSCRIPTIONS',
  FetchSubscriptionUsers = 'FETCH_SUBSCRIPTION_USERS',
  RemoveSubscription = 'REMOVE_SUBSCRIPTION',
  CreateSubscription = 'CREATE_SUBSCRIPTION',
  ActivateSubscription = 'ACTIVATE_SUBSCRIPTION',
  DeactivateSubscription = 'DEACTIVATE_SUBSCRIPTION',
  FetchMessageSubscription = 'FETCH_MESSAGE_SUBSCRIPTION',
  PurchaseMessageSubscription = 'PURCHASE_MESSAGE_SUBSCRIPTION',
  ChangeSubscription = 'CHANGE_SUBSCRIPTION',
}
