export enum StorageKeys {
  AuthToken = 'auth_token',
  IncognitoAlert = 'incognito_alert',
  ThemeColor = 'themeColor',
  UserGeolocationDenied = 'user-geolocation-denied',
  AboutRaiseViewed = 'about-raise-viewed',
  AboutSubscriptionViewed = 'about-subscription-viewed',
  AboutStoryViewed = 'about-story-viewed',
  SubscriptionFilter = 'subscription-filter',
  IsMajority = 'is-majority',
  TgSubscribed = 'tg-subscribed',
  BonusStateLastFetch = 'bonus-state-last-fetch',
  BuyViewContactsAlert = 'buy-view-contacts-alert',
}
