import { PusherChannel, PusherEvent } from './enums';
import { BasePusher } from './BasePusher';

export class TypingPusher extends BasePusher {
  public init(dialogId: string) {
    this.channel = this.pusher?.subscribe(`${PusherChannel.PrivateRoom}.${dialogId}`);
  }

  public subscribeToClientIsTyping(cb: () => void | Promise<void>) {
    this.channel?.bind(PusherEvent.ClientIsTyping, cb);
    return () => this.channel?.unbind(PusherEvent.ClientIsTyping, cb);
  }

  public subscribeToClientStoppedTyping(cb: () => void | Promise<void>) {
    this.channel?.bind(PusherEvent.ClientStoppedTyping, cb);
    return () => this.channel?.unbind(PusherEvent.ClientStoppedTyping, cb);
  }

  public triggerClientIsTyping() {
    this.channel?.trigger(PusherEvent.ClientIsTyping, {});
  }

  public triggerClientStoppedTyping() {
    this.channel?.trigger(PusherEvent.ClientStoppedTyping, {});
  }
}
