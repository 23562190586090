import { AxiosInstance } from 'axios';
import { IMetapagesResponse } from 'core/types';

export class MetaPagesService {
  static init(restService: AxiosInstance): MetaPagesService {
    return new MetaPagesService(restService);
  }

  constructor(private restService: AxiosInstance) {
  }

  listPages() {
    return this.restService.get<IMetapagesResponse[]>('/public/meta/pages');
  }
}
