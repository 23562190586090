<template>
  <transition name="short-popup">
    <div
      v-if="modelValue"
      class="short-popup"
      :class="overlayClass"
    >
      <div
        class="short-popup__wrapper"
        v-bind="$attrs"
      >
        <icon-btn
          v-if="closable"
          class="short-popup__close"
          @click="close"
        >
          <icon
            :name="IconName.Crosses"
            :type="IconType.Contour"
            :size="Size.Xxs"
          />
        </icon-btn>

        <slot />
      </div>
    </div>
  </transition>
</template>

<script lang="ts" src="./ShortPopup.ts" />
<style lang="scss" src="./shortPopup.scss" />
