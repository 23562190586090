import { noop } from 'core/helpers';
import { MainColor, Size } from 'core/styles';
import { ifElse } from 'ramda';
import { computed, defineComponent, PropType, toRefs } from 'vue';
import { useRouter } from 'vue-router';

const IconBtn = defineComponent({
  props: {
    size: {
      type: String as PropType<Size>,
      default: Size.M,
    },
    color: {
      type: String as PropType<MainColor>,
      default: MainColor.Black,
    },
    to: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { slots }) {
    const { to } = toRefs(props);

    const router = useRouter();

    const hasMarker = computed((): boolean => !!slots?.marker);

    const tag = computed(ifElse(
      () => !!to.value,
      () => 'a',
      () => 'button',
    ));

    const handleClick = ifElse(
      () => !!to.value,
      () => router.push(to.value ?? ''),
      noop,
    );

    return {
      tag,
      hasMarker,
      handleClick,
    };
  },
});

export default IconBtn;
