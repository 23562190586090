import { AxiosInstance } from 'axios';
import { Nullable } from 'core/types';

export interface IUpdatePageContent {
  title: string;
  content?: string;
}

export interface IDashboardPage {
  url: string;
  title: string;
}

export interface IDashboard {
  metrics: {
    transactions?: number;
    users?: number;
  };
  pages: Record<string, IDashboardPage>
}

export enum ServiceSettingsSubject {
  TakeMeToTheTopCostFemale = 'lovista.services.female.App\\Models\\TakeMeToTheTop.cost',
  TakeMeToTheTopTimeFemale = 'lovista.services.female.App\\Models\\TakeMeToTheTop.lifetime',
  SubscriptionCostFemale = 'lovista.services.female.App\\Models\\Subscription.cost',
  SubscriptionTimeFemale = 'lovista.services.female.App\\Models\\Subscription.lifetime',
  HighlightCostFemale = 'lovista.services.female.App\\Models\\Highlight.cost',
  HighlightTimeFemale = 'lovista.services.female.App\\Models\\Highlight.lifetime',
  TakeMeToTheTopCostMale = 'lovista.services.male.App\\Models\\TakeMeToTheTop.cost',
  TakeMeToTheTopTimeMale = 'lovista.services.male.App\\Models\\TakeMeToTheTop.lifetime',
  SubscriptionCostMale = 'lovista.services.male.App\\Models\\Subscription.cost',
  SubscriptionTimeMale = 'lovista.services.male.App\\Models\\Subscription.lifetime',
  HighlightCostMale = 'lovista.services.male.App\\Models\\Highlight.cost',
  HighlightTimeMale = 'lovista.services.male.App\\Models\\Highlight.lifetime',
  MessageSubscriptionMaxCostMale = 'lovista.services.male.App\\Models\\MessagingSubscription.max.price',
  MessageSubscriptionMaxTimeMale = 'lovista.services.male.App\\Models\\MessagingSubscription.max.lifetime',
  MessageSubscriptionMediumCostMale = 'lovista.services.male.App\\Models\\MessagingSubscription.medium.price',
  MessageSubscriptionMediumTimeMale = 'lovista.services.male.App\\Models\\MessagingSubscription.medium.lifetime',
  MessageSubscriptionSmallCostMale = 'lovista.services.male.App\\Models\\MessagingSubscription.small.price',
  MessageSubscriptionSmallTimeMale = 'lovista.services.male.App\\Models\\MessagingSubscription.small.lifetime',
  RemoveAllMessagesCost = 'lovista.services.conversation_deletion.cost',
  Incognito1Cost = 'lovista.services.incognito.1.cost',
  Incognito6Cost = 'lovista.services.incognito.6.cost',
  ContactView1Cost = 'lovista.services.contacts.1.cost',
  ContactView5Cost = 'lovista.services.contacts.5.cost',
  ContactView10Cost = 'lovista.services.contacts.10.cost',
  ContactViewUnlim1MonthCost = 'lovista.services.contacts.unlim.1.month.cost',
}

export interface IServiceSettings {
  id: number;
  created_at: string;
  updated_at: string;
  subject: ServiceSettingsSubject,
  label: Nullable<string>;
  description: Nullable<string>
  value: string,
}

export class AdminService {
  static init(restService: AxiosInstance): AdminService {
    return new AdminService(restService);
  }

  constructor(private restService: AxiosInstance) {
  }

  fetchDashboard() {
    return this.restService.get<IDashboard>('private/dashboard');
  }

  updatePageContent(page: string, model: IUpdatePageContent) {
    return this.restService.post(`private/pages/${page}`, model);
  }

  fetchPageContent(page: string) {
    return this.restService.get(`private/pages/${page}`);
  }

  addOrRemoveAdmin(userId: number) {
    return this.restService.post(`private/administrators/${userId}`);
  }

  setShadowBan(userId: number, isSet: number) {
    return this.restService.post(`private/shadow-ban/${userId}`, { set: isSet });
  }

  block(userId: number, date: string) {
    return this.restService.post(`private/block/${userId}`, { date });
  }

  deleteToggle(userId: number, deleting: number) {
    return this.restService.post(`private/delete-toggle/${userId}`, { deleting });
  }

  fetchServiceSettings() {
    return this.restService.get<IServiceSettings[]>('public/dictionary/list');
  }

  updateServiceSetting(id: number, value: number) {
    return this.restService.post('private/dictionary/update', {
      id,
      value,
    });
  }
}
