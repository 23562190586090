
import { defineComponent } from 'vue';
import { useNotificationsGetter, NotificationsGetter } from 'store/notifications';
import { MainColor, Size } from 'core/styles';
import { IconType, IconName } from 'components/Icon';

export default defineComponent({
  setup() {
    const { result: hasUnviewed } = useNotificationsGetter<boolean>(NotificationsGetter.HasUnviewed);

    return {
      hasUnviewed,
    };
  },

  data: () => ({
    MainColor,
    Size,
    IconType,
    IconName,
  }),
});
