export enum UsersGetter {
  Profile = 'PROFILE',
  ThemeColor = 'THEME_COLOR',
  List = 'LIST',
  ListPagination = 'LIST_PAGINATION',
  ListHasNext = 'LIST_HAS_NEXT',
  HasFilter = 'HAS_FILTER',
  Filter = 'FILTER',
  FilterDefaults = 'FILTER_DEFAULTS',
  FilterValues = 'FILTER_VALUES',
  AuthToken = 'AUTH_TOKEN',
  IsAuth = 'IS_AUTH',
  GeolocationRequired = 'GEOLOCATION_REQUIRED',
  GeolocationCoords = 'GEOLOCATION_COORDS',
  NewUsers = 'NEW_USERS',
  TopUsers = 'TOP_USERS',
  TotalNewUsers = 'TOTAL_NEW_USERS',
  TotalTopUsers = 'TOTAL_TOP_USERS',
  MaleUsers = 'MALE_USERS',
  FemaleUsers = 'FEMALE_USERS',
  TotalMaleUsers = 'TOTAL_MALE_USERS',
  TotalFemaleUsers = 'TOTAL_FEMALE_USERS',
  IsProfileRaised = 'IS_PROFILE_RAISED',
  Favorites = 'FAVORITES',
  ProfileMainPhoto = 'MAIN_PHOTO',
  TgToken = 'TG_TOKEN',
  FavoritesHasNext = 'FAVORITES_HAS_NEXT',
  SuccessUsersHasNext = 'SUCCESS_USERS_HAS_NEXT',
  EmailNotModerated = 'EMAIL_NOT_MODERATED',
  ProfileNotFilled = 'PROFILE_NOT_FILLED',
  PaidPackages = 'PAID_PACKAGES',
  PaidPackageDiscount = 'PAID_PACKAGE_DISCOUNT',
  AvailablePaidPackages = 'AVAILABLE_PAID_PACKAGES',
  ProfilePaidParams = 'PROFILE_PAID_PARAMS',
  ProfilePaidPackages = 'PROFILE_PAID_PACKAGES',
  RaiseInfo = 'RAISE_INFO',
  SuccessUsers = 'SUCCESS_USERS',
  AuthLog = 'AUTH_LOG',
  AuthLogHasNext = 'AUTH_LOG_HAS_NEXT',
  AllRewardsReceived = 'ALL_REWARDS_RECEIVED',
}
