import { MainColor, Size } from 'core/styles';
import { computed, defineComponent, PropType, toRefs } from 'vue';
import { TxtType } from './enums/TxtType';
import { TxtWeight } from './enums/TxtWeight';

const Txt = defineComponent({
  props: {
    tag: {
      type: String,
      default: null,
    },
    type: {
      type: String as PropType<TxtType>,
      default: TxtType.MainText,
    },
    weight: {
      type: String as PropType<TxtWeight>,
      default: null,
    },
    leading: {
      type: String as PropType<Size>,
      default: null,
    },
    color: {
      type: String as PropType<MainColor>,
      default: null,
    },
  },

  setup(props) {
    const {
      type,
      tag,
    } = toRefs(props);

    const tagName = computed((): string => {
      let t: string;

      const headings = [TxtType.Heading1, TxtType.Heading2, TxtType.Heading3, TxtType.Heading4];

      if (tag.value) {
        t = tag.value;
      } else if (headings.some((x) => x === type.value)) {
        t = type.value;
      } else {
        t = 'span';
      }

      return t;
    });

    return { tagName };
  },
});

export default Txt;
