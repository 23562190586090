<template>
  <router-view v-slot="{ Component }">
    <splash>
      <keep-alive
        :exclude="excludeName"
        :include="includes"
        :max="5"
      >
        <component
          :is="Component"
        />
      </keep-alive>
    </splash>
  </router-view>
</template>

<script lang="ts" src="./KeepAliveRouter.ts" />
