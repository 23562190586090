export enum ChatsMutation {
  setChats = 'SET_CHATS',
  setUnread = 'SET_UNREAD',
  setMessagesByUserId = 'SET_MESSAGES_BY_USER_ID',
  deleteMessagesByUserId = 'DELETE_MESSAGES_BY_USER_ID',
  addUnsentMessage = 'ADD_UNSENT_MESSAGE',
  invalidateMessage = 'INVALIDATE_MESSAGE',
  cancelMessage = 'CANCEL_MESSAGE',
  increaseMessagesLoadCallsCount = 'INCREASE_MESSAGE_LOAD_CALLS_COUNT',
  readMessagesByUserId = 'READ_MESSAGES_BY_USER_ID',
  addChat = 'ADD_CHAT',
  setChatPagination = 'SET_CHAT_PAGINATION',
  ClearState = 'CLEAR_STATE',
  DeleteMessagesById = 'DELETE_MESSAGES_BY_ID',
  AddNewMessage = 'ADD_NEW_MESSAGE',
}
