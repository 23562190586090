import { computed, defineComponent, onBeforeUnmount, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { APP_STORE_KEY, AppMutation } from 'store/app';
import { hideScroll } from 'core/helpers';

const Popup = defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    class: {
      type: [String, Object, Array],
      default: '',
    },
    showBar: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const popupRef = ref();
    const classes = computed(() => {
      const classes = [];
      if (props.class) classes.push(props.class);
      if (props.showBar) classes.push('popup--show-bar');
      return classes;
    });

    const setTabBarOnTop = (flag: boolean) => {
      store.commit(`${APP_STORE_KEY}/${AppMutation.SetTabBarOnTop}`, flag);
    };

    let targets: Array<EventTarget> = [];

    function blurInput(e: any) {
      if (!props.show || !e?.target) return;
      (<HTMLInputElement>e?.target)?.blur();
    }

    function handleFocusIn(e: FocusEvent) {
      if (!props.show || !e.target || popupRef.value?.contains(e.target)) return;
      (<HTMLInputElement>document?.activeElement)?.blur();
      targets.push(e.target);
      e.target.addEventListener('focus', blurInput);
    }

    watchEffect(() => {
      setTabBarOnTop(!!props.showBar && !!props.show);
      hideScroll(props.show);
      if (props.show) {
        document.addEventListener('focusin', handleFocusIn);
      } else {
        clearListeners();
      }
    }, { flush: 'post' });

    function clearListeners() {
      document.removeEventListener('focusin', handleFocusIn);
      targets.forEach((target) => target.removeEventListener('focus', blurInput));
      targets = [];
    }

    onBeforeUnmount(() => {
      setTabBarOnTop(false);
      hideScroll(false);
      clearListeners();
    });

    return {
      popupRef,
      classes,
    };
  },
});

export default Popup;
