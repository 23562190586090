export enum UsersAction {
  GetProfile = 'GET_PROFILE',
  GetUserById = 'GET_USER_BY_ID',
  GetCatalog = 'GET_CATALOG',
  EditProfile = 'EDIT_PROFILE',
  PutClaim = 'PUT_CLAIM',
  CheckAuth = 'CHECK_AUTH',
  PutGeolocationCoords = 'PUT_GEOLOCATION_COORDS',
  AddPhoto = 'ADD_PHOTO',
  AddPrivatePhoto = 'ADD_PRIVATE_PHOTO',
  DeletePhoto = 'DELETE_PHOTO',
  WalletEnroll = 'WALLET_ENROLL',
  RaiseProfile = 'RAISE_PROFILE',
  FetchTopUsers = 'FETCH_TOP_USERS',
  FetchNewUsers = 'FETCH_NEW_USERS',
  FetchMaleUsers = 'FETCH_MALE_USERS',
  FetchFemaleUsers = 'FETCH_FEMALE_USERS',
  BlockUser = 'BLOCK_USER',
  UnblockUser = 'UNBLOCK_USER',
  FetchFavorites = 'FETCH_FAVORITES',
  FetchSuccessUsers = 'FETCH_SUCCESS_USERS',
  SetAsMain = 'SET_AS_MAIN',
  ChangeTheme = 'CHANGE_THEME',
  AddContacts = 'ADD_CONTACTS',
  ChangeEmail = 'CHANGE_EMAIL',
  FetchPaidPackages = 'FETCH_PAID_PACKAGES',
  BuyPaidPackages = 'BUY_PAID_PACKAGES',
  FetchAuthLog = 'FETCH_AUTH_LOG',
  GetReward = 'GET_REWARD',
  BuyIncognito = 'BUY_INCOGNITO',
  BuyContact = 'BUY_CONTACTS',
  OpenContact = 'OPEN_CONTACTS',
}
