import { Nullable } from 'core/types';
import { ContactType } from './enums/ContactType';
import type { IContact, IGeolocaton } from './interfaces/IContact';

export class Contact implements IContact {
  public static create(raw: IContact): Contact {
    return new Contact(raw);
  }

  public type: ContactType;
  public contact: string;
  public geolocation?: Nullable<IGeolocaton>;

  public constructor(raw: IContact) {
    this.type = raw.type;
    this.contact = raw.contact;
    this.geolocation = raw.geolocation ?? null;
  }

  public getUrlLink(): string {
    let result = '';

    if (this.type === ContactType.Telegram) {
      result = `https://telegram.me/${this.contact}`;
    } else if (this.type === ContactType.WhatsApp) {
      result = `/wa?phone=${this.contact}`;
    } else if (this.type === ContactType.Phone) {
      result = `tel:${this.contact}`;
    } else {
      result = this.contact;
    }

    return result;
  }
}
