<template>
  <label class="checkbox">
    <input
      type="checkbox"
      class="checkbox__input"

      :value="modelValue"
      :checked="checked"

      @input="$emit('update:modelValue', $event.target.value)"
    >

    <span
      :class="{
        'checkbox__label': true,
        'checkbox__label_not-empty': !!label
      }"
    >
      {{ label }}
    </span>
  </label>
</template>

<script lang="ts" src="./Checkbox.ts"></script>
<style lang="scss" src="./checkbox.scss"></style>
