import { Module } from 'vuex';
import { getDefaultUsersState } from 'store/users/getDefaultUsersState';
import { IStoreState } from '../interfaces/IStoreState';
import { usersActions } from './actions';
import { usersGetters } from './getters';
import { usersMutations } from './mutations';
import { IUsersState } from './interfaces/IUsersState';

export const users: Module<IUsersState, IStoreState> = {
  namespaced: true,

  state: getDefaultUsersState(),

  getters: usersGetters,
  mutations: usersMutations,
  actions: usersActions,
};
