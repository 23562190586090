import { MajorityValues, StorageKeys, storageService } from 'storage';
import { Router } from 'vue-router';
import { Nullable } from 'core/types';
import { RouteNames } from 'router/names';

export function useMajorityMiddleware(router: Router) {
  let isMajority = storageService.getItem(StorageKeys.IsMajority) as Nullable<MajorityValues>;
  storageService.subscribe(StorageKeys.IsMajority, (val) => {
    isMajority = val as Nullable<MajorityValues>;
  });

  router.beforeEach((to) => {
    if (to.name === RouteNames.Rules) return true;

    switch (isMajority) {
      case MajorityValues.NotMajority: return { name: RouteNames.Rules };
      case MajorityValues.NotViewed:
      case MajorityValues.Majority:
      default: break;
    }
    return true;
  });
}
