import { IAdminState } from 'store/admin/index';

export function getDefaultAdminState(): IAdminState {
  return {
    dashboard: {
      metrics: {},
      pages: {},
    },
  };
}
