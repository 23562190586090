<template>
  <button
    v-if="!to"
    :type="actionType"
    :class="[
      `btn btn_${type}`,
      outlined ? 'btn_outlined' : undefined,
      center ? 'btn_center' : undefined,
    ]"
    :disabled="disabled"
    :style="style"
  >
    <slot />
  </button>

  <router-link
    v-else
    v-slot="{ href, navigate }"
    custom
    :to="to"
  >
    <a
      v-if="disabled"
      :class="[
        `btn btn_${type}`,
        outlined ? 'btn_outlined' : undefined,
        center ? 'btn_center' : undefined,
      ]"
      :style="style"
      v-bind="$attrs"
      @click.prevent
    >
      <slot />
    </a>
    <a
      v-else
      :href="href"
      :class="[
        `btn btn_${type}`,
        outlined ? 'btn_outlined' : undefined,
        center ? 'btn_center' : undefined,
      ]"
      :style="style"
      v-bind="$attrs"
      @click="navigate"
    >
      <slot />
    </a>
  </router-link>
</template>

<script lang="ts" src="./Btn.ts"></script>
<style lang="scss" src="./btn.scss"></style>
