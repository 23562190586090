import { AxiosInstance, AxiosResponse } from 'axios';
import { INotification, INotificationSettings } from 'api/notifications/models';
import { IApiPaginateResponse } from 'api/api-request';

export class NotificationService {
  static init(restService: AxiosInstance) {
    return new NotificationService(restService);
  }

  constructor(private restService: AxiosInstance) {
  }

  getNotifications(page = 1) {
    return this.restService.get<IApiPaginateResponse<INotification[]>>('/public/notifications', { params: { page } });
  }

  markAsRead() {
    return this.restService.post('/public/notifications/read');
  }

  readById(id: string): Promise<AxiosResponse<{
    result: boolean,
    notification: INotification
  }>> {
    return this.restService.post(`/public/notifications/read-by-id/${id}`);
  }

  fetchNotificationSettings() {
    return this.restService.get<INotificationSettings>('public/profile/notifications');
  }

  setNotificationSettings(settings: INotificationSettings) {
    return this.restService.post('public/profile/notifications', settings);
  }
}
