<template>
  <label
    class="toggler"
    :class="{ 'toggler__input_disabled': disabled }"
  >
    <input
      type="checkbox"
      class="toggler__input"

      :checked="modelValue"
      :disabled="disabled"

      @change="handleChange"
    >

    <span
      :class="{
        'toggler__label': true,
        'toggler__label_not-empty': !!label
      }"
    >
      {{ label }}
    </span>
  </label>
</template>

<script lang="ts" src="./Toggler.ts"></script>
<style lang="scss" src="./toggler.scss"></style>
