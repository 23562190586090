import type { GetterTree } from 'vuex';
import type { IStoreState } from '../interfaces/IStoreState';
import type { INotificationsState } from './interfaces';
import { NotificationsGetter } from './enums';

export const notificationsGetters: GetterTree<INotificationsState, IStoreState> = {
  [NotificationsGetter.Notifications]: ({ items }) => items,
  [NotificationsGetter.NotificationsPagination]: ({ pagination }) => pagination,
  [NotificationsGetter.HasNext]: (state, getters) => {
    const pagination = getters[NotificationsGetter.NotificationsPagination];
    return !!pagination && pagination.meta.current_page < pagination.meta.last_page;
  },

  [NotificationsGetter.HasUnviewed]: ({ items }) => items?.some(item => !item.read_at),

  [NotificationsGetter.NotificationSettings]: ({ settings }) => settings,

  [NotificationsGetter.LastNotification]: ({ items }) => (items?.length && items[0]) || null,
};
