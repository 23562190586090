import { useStore } from 'vuex';
import { StoreInjectionKey } from '../../constants';
import { STORIES_STORE_KEY } from '../constants';
import { StoriesAction } from '../enums/StoriesAction';

export const useStoriesAction = <T = unknown>(key: StoriesAction, payload?: T): void => {
  const store = useStore<typeof StoreInjectionKey>();

  store.dispatch(`${STORIES_STORE_KEY}/${key}`, payload);
};

export const useGenerateStoriesAction = <T = unknown>(key: StoriesAction) => {
  const store = useStore<typeof StoreInjectionKey>();

  return (payload?: T) => store.dispatch(`${STORIES_STORE_KEY}/${key}`, payload);
};
