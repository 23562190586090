import 'reset-css';
import 'assets/scss/global.scss';
import 'assets/scss/utilities.scss';
import 'dayjs/locale/ru';

// import { makeServer } from 'api/server-mock/make-server';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import weekday from 'dayjs/plugin/weekday';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { VueMaskDirective } from 'v-mask';

const vMaskV2 = VueMaskDirective;

export const vMaskV3 = {
  beforeMount: vMaskV2.bind,
  updated: vMaskV2.componentUpdated,
  unmounted: vMaskV2.unbind,
};

export const setupApp = (): void => {
  // makeServer();

  dayjs.extend(relativeTime);
  dayjs.extend(duration);
  dayjs.extend(isToday);
  dayjs.extend(isYesterday);
  dayjs.extend(weekday);
  dayjs.locale('ru');
  dayjs.extend(customParseFormat);
};
