import type { Module } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { Api } from 'api/api';
import { IDashboard } from 'api/adminService';
import { getDefaultAdminState } from 'store/admin/getDefaultAdminState';
import type { IStoreState } from '../interfaces/IStoreState';

export const ADMIN_STORE_KEY = 'admin';

export interface IAdminState {
  dashboard: IDashboard,
}

export enum AdminGetter {
  IsAdmin = 'IS_ADMIN',
  Metrics = 'METRICS',
  Pages = 'PAGES',
}
export enum AdminAction {
  FetchDashboard = 'FETCH_DASHBOARD',
  FetchPage = 'FETCH_PAGE',
  UpdatePage = 'UPDATE_PAGE',
  AddOrRemoveAdmin = 'ADD_OR_REMOVE_ADMIN',
  UpdateServiceSetting = 'UPDATE_SERVICE_SETTING',
  SetShadowBan = 'SET_SHADOW_BAN',
  Block = 'USER_BLOCK',
  DeleteToggle = 'USER_DELETE_TOGGLE',
}
export enum AdminMutation {
  SetDashboard = 'SET_DASHBOARD',
  ClearState = 'CLEAR_STATE',
}

export const admin: Module<IAdminState, IStoreState> = {
  namespaced: true,

  state: getDefaultAdminState(),
  getters: {
    [AdminGetter.IsAdmin]:
      (state, getters, rootState, rootGetters) => !!rootGetters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]?.is_admin,
    [AdminGetter.Metrics]: (state) => state.dashboard.metrics,
    [AdminGetter.Pages]: (state) => state.dashboard.pages,
  },
  mutations: {
    [AdminMutation.SetDashboard]: (state, dashboard: IDashboard) => {
      state.dashboard = dashboard;
    },
    [AdminMutation.ClearState]: (state) => {
      Object.assign(state, getDefaultAdminState());
    },
  },
  actions: {
    [AdminAction.FetchPage]: async (_, page: string) => {
      const { data } = await Api.adminService.fetchPageContent(page);
      return data;
    },

    [AdminAction.UpdatePage]: async (_, { page, model }) => {
      await Api.adminService.updatePageContent(page, model);
    },

    [AdminAction.AddOrRemoveAdmin]: async (_, userId: number) => {
      const { data } = await Api.adminService.addOrRemoveAdmin(userId);
      return data;
    },

    [AdminAction.SetShadowBan]: async (_, { userId, isSet }) => {
      const { data } = await Api.adminService.setShadowBan(userId, isSet);
      return data;
    },

    [AdminAction.Block]: async (_, { userId, date }) => {
      const { data } = await Api.adminService.block(userId, date);
      return data;
    },

    [AdminAction.DeleteToggle]: async (_, { userId, deleting }) => {
      const { data } = await Api.adminService.deleteToggle(userId, deleting);
      return data;
    },

    [AdminAction.UpdateServiceSetting]: async (_, { id, value }) => {
      await Api.adminService.updateServiceSetting(id, value);
    },

    [AdminAction.FetchDashboard]: async ({ commit }) => {
      const { data } = await Api.adminService.fetchDashboard();
      commit(AdminMutation.SetDashboard, data);
    },
  },
};
