import { IApiCaptchaResponse } from 'api/api-request/interfaces/IApiCaptchaResponse';
import { AxiosInstance } from 'axios';

export class CaptchaService {
  static init(restService: AxiosInstance) {
    return new CaptchaService(restService);
  }

  constructor(private restService: AxiosInstance) {}

  getCaptcha() {
    return this.restService.get<IApiCaptchaResponse>('/public/captcha');
  }
}
