import Pusher, { Channel } from 'pusher-js';
import { PusherChannel, PusherEvent } from './enums';

export class BasePusher {
  protected pusher?: Pusher;
  protected channel?: Channel;

  public setPusher(pusher: Pusher) {
    this.pusher = pusher;
  }

  public unsubscribe() {
    this.channel?.unbind_all();
    this.pusher?.unsubscribe(PusherChannel.PrivateRoom);
    this.channel = undefined;
  }

  public unbind(eventName: PusherEvent, cb: (...args: any) => void | Promise<void>) {
    this.channel?.unbind(eventName, cb);
  }

  public subscribeToSucceeded(cb: () => void | Promise<void>) {
    this.channel?.bind(PusherEvent.SubscriptionSucceeded, cb);
    return () => this.channel?.unbind(PusherEvent.SubscriptionSucceeded, cb);
  }
}
