import { IChatsState } from 'store/chats/interfaces';

export function getDefaultChatsState(): IChatsState {
  return {
    chats: [],
    messagesByUserId: {},
    sendMessageData: {},
    unread: 0,
    loadMessagesCallsCount: {},
  };
}
