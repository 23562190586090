import Modal from './Modal.vue';

export { default as ModalHeader } from './ModalHeader.vue';

export default Modal;

export * from './enums/ModalHeaderDirection';

export * from './interfaces/IModalHeader';
export * from './interfaces/IModalHeaderAction';
