import { Story } from 'api/stories/models/story';
import { MutationTree } from 'vuex';
import { getDefaultStoriesState } from 'store/stories/getDefaultStoriesState';
import { StoriesMutation } from './enums/StoriesMutation';
import { IStoriesState } from './interfaces/IStoriesState';

export const storiesMutations: MutationTree<IStoriesState> = {
  [StoriesMutation.SetStories]: (state, list: Story[]) => {
    state.list = list;
  },
  [StoriesMutation.SetStory]: (state, story: Story) => {
    state.profile = story;
  },
  [StoriesMutation.ClearState]: (state) => {
    Object.assign(state, getDefaultStoriesState());
  },
};
