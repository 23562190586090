export function debounce<T>(
  fn: (arg: T) => void,
  ms: number,
): (arg: T) => void {
  if (ms === 0) {
    return fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let timeout: any;

  return (arg): void => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(arg);
    }, ms);
  };
}
