import { ICropImage } from './types';
import { cropperEventBus } from './eventBus';

export function useCropper() {
  function cropImage(opts: ICropImage) {
    cropperEventBus.emit('crop', opts);
  }

  return {
    cropImage,
  };
}
