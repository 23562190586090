import { useStore } from 'vuex';
import { computed } from 'vue';
import { INotification, NotificationType } from 'api/notifications/models';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { Nullable } from 'core/types';
import { useI18n } from 'vue-i18n';

export function useEmailNotValidatedNotification() {
  const { t } = useI18n();
  const store = useStore();
  const emailNotModerated =
    computed<boolean>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.EmailNotModerated}`]);

  const emailNotification = computed<Nullable<Partial<INotification>>>(() => {
    if (emailNotModerated.value) {
      return {
        type: NotificationType.EmailNotValidated,
        data: {
          text: t('components.pushNotifications.emailNotValidated'),
          actionText: t('components.pushNotifications.emailNotValidatedAction'),
        },
      };
    }

    return null;
  });

  return {
    emailNotification,
  };
}
