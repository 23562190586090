import { useStore } from 'vuex';
import { StoreInjectionKey } from '../../constants';
import { USER_STORE_KEY } from '../constants';
import { UsersGetter } from '../enums/UsersGetter';

export const useUsersGetter = <R = unknown>(key: UsersGetter): R => {
  const store = useStore<typeof StoreInjectionKey>();

  return store.getters[`${USER_STORE_KEY}/${key}`];
};
