<template>
  <span
    class="tag"
    :class="{
      tag_active: active,
      tag_clickable: clickable,
      tag_short: short,
      [`tag_size-${size}`]: true,
    }"
  >
    <span
      v-if="hasMarker"
      class="tag__marker"
    >
      <slot name="marker" />
    </span>

    <slot />
  </span>
</template>

<script lang="ts" src="./Tag.ts"></script>
<style lang="scss" src="./tag.scss"></style>
