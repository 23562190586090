import { Module } from 'vuex';
import { IStoreState } from 'store/interfaces/IStoreState';
import { IChatsState } from 'store/chats/interfaces';
import { chatsMutations } from 'store/chats/mutations';
import { chatsActions } from 'store/chats/actions';
import { chatsGetters } from 'store/chats/getters';
import { getDefaultChatsState } from 'store/chats/getDefaultChatsState';

export const chatsModule: Module<IChatsState, IStoreState> = {
  namespaced: true,
  state: getDefaultChatsState(),
  mutations: chatsMutations,
  actions: chatsActions,
  getters: chatsGetters,
};
