import { computed, defineComponent, ref } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';

let uuid = 0;

const RadioGroup = defineComponent({
  props: {
    values: {
      type: null,
      required: true,
    },
    modelValue: {
      type: null,
      required: true,
    },
    order: {
      type: Number,
      default: 1,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: true,
    },
    valueKey: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const groupName = ref(`radio-group-${++uuid}`);
    function isChecked(value: any) {
      return props.valueKey ? props.modelValue === value?.[props.valueKey] : props.modelValue === value;
    }

    function onChange(value: any) {
      emit('update:modelValue', props.valueKey ? value?.[props.valueKey] : value);
    }

    function isDisabled(value: any) {
      return !!value?.disabled;
    }

    const radioValues = computed(() => {
      if (props.order === 1) return props.values;
      return props.values.slice().reverse();
    });

    return {
      groupName,
      isChecked,
      onChange,
      isDisabled,
      radioValues,

      IconName,
      IconType,
      Size,
    };
  },
});

export default RadioGroup;
