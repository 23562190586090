import { ActionTree } from 'vuex';
import { IStoreState } from 'store/interfaces/IStoreState';
import { ChatsAction, ChatsGetter, ChatsMutation } from 'store/chats/enums';
import { Api } from 'api/api';
import { Message, SendMessageData } from 'api/chatsService';
import { v4 as uuidv4 } from 'uuid';
import { USER_STORE_KEY, UsersAction, UsersGetter, UsersMutation } from 'store/users';
import { IApiPaginateResponseMeta } from 'api/api-request';
import { IChatsState, MessageSendCallbacks } from './interfaces';

const emptyCallbacks: MessageSendCallbacks = {
  onSuccess: (_m: string, _r: number) => '',
  onError: (_m: string, _r: number) => '',
};

async function sendPhotoMessage(userId: number, payload: SendMessageData) {
  if (!payload.photo) throw new Error('Invalid photo');
  const { data } = await Api.chatsService.sendMessageToUser(userId, {
    type: 'photo',
  });
  for (const photo of payload.photo) {
    const { data: uploadData } = await Api.chatsService.uploadPhoto(data.id, photo);
    if (uploadData.error) throw new Error(uploadData.error);
    data.content.photo.push(uploadData.url);
  }
  const { data: publishData } = await Api.chatsService.publishPhotoMessage(data.id);
  if (publishData.error) throw new Error(publishData.error);
  return { data };
}

export const chatsActions: ActionTree<IChatsState, IStoreState> = {
  [ChatsAction.LoadChats]: async ({ commit, rootGetters }) => {
    if (!rootGetters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`]) return;
    const { data } = await Api.chatsService.loadConversations();

    commit(ChatsMutation.setChats, data);
  },
  [ChatsAction.LoadChatByUserId]: async ({ commit }, userId: number) => {
    const { data } = await Api.chatsService.loadConversationByUserId(userId);

    commit(ChatsMutation.addChat, data);
  },
  [ChatsAction.LoadUnread]: async (ctx) => {
    const { data: unread } = await Api.chatsService.loadUnread();

    ctx.commit(ChatsMutation.setUnread, unread);
  },
  [ChatsAction.LoadMessagesByUserId]: async (ctx, { userId, more = false, clear = false }: { userId: number, more: boolean, clear: boolean }): Promise<void> => {
    let page = 1;
    const pagination = ctx.getters[ChatsGetter.MessagePaginationByUserId](userId) as IApiPaginateResponseMeta;
    if (pagination && more) {
      if (pagination.current_page >= pagination.last_page) return;
      page = pagination.current_page + 1;
    }
    let messages: Message[] = [];
    let chat = ctx.getters[ChatsGetter.ChatByUserId](userId);
    if (!chat) {
      await ctx.dispatch(ChatsAction.LoadChatByUserId, userId);
      chat = ctx.getters[ChatsGetter.ChatByUserId](userId);
    }
    try {
      const { data } = await Api.chatsService.loadMessagesByChatId(chat?.id, page);
      messages = data.data;
      if (clear && !more) ctx.commit(ChatsMutation.deleteMessagesByUserId, userId);
      ctx.commit(ChatsMutation.setMessagesByUserId, { userId, messages });
      ctx.commit(ChatsMutation.setChatPagination, { userId, pagination: data.meta });
    } catch (e) {
      ctx.commit(ChatsMutation.setMessagesByUserId, { userId, messages: [] });
    }
    ctx.commit(ChatsMutation.increaseMessagesLoadCallsCount, { userId });
  },
  [ChatsAction.SendMessageToUser]: async (ctx, { userId, data, fetchMessages = true }: {
    userId: number; data: SendMessageData; fetchMessages: boolean;
  }) => {
    // ctx.state.chats.find((chat) => chat);
    const response = await Api.chatsService.sendMessageToUser(userId, data);

    if (fetchMessages) {
      await ctx.dispatch(ChatsAction.LoadMessagesByUserId, { userId });
    }

    return response;
  },

  [ChatsAction.ScheduleNewMessage]: async (ctx, { userId, data, uploadPreview = '', callbacks = emptyCallbacks }: {
    userId: number, data: SendMessageData, uploadPreview: string, callbacks: MessageSendCallbacks,
  }) => {
    const messageId = uuidv4();
    const sendData = data;
    sendData.id = messageId;

    const fakeMessage: Message = {
      id: sendData.id,
      content: {
        [sendData.type]: sendData.text,
      },
      type: sendData.type,
      created_at: new Date().toString(),
      state: 'new',
      recipient_id: userId,
      sender_id: -1,
      read_at: null,
      message_dialog_id: '',
      refer: null,
      updated_at: new Date().toString(),
    };

    if (fakeMessage.type === 'photo') {
      fakeMessage.content.text = uploadPreview;
      fakeMessage.content = {
        photo: uploadPreview,
      };
    }

    if (fakeMessage.type === 'private_photo') {
      fakeMessage.content.text = uploadPreview;
      fakeMessage.content = {
        private_photo: sendData.text?.split('\n'),
      };
    }
    if (fakeMessage.type === 'address') {
      fakeMessage.content = {
        geolocation: sendData.geolocation,
      };
    }
    ctx.commit(ChatsMutation.addUnsentMessage, { userId, message: fakeMessage });

    try {
      let data;
      if (sendData.type === 'photo') {
        data = (await sendPhotoMessage(userId, sendData)).data;
      } else {
        data = (await Api.chatsService.sendMessageToUser(userId, sendData)).data;
      }

      if (!ctx.state.messagesByUserId[userId]?.stored?.length) {
        ctx.dispatch(`${USER_STORE_KEY}/${UsersAction.GetProfile}`, undefined, { root: true });
      }
      fakeMessage.id = data.id;
      ctx.commit(ChatsMutation.setMessagesByUserId, { userId, messages: [data] });
      if (callbacks.onSuccess) {
        callbacks.onSuccess(messageId, userId);
      }
    } catch (e) {
      const { state } = ctx;
      state.sendMessageData[messageId] = sendData;
      ctx.commit(ChatsMutation.invalidateMessage, { userId, messageId });
      if (callbacks.onError) {
        callbacks.onError(messageId, userId, e);
      }
    }

    return fakeMessage;
  },
  [ChatsAction.DeleteMessage]: async ({ commit }, { userId, messageId }: {
    userId: number; messageId: string;
  }) => {
    await Api.chatsService.deleteMessage(userId, messageId);
    commit(ChatsMutation.DeleteMessagesById, { messageId, userId });
  },
  [ChatsAction.CancelMessage]: (ctx, { userId, messageId }: {
    userId: string; messageId: string;
  }) => {
    ctx.commit(ChatsMutation.cancelMessage, { userId, messageId });
  },
  [ChatsAction.RetryMessage]: (ctx, { userId, messageId }: {
    userId: string; messageId: string;
  }) => {
    const message = ctx.state.sendMessageData[messageId];
    if (!message) return;
    ctx.dispatch(ChatsAction.SendMessageToUser, { userId, message });
  },
  [ChatsAction.DeleteSelfChat]: async ({ commit }, userId: number) => {
    await Api.chatsService.deleteSelfChat(userId);
    commit(ChatsMutation.deleteMessagesByUserId, userId);
  },
  [ChatsAction.DeleteAllChat]: async ({ commit }, userId: number) => {
    await Api.chatsService.deleteAllChat(userId);
    commit(ChatsMutation.deleteMessagesByUserId, userId);
    commit(`${USER_STORE_KEY}/${UsersMutation.DecrementConversationDeletions}`, undefined, { root: true });
  },
  [ChatsAction.PurchaseDeletion]: async ({ dispatch }) => {
    await Api.chatsService.purchaseDeletion();

    await dispatch(`${USER_STORE_KEY}/${UsersAction.GetProfile}`, undefined, { root: true });
  },
  [ChatsAction.ReadMessagesByUserId]: async ({ state, commit }, userId) => {
    const messages = state.messagesByUserId[userId]?.stored;
    if (!messages?.length) return;
    let l = messages.length;
    while (l--) {
      if (messages[l].sender_id === userId) {
        break;
      }
    }

    if (l >= 0 && !messages[l].read_at) {
      Api.chatsService.readMessage(userId, messages[l].id as string);
      commit(ChatsMutation.readMessagesByUserId, userId);
    }
  },

  [ChatsAction.ResetChat]: async ({ state, dispatch, getters, commit }, loadId?: number) => {
    await dispatch(ChatsAction.LoadChats);
    if (loadId) await dispatch(ChatsAction.LoadMessagesByUserId, { userId: loadId, more: false, clear: true });
    Object.keys(state.messagesByUserId).forEach((userId) => {
      // @ts-ignore
      // eslint-disable-next-line eqeqeq
      if (userId != loadId) commit(ChatsMutation.deleteMessagesByUserId, userId);
    });
  },
};
