import { VoidFn } from 'core/types';
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref, toRefs, watch } from 'vue';
import { ContextMenuPosition } from './enums/ContextMenuPosition';
import { IContextMenuItem } from './interfaces/IContextMenuItem';

const ContextMenu = defineComponent({
  props: {
    pos: {
      type: Number as PropType<ContextMenuPosition>,
      default: ContextMenuPosition.Left,
    },
    list: {
      type: Array as PropType<IContextMenuItem[]>,
      required: true,
    },
    cssClass: {
      type: String as PropType<string>,
      default: '',
    },
  },

  emits: ['changeVisible'],

  setup(props, { emit }) {
    const { pos, list } = toRefs(props);

    const isShow = ref(false);
    const position = ref([0, 0]);

    watch(isShow, (val) => {
      emit('changeVisible', val);
    });

    const handleOpen = (e: MouseEvent) => {
      const el = e.target as HTMLElement;

      if (el) {
        const bounds = el.getBoundingClientRect();
        position.value = [
          pos.value === ContextMenuPosition.Left ? bounds.left : 15,
          bounds.bottom,
        ];
      }

      isShow.value = true;
    };

    const handleDocClick = (e: MouseEvent): void => {
      const el = e.target as HTMLElement;

      const activator = el?.closest('.context-menu-activator');
      const menu = el?.closest('.context-menu');

      if (!activator && !menu) {
        isShow.value = false;
      }
    };

    const handleAction = (action: VoidFn): void => {
      isShow.value = false;

      action();
    };

    onMounted(() => {
      document.body.addEventListener('click', handleDocClick, false);
    });

    onUnmounted(() => {
      document.body.removeEventListener('click', handleDocClick, false);
    });

    const enabledItems = computed(() => list.value.filter((l) => l.isEnabled !== false));

    return {
      isShow,
      position,
      enabledItems,

      handleOpen,
      handleAction,
    };
  },

  data() {
    return {
      ContextMenuPosition,
    };
  },
});

export default ContextMenu;
