import { computed } from 'vue';
import { useStore } from 'vuex';
import type { ComputedRef } from 'vue';
import { StoreInjectionKey } from 'store/constants';
import { NotificationsGetter, NotificationsMutation, NotificationsAction } from '../enums';
import { NOTIFICATIONS_STORE_KEY } from '../constants';

export const useNotificationsGetter = <T = unknown>(key: NotificationsGetter): {
  result: ComputedRef<T>,
} => {
  const store = useStore<typeof StoreInjectionKey>();

  const result = computed(() => store.getters[`${NOTIFICATIONS_STORE_KEY}/${key}`]);

  return {
    result,
  };
};

export const useNotificationsMutation = <T = unknown>(key: NotificationsMutation): {
  commit: (payload: T) => void
} => {
  const store = useStore<typeof StoreInjectionKey>();

  function commit(payload: T) {
    store.commit(`${NOTIFICATIONS_STORE_KEY}/${key}`, payload);
  }

  return {
    commit,
  };
};

export const useNotificationsAction = <T = unknown>(key: NotificationsAction): {
  dispatch: (payload?: T) => Promise<void>
} => {
  const store = useStore<typeof StoreInjectionKey>();

  async function dispatch(payload?: T) {
    store.dispatch(`${NOTIFICATIONS_STORE_KEY}/${key}`, payload);
  }

  return {
    dispatch,
  };
};
