import { Api } from 'api/api';

import type { Module } from 'vuex';
import type { IPagesResponse } from 'core/types';
import { getDefaultPagesState } from 'store/pages/getDefaultPagesState';
import type { IStoreState } from '../interfaces/IStoreState';

export const PAGES_STORE_KEY = 'pages';

export interface IPagesState {
  items: Record<string, IPagesResponse>
}

export enum PagesGetter {
  Pages = 'PAGES',
}
export enum PagesMutation {
  SetPage = 'SET_PAGE',
  ClearState = 'CLEAR_STATE'
}
export enum PagesAction {
  GetPage = 'GET_PAGE',
}

export const pages: Module<IPagesState, IStoreState> = {
  namespaced: true,

  state: getDefaultPagesState(),

  getters: {
    [PagesGetter.Pages]: ({ items }) => items,
  },
  mutations: {
    [PagesMutation.SetPage]: (state, [page, data]: [string, IPagesResponse]) => {
      state.items[page] = data;
    },
    [PagesMutation.ClearState]: (state) => {
      Object.assign(state, getDefaultPagesState());
    },
  },
  actions: {
    [PagesAction.GetPage]: async ({ commit }, page: string) => {
      const { data } = await Api.pagesService.getPage(page);

      commit(PagesMutation.SetPage, [page, data]);
    },
  },
};
