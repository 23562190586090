import { globalComponents } from 'components/global-components';
import { i18n } from 'i18n/i18n';
import { forEachObjIndexed } from 'ramda';
import { store } from 'store/store';

import { createApp } from 'vue';
import Vue3TouchEvents from 'vue3-touch-events';
import { ClickOutsideDirective } from 'directives/clickOutside';
import lazySizes from 'lazysizes';
import { setPageReloadTimer } from 'core/helpers/pageReloadTimer';
import { useErrorsPopup } from 'modules/errorsPopup';
import App from './App.vue';
import router from './router';
import { setupApp, vMaskV3 } from './setup';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import addSentry from './trackers/sentry';
import addTagManger from './trackers/tagManager';

setPageReloadTimer();
const { showErrorsPopup } = useErrorsPopup();

lazySizes.cfg.loadMode = 2;

setupApp();

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Vue3TouchEvents)
  .directive('mask', vMaskV3)
  .directive('click-outside', ClickOutsideDirective);

forEachObjIndexed((v, k) => app.component(k, v), globalComponents);

addSentry(app, router);
addTagManger(app, router);

app.config.errorHandler = (e) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') console.error(e);
  else showErrorsPopup();
};

app.mount('#app');
