import { GetterTree } from 'vuex';
import { IChatsState } from 'store/chats/interfaces';
import { IStoreState } from 'store/interfaces/IStoreState';
import { ChatsGetter } from 'store/chats/enums';
import { Message } from 'api/chatsService';
import { IApiPaginateResponseMeta } from 'api/api-request';

export const chatsGetters: GetterTree<IChatsState, IStoreState> = {
  [ChatsGetter.Chats]: state => Object.values(state.chats),
  [ChatsGetter.Unread]: state => state.unread,
  [ChatsGetter.ChatByUserId]: (state, getters) => (userId: string) => {
    const chats = getters[ChatsGetter.Chats];
    if (!chats) return undefined;

    const items = Array.isArray(chats) ? chats : Object.values(chats);
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    return items.find((item) => item.opposite_user.id == userId);
  },
  [ChatsGetter.MessagesByUserId]: (state) => (id: string | number) => {
    const mset = state.messagesByUserId[id];
    let messages: Message[] = [];
    if (!mset) return messages;
    messages = messages
      .concat(mset.stored || [])
      .concat(mset.pending || []);
    return messages;
  },
  [ChatsGetter.MessagePaginationByUserId]: (state) => (id: number) => {
    const mset = state.messagesByUserId[id];
    if (!mset) return undefined;
    return mset.pagination;
  },
  [ChatsGetter.ChatHasNextPage]: (state, getters) => (userId: number) => {
    const pagination: IApiPaginateResponseMeta | undefined = getters[ChatsGetter.MessagePaginationByUserId](userId);
    return pagination && pagination.current_page < pagination.last_page;
  },
  [ChatsGetter.IsMessagesLoaded]: (state) => (userId: string | number) => !!(state.loadMessagesCallsCount[userId]),
};
