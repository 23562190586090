import type { INotification } from 'api/notifications/models';
import type { MutationTree } from 'vuex';
import dayjs from 'dayjs';
import { getDefaultNotificationsState } from 'store/notifications/getDefaultNotificationsState';
import { INotificationSettings } from 'api/users/models/user';
import { IApiPaginateResponse } from 'api/api-request';
import type { INotificationsState } from './interfaces';
import { NotificationsMutation } from './enums';

export const notificationsMutations: MutationTree<INotificationsState> = {
  [NotificationsMutation.SetNotifications]: (state, data: IApiPaginateResponse<INotification[]>) => {
    state.pagination = {
      meta: data.meta,
      links: data.links,
    };

    if (!state.items) {
      state.items = data.data;
      return;
    }

    data.data.forEach(item => {
      if (!state.items) return;
      const index = state.items.findIndex(x => x?.id === item?.id);

      if (index === -1) {
        state.items.push(item);
      } else {
        state.items.splice(index, 1, item);
      }
    });
  },

  [NotificationsMutation.UpdateNotification]: ({ items }, notification: INotification) => {
    if (!items) return;
    const index = items.findIndex((item) => item.id === notification.id);
    if (index >= 0) {
      items?.splice(index, 1, notification);
    }
  },

  [NotificationsMutation.ClearNotifications]: (state) => {
    state.items = [];
    state.pagination = null;
  },

  [NotificationsMutation.MarkAsRead]: (state) => {
    if (!state.items) return;

    state.items = state.items.map(item => ({
      ...item,
      read_at: dayjs().toISOString(),
    }));
  },

  [NotificationsMutation.ClearState]: (state) => {
    Object.assign(state, getDefaultNotificationsState());
  },

  [NotificationsMutation.SetNotificationSettings]: (state, settings: INotificationSettings) => {
    state.settings = settings;
  },
};
