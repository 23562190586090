import { RouteLocationNormalized, Router } from 'vue-router';
import { i18n } from 'i18n/i18n';
import { nextTick } from 'vue';

function setTitle(to: RouteLocationNormalized) {
  nextTick(() => {
    if (to.meta.title === false) return;
    if (typeof to.meta.title === 'string') {
      document.title = to.meta.title;
      return;
    }
    document.title = i18n.global.t('pageTitles.default');
  });
}

function setDescription(to: RouteLocationNormalized) {
  nextTick(() => {
    if (to.meta.description === false) return;
    const descriptionEl = document?.querySelector('meta[name="description"]');
    if (!descriptionEl) return;
    if (typeof to.meta.description === 'string') {
      descriptionEl.setAttribute('content', to.meta.description);
      return;
    }
    descriptionEl.setAttribute('content', i18n.global.t('pageDescriptions.default'));
  });
}

function setKeywords(to: RouteLocationNormalized) {
  nextTick(() => {
    if (to.meta.keywords === false) return;
    const keywordsEl = document?.querySelector('meta[name="keywords"]');
    if (!keywordsEl) return;
    if (typeof to.meta.keywords === 'string') {
      keywordsEl.setAttribute('content', to.meta.keywords);
      return;
    }
    keywordsEl.setAttribute('content', i18n.global.t('pageKeywords.default'));
  });
}

function setMetaSchemaJsonLd(to: RouteLocationNormalized) {
  const script = document?.querySelector('script[type="application/ld+json"]');
  if (!script) return;

  const defaultSchemaData = {
    name: 'Lovista',
    url: `${window.location.protocol}//${process.env.VUE_APP_SENTRY_TRACKING_ORIGIN}${to.fullPath}`,
    description: 'Сайт онлайн знакомств Lovista предлагает уникальный опыт общения и встреч.',
  };

  script.textContent = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    ...defaultSchemaData,
    ...to.meta.schemaJsonLd,
  });
}

function setMetaOpenGraph(to: RouteLocationNormalized) {
  const defaultOGData = {
    site_name: 'Lovista',
    type: 'website',
    locale: 'ru_RU',
    url: `${window.location.protocol}//${process.env.VUE_APP_SENTRY_TRACKING_ORIGIN}${to.fullPath}`,
  };

  function addMetaTag(property: string, content?: string) {
    if (content) {
      nextTick(() => {
        const metaTagEl = document?.querySelector(`meta[property="og:${property}"]`);
        if (!metaTagEl) return;
        metaTagEl.setAttribute('content', content);
      });
    }
  }
  if (to.meta?.og) {
    const data = { ...defaultOGData, ...to.meta.og };
    addMetaTag('title', data.title);
    addMetaTag('description', data.description);
    addMetaTag('url', data.url);
    addMetaTag('site_name', data.site_name);
    addMetaTag('type', data.type);
    addMetaTag('locale', data.locale);
    addMetaTag('image', data.image);
  }
}

export function useMetaMiddleware(router: Router) {
  router.isReady().then(() => {
    setTitle(router.currentRoute.value);
    setDescription(router.currentRoute.value);
    setKeywords(router.currentRoute.value);
    setMetaOpenGraph(router.currentRoute.value);
    setMetaSchemaJsonLd(router.currentRoute.value);
  });
  router.afterEach((to) => {
    setTitle(to);
    setDescription(to);
    setKeywords(to);
    setMetaOpenGraph(to);
    setMetaSchemaJsonLd(to);
  });
}
