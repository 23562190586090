import { defineComponent } from 'vue';

const Toggler = defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(_, { emit }) {
    const handleChange = (e: InputEvent) => {
      const t = e.target as HTMLInputElement;

      emit('update:modelValue', t.checked);
    };

    return {
      handleChange,
    };
  },
});

export default Toggler;
