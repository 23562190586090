import { Size } from 'core/styles';
import { defineComponent, PropType } from 'vue';
import { IconName, IconType } from '../Icon';
import { TxtType } from '../Txt';
import { ModalHeaderDirection } from './enums/ModalHeaderDirection';

const ModalHeader = defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    dir: {
      type: String as PropType<ModalHeaderDirection>,
      default: ModalHeaderDirection.Ltr,
    },
    actionText: {
      type: String,
      default: undefined,
    },
    relative: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close', 'action'],

  setup() {
    return {
      Size,
      IconType,
      IconName,
      TxtType,
    };
  },
});

export default ModalHeader;
