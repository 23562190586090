import type { UnknownFn, UnknownObject } from '../types';

export const isArray = (value: unknown): value is unknown[] =>
  Array.isArray(value);

export const isObject = (value: unknown): value is UnknownObject =>
  typeof value === 'object';

export const isFunction = (value: unknown): value is UnknownFn =>
  typeof value === 'function';

export const isString = (value: unknown): value is string =>
  typeof value === 'string';

export const isNumber = (value: unknown): value is number =>
  typeof value === 'number';

export const isNil = (value: unknown): value is (null | undefined) =>
  // eslint-disable-next-line eqeqeq
  value == undefined;
