import { MutationTree } from 'vuex';
import { ISubscriptionsState } from 'store/subscriptions/interfaces/ISubscriptionsState';
import { SubscriptionMutation } from 'store/subscriptions/enums/SubscriptionMutation';
import { ISubscription } from 'api/users/models/user/interfaces/ISubscription';
import { IApiPaginateResponse } from 'api/api-request';
import { IUser } from 'api/users/models/user';
import { getDefaultSubscriptionsState } from 'store/subscriptions/getDefaultSubscriptionsState';

export const SubscriptionMutations: MutationTree<ISubscriptionsState> = {
  [SubscriptionMutation.SetSubscriptions]: (state, subscriptions: ISubscription[]) => {
    state.subscriptions = subscriptions;
  },
  [SubscriptionMutation.ClearSubscriptionUsers]: (state, subscriptionId: number) => {
    delete state.subscriptionUsers[subscriptionId];
  },
  [SubscriptionMutation.SetSubscription]: (state, subscription) => {
    if (!state.subscriptions) return;
    const index = state.subscriptions.findIndex((s) => s.id === subscription.id);
    if (index >= 0) {
      state.subscriptions[index] = subscription;
    }
  },
  [SubscriptionMutation.SetSubscriptionUsers]: (state, { subscriptionUsers, subscriptionId }:
    { subscriptionUsers: IApiPaginateResponse<IUser[]>, subscriptionId: number }) => {
    if (!state.subscriptionUsers[subscriptionId]) {
      state.subscriptionUsers[subscriptionId] = subscriptionUsers;
      return;
    }

    state.subscriptionUsers[subscriptionId] = {
      ...subscriptionUsers,
      data: state.subscriptionUsers[subscriptionId].data?.concat(subscriptionUsers?.data || []) || [],
    };
  },
  [SubscriptionMutation.SetMessagingSubscription]: (state, messagingSubscription) => {
    state.messagingSubscription = messagingSubscription;
  },
  [SubscriptionMutation.ClearState]: (state) => {
    Object.assign(state, getDefaultSubscriptionsState());
  },

  [SubscriptionMutation.UpdateSubscription]: (state, subscription: ISubscription) => {
    if (!state.subscriptions) state.subscriptions = [subscription];
    else state.subscriptions.unshift(subscription);
  },
};
