import type { IProfile } from 'api/users/models/user';
import { BodyType, BreastSize, FieldType, HairColor, HipType, InterestType, Sex } from 'api/users/models/user';
import type { GetterTree } from 'vuex';
import { IDefaultUserFilter } from 'store/users/interfaces/IDefaultUserFilter';
import { CITIES_STORE_KEY, CitiesGetter } from 'store/cities';
import { ICity } from 'core/types';
import { usersFilterValueDefaults } from 'core/values';
import { ModeratedReason } from 'api/users/models/user/enums/ModeratedReason';
import { UsersGetter } from './enums/UsersGetter';
import type { IStoreState } from '../interfaces/IStoreState';
import type { IUsersState } from './interfaces/IUsersState';

export const usersGetters: GetterTree<IUsersState, IStoreState> = {
  [UsersGetter.AuthToken]: ({ authToken }) => authToken,
  [UsersGetter.IsAuth]: ({ authToken }) => !!authToken,
  [UsersGetter.Profile]: ({ profile }, getters, rootState, rootGetters) => {
    const cities: ICity[] = rootGetters[`${CITIES_STORE_KEY}/${CitiesGetter.Cities}`];
    return profile ? {
      ...profile,
      highlight: profile.highlight && (new Date(profile.highlight.expired_at) > new Date()) ? profile.highlight : null,
      oppositeGender: profile.gender === Sex.Male ? Sex.Female : Sex.Male,
      city: cities.find((city) => city.id === profile.city_id) || null,
    } as IProfile : null;
  },
  [UsersGetter.RaiseInfo]: ({ profile }) => profile?.top || null,
  [UsersGetter.IsProfileRaised]: ({ profile }) => !!(profile?.top && new Date(profile.top.expired_at) > new Date()),
  [UsersGetter.ThemeColor]: ({ profile }) => profile?.appearance?.color,
  [UsersGetter.List]: ({ list }) => list,
  [UsersGetter.ListPagination]: ({ listPagination }) => listPagination,
  [UsersGetter.ListHasNext]: ({ listPagination }) => {
    if (!listPagination) return true;
    const { meta } = listPagination;
    return meta.current_page < meta.last_page;
  },
  [UsersGetter.FavoritesHasNext]: ({ favoritesPagination }) => {
    if (!favoritesPagination) return true;
    const { meta } = favoritesPagination;
    return meta.current_page < meta.last_page;
  },
  [UsersGetter.SuccessUsersHasNext]: ({ successUsersPagination }) => {
    if (!successUsersPagination) return true;
    const { meta } = successUsersPagination;
    return meta.current_page < meta.last_page;
  },
  [UsersGetter.AuthLogHasNext]: ({ authLogPagination }) => {
    if (!authLogPagination) return true;
    const { meta } = authLogPagination;
    return meta.current_page < meta.last_page;
  },

  [UsersGetter.FilterValues]: () => ({
    age: usersFilterValueDefaults.age,
    height: usersFilterValueDefaults.height,
    hair: [
      HairColor.Blond,
      HairColor.Brunette,
      HairColor.Brown,
      HairColor.Fair,
      HairColor.Red,
      HairColor.Colorful,
      HairColor.None,
    ],
    breast: [
      BreastSize.One,
      BreastSize.Two,
      BreastSize.Three,
      BreastSize.Four,
      BreastSize.FivePlus,
    ],
    hips: [
      HipType.Small,
      HipType.Middle,
      HipType.Round,
    ],
    activity: [
      FieldType.Finance,
      FieldType.Tourism,
      FieldType.Education,
      FieldType.It,
      FieldType.Medicine,
      FieldType.Trade,
      FieldType.Industry,
      FieldType.Sport,
      FieldType.Art,
      FieldType.Other,
    ],
    body: [
      BodyType.Thin,
      BodyType.Athletic,
      BodyType.Paunchy,
    ],
    interests: [
      InterestType.Books,
      InterestType.Movies,
      InterestType.Music,
      InterestType.Art,
      InterestType.Architecture,
      InterestType.Design,
      InterestType.ForeignLanguages,
      InterestType.Fashion,
      InterestType.Dancing,
      InterestType.Cooking,
      InterestType.Astrology,
      InterestType.Psychology,
      InterestType.Space,
      InterestType.Sport,
      InterestType.Extreme,
      InterestType.Games,
      InterestType.Travel,
      InterestType.Cars,
      InterestType.Aviation,
      InterestType.Yachting,
      InterestType.Business,
      InterestType.Politics,
      InterestType.Investments,
      InterestType.Technology,
      InterestType.Science,
    ],
  }),

  [UsersGetter.FilterDefaults]: (state, getters): IDefaultUserFilter => {
    const profile = getters[UsersGetter.Profile] as IProfile;
    return {
      gender: profile?.oppositeGender,
      age: usersFilterValueDefaults.age,
      height: usersFilterValueDefaults.height,
    };
  },

  [UsersGetter.Filter]: (state) => state.filter,

  [UsersGetter.HasFilter]: ({ filter }) => !!filter,

  [UsersGetter.GeolocationRequired]: (state) => state.geolocationRequired,
  [UsersGetter.GeolocationCoords]: (state) => state.profile?.geolocation,

  [UsersGetter.NewUsers]: (state) => state.newUsers,
  [UsersGetter.TopUsers]: (state) => state.topUsers,
  [UsersGetter.TotalNewUsers]: (state) => state.newUsersTotal,
  [UsersGetter.TotalTopUsers]: (state) => state.topUsersTotal,
  [UsersGetter.MaleUsers]: (state) => state.maleUsers,
  [UsersGetter.FemaleUsers]: (state) => state.femaleUsers,
  [UsersGetter.TotalMaleUsers]: (state) => state.maleUsersTotal,
  [UsersGetter.TotalFemaleUsers]: (state) => state.femaleUsersTotal,

  [UsersGetter.Favorites]: (state) => state.favorites,
  [UsersGetter.SuccessUsers]: (state) => state.successUsers,
  [UsersGetter.AuthLog]: (state) => state.authLog,

  [UsersGetter.ProfileMainPhoto]: ({ profile }) => {
    if (!profile) return null;
    return profile.photos.find((photo) => photo.main) || null;
  },

  [UsersGetter.TgToken]: ({ profile }) => profile?.tg_token,
  [UsersGetter.EmailNotModerated]: ({ profile }) => profile?.moderated === ModeratedReason.EmailNotValidated,
  [UsersGetter.ProfileNotFilled]: ({ profile }) => !profile?.profile_filled,

  [UsersGetter.PaidPackages]: ({ paidPackages }) => paidPackages,
  [UsersGetter.PaidPackageDiscount]: ({ paidPackageDiscount }) => paidPackageDiscount,
  [UsersGetter.AvailablePaidPackages]: ({ paidPackages, profile }) => {
    if (!profile?.gender) return [];
    return paidPackages.filter((p) => p.gender === profile.gender);
  },

  [UsersGetter.ProfilePaidParams]: ({ profile }) => (profile && profile.paid_params) ?? [],
  [UsersGetter.ProfilePaidPackages]: ({ profile }) => (profile && profile.paid_packages) ?? [],
  [UsersGetter.AllRewardsReceived]: ({ profile }) => {
    if (!profile || !profile.tutorial_stages?.data) return null;

    return !Object.values(profile.tutorial_stages.data).find((i) => !i.reward_received);
  },
};
