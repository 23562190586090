import { ActionTree } from 'vuex';
import { IAppState } from 'store/app/interfaces/IAppState';
import { IStoreState } from 'store/interfaces/IStoreState';
import { AppAction } from 'store/app/enums/AppAction';
import { Api } from 'api/api';
import { AppMutation } from 'store/app/enums/AppMutation';
import { USER_STORE_KEY, UsersMutation } from 'store/users';
import { CHATS_STORE_KEY, ChatsMutation } from 'store/chats';
import { NOTIFICATIONS_STORE_KEY } from 'store/notifications/constants';
import { NotificationsMutation } from 'store/notifications';
import { ADMIN_STORE_KEY, AdminMutation } from 'store/admin';
import { STORIES_STORE_KEY, StoriesMutation } from 'store/stories';
import { SubscriptionMutation, SUBSCRIPTIONS_STORE_KEY } from 'store/subscriptions';
import { storageService } from 'storage';

export const AppActions: ActionTree<IAppState, IStoreState> = {
  [AppAction.FetchServiceSettings]: async ({ commit }) => {
    const { data } = await Api.adminService.fetchServiceSettings();

    commit(AppMutation.SetServiceSettings, data);
  },
  [AppAction.Logout]: async ({ commit }, withFetch = true) => {
    if (withFetch) await Api.userService.logout();
    storageService.clearStorage();
    commit(`${CHATS_STORE_KEY}/${ChatsMutation.ClearState}`, undefined, { root: true });
    commit(`${NOTIFICATIONS_STORE_KEY}/${NotificationsMutation.ClearState}`, undefined, { root: true });
    commit(`${ADMIN_STORE_KEY}/${AdminMutation.ClearState}`, undefined, { root: true });
    commit(`${STORIES_STORE_KEY}/${StoriesMutation.ClearState}`, undefined, { root: true });
    commit(`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionMutation.ClearState}`, undefined, { root: true });
    commit(`${USER_STORE_KEY}/${UsersMutation.ClearState}`, undefined, { root: true });
  },
};
