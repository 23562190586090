import { ref } from 'vue';
import { usePageLoader } from 'composables/pageLoader';

const { startLoading, endLoading } = usePageLoader();

export function useFetching() {
  const isFetching = ref(false);

  async function fetch<T extends any>(fetchFn: Promise<T>, pageLoader?: boolean): Promise<T>;
  async function fetch<T extends any>(fetchFn: Array<Promise<T>>, pageLoader?: boolean): Promise<Array<T>>;
  async function fetch<T extends any>(fetchFn: Array<Promise<T>> | Promise<T>, pageLoader = true): Promise<Array<T> | T> {
    isFetching.value = true;
    if (pageLoader) startLoading();
    try {
      if (Array.isArray(fetchFn)) {
        return await Promise.all(fetchFn);
      }
      return await fetchFn;
    } finally {
      isFetching.value = false;
      if (pageLoader) endLoading();
    }
  }

  function startFetching() {
    isFetching.value = true;
  }
  function endFetching() {
    isFetching.value = false;
  }

  return {
    isFetching,
    fetch,
    startFetching,
    endFetching,
  };
}
