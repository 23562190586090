<template>
  <teleport to="#modals">
    <div
      v-if="show"
      class="modal"
    >
      <modal-header
        :title="header.title"
        :dir="dir"
        :action-text="actionText"
        @close="$emit('close')"
        @action="onActionClick"
      />

      <section class="modal-body">
        <slot />
      </section>
    </div>
  </teleport>
</template>

<script lang="ts" src="./Modal.ts"></script>
<style lang="scss" src="./modal.scss"></style>
