import { Router } from 'vue-router';

let isPopState = false;
window.addEventListener('popstate', () => {
  isPopState = true;
});

export function usePopStateMiddleware(router: Router) {
  router.beforeEach((to) => {
    // eslint-disable-next-line no-param-reassign
    to.meta.isItABackButton = isPopState;
    isPopState = false;
  });
}
