import { defineComponent } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';

const ShortPopup = defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    overlayClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function close() {
      emit('update:modelValue', false);
    }

    return {
      close,

      IconName,
      IconType,
      Size,
    };
  },
});

export default ShortPopup;
