import { RouteLocationNormalized, RouteLocationNormalizedLoaded, useRoute } from 'vue-router';
import { computed } from 'vue';
import { ICity, Nullable } from 'core/types';
import { CITIES_STORE_KEY, CitiesGetter } from 'store/cities';
import { Store, useStore } from 'vuex';

export function useCity() {
  const route = useRoute();
  const store = useStore();
  const cities = computed<ICity[]>(() => store.getters[`${CITIES_STORE_KEY}/${CitiesGetter.Cities}`]);

  const currentCity = computed<Nullable<ICity>>(() => {
    const cityName: string = (route.params?.city === undefined ? '' : route.params.city) as string;
    const citySlug = cityName;
    if (!citySlug) return null;
    return cities.value.find((city) => city.slug === citySlug);
  });

  return {
    currentCity,
  };
}

export function useCitiesIdSlugMap() {
  const store = useStore();
  const cities = computed<ICity[]>(() => store.getters[`${CITIES_STORE_KEY}/${CitiesGetter.Cities}`]);

  const citiesIdSlugMap = computed<Record<number, string>>(() => {
    const linksById: Record<string, string> = {};
    cities.value.forEach(city => {
      linksById[city.id.toString()] = city.slug;
    });
    return linksById;
  });

  return citiesIdSlugMap;
}

export function getCity(_store: Store<any>|null = null, _route: RouteLocationNormalizedLoaded|RouteLocationNormalized|null = null) {
  const route = _route === null ? useRoute() : _route;
  const store = _store === null ? useStore() : _store;

  const cityName: string = (route.params?.city === undefined ? '' : route.params.city) as string;
  const currentCity = store.getters[`${CITIES_STORE_KEY}/${CitiesGetter.CityBySlug}`](cityName);

  return currentCity;
}
