import { ClaimType } from './enums/ClaimType';
import type { IClaim } from './interfaces/IClaim';

export class Claim implements IClaim {
  public static create(raw: IClaim): Claim {
    return new Claim(raw);
  }

  public id: number;
  public informerId: number;
  public userId: number;
  public type: ClaimType;

  public constructor(raw: IClaim) {
    this.id = raw.id;
    this.informerId = raw.informerId;
    this.userId = raw.userId;
    this.type = raw.type;
  }
}
