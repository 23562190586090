export enum PaidPackageParamsType {
  DurationMinutes = 'duration_minutes', // количество в минутах
  DurationDays = 'duration_days', // количество в днях'
  DurationMonths = 'duration_months', // количество в месяцах'
  DurationForever = 'duration_forever', // навсегда (количество не имеет значение)
  Count = 'count', // количество штук (например 1 раз выполнить подписку на типаж)
}

export enum PaidPackageDuration {
  DurationMinutes = 'minutes', // количество в минутах
  DurationDays = 'days', // количество в днях'
  DurationMonths = 'months', // количество в месяцах'
  DurationForever = 'forever', // навсегда (количество не имеет значение)
}

export enum PaidPackageKeys {
  ToTop = 'to_top', // Размещение в топ анкет
  ToCatalogSuccessMan = 'to_catalog_success_man', // 'Каталог успешных мужчин',
  ToCatalogBeauty = 'to_catalog_beauty', // 'Каталог красоток',
  OutlineFramePhotoInStyle = 'outline_frame_photo_in_style', // 'Выделенная обводка рамки в фирменном стиле фото пользователя',
  OutlineFramePhotoGold = 'outline_frame_photo_gold', // 'Выделенная обводка рамки в золотом цвете.',
  OutlineFramePhotoBrilliant = 'outline_frame_photo_brilliant', // 'Изумрудная или бриллиантовая обводка',
  OutlineFramePhotoDon = 'outline_frame_photo_don', // 'Изумрудная или бриллиантовая обводка',
  AllowToSubscribeUsers = 'allow_to_subscribe_users', // 'Бесплатных подписок на типаж',
  DeadlineAllowFreePublishStories = 'deadline_allow_free_publish_stories', // 'Публикация сториз без ограничений',
  DeadlineAllowFreeSubscribeUsers = 'deadline_allow_free_subscribe_users', // 'Подписка на типаж без ограничений',
  DeadlineAllowFreeDeleteChats = 'deadline_allow_free_delete_chats', // 'Удаление чатов без ограничений',
  ToTopSpecialMan = 'to_top_special_man', // 'Пользователь всегда показывается первым в каталоге, в топе анкет',
  DeadlineAllowToSeeWomenContacts = 'deadline_allow_to_see_women_contacts', // Все контакты открыты.
  IconDazle = 'icon_dazle', // Значок "Дензл"
  IconHearthBreaker = 'icon_heartbreaker', // Значок "Сердцеедка"'
  IconDon = 'icon_don', // Значок "Дон"'
}

export type IconType = PaidPackageKeys.IconDazle | PaidPackageKeys.IconHearthBreaker | PaidPackageKeys.IconDon;
