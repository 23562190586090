import { computed, ref } from 'vue';

const LOADER_MAX_PROGRESS = 98;
const LOADER_INCREMENT_TIME = 500;
const LOADER_FADE_TIME = 750;
const LOADER_START_PROGRESS = 10;

let currentLoadings = 0;

const progress = ref(LOADER_START_PROGRESS);
const isLoaderShow = ref(true);

function getRandomProgress() {
  return Math.floor(Math.random() * 4) + 1;
}

function increment(to?: number) {
  if (to) {
    progress.value = Math.max(0, Math.min(to, LOADER_MAX_PROGRESS));
  }
  const inc = getRandomProgress();
  if (progress.value + inc < LOADER_MAX_PROGRESS) {
    progress.value += inc;
  } else {
    progress.value = LOADER_MAX_PROGRESS;
  }
}

function newLoading() {
  if (progress.value > 50) progress.value -= 5 * getRandomProgress();
}

let interval: number;

function endLoading() {
  if (interval) clearInterval(interval);
  progress.value = 100;
  setTimeout(() => {
    currentLoadings--;
    if (currentLoadings <= 0) {
      isLoaderShow.value = false;
      progress.value = LOADER_START_PROGRESS;
      currentLoadings = 0;
    }
  }, LOADER_FADE_TIME);
}

function startLoading(autoIncrement: boolean) {
  if (interval) clearInterval(interval);
  if (autoIncrement) interval = setInterval(increment, LOADER_INCREMENT_TIME);
  isLoaderShow.value = true;
  if (!currentLoadings) {
    progress.value = LOADER_START_PROGRESS;
  } else {
    newLoading();
  }
  currentLoadings++;
}

export function usePageLoader(autoIncrement = true) {
  return {
    progress: computed(() => progress.value),
    isLoaderShow: computed(() => isLoaderShow.value),
    startLoading: () => startLoading(autoIncrement),
    endLoading,
    increment,
  };
}
