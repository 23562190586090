import { createI18n } from 'vue-i18n';
import { ru } from './locales/ru';

const numberFormats = {
  ru: {
    currency: {
      style: 'currency',
      currency: 'RUB',
      notation: 'standard',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
};

const datetimeFormats = {
  ru: {
    short: {
      year: 'numeric' as const,
      month: 'numeric' as const,
      day: 'numeric' as const,
    },
    long: {
      year: 'numeric' as const,
      month: 'numeric' as const,
      day: 'numeric' as const,
      hour: 'numeric' as const,
      minute: 'numeric' as const,
    },
  },
};

export const i18n = createI18n({
  locale: navigator.language.substr(0, 2),
  fallbackLocale: 'ru',
  numberFormats,
  datetimeFormats,
  pluralizationRules: {
    ru: (choice, choicesLength) => {
      if (choice === 0) return 0;

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (!teen && endsWithOne) return 1;
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) return 2;

      return choicesLength < 4 ? 2 : 3;
    },
  },
  messages: {
    ru,
  },
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});
