const IS_METRICS_ENABLED = process.env.VUE_APP_IS_METRICS_ENABLED === 'true';
const IDENTIFIER = process.env.VUE_APP_YM_IDENTIFIER;
const TARGET_TELEGRAM_LINKED = process.env.VUE_APP_YM_TARGET_TELEGRAM_LINKED;
const TARGET_REGISTRATION = process.env.VUE_APP_YM_TARGET_REGISTRATION;
const TARGET_SUCCESSFUL_PAYMENT = process.env.VUE_APP_YM_TARGET_SUCCESSFUL_PAYMENT;

let queue: (() => void)[] | undefined = [];
let interval: number | undefined;

function startInterval() {
  // @ts-ignore
  interval = setInterval(() => {
    // @ts-ignore
    if (window.ym) {
      clearInterval(interval);
      queue?.forEach((fn) => fn());
      queue = undefined;
      // @ts-ignore
    }
  }, 50);
}

const callYmReachGoal = (identifier: string | undefined, target: string | undefined) => {
  if (!IS_METRICS_ENABLED || !identifier || !target) return;

  // @ts-ignore
  if (!window.ym) {
    // @ts-ignore
    queue.push(() => window.ym(identifier, 'reachGoal', target));
    if (!interval) startInterval();
  } else {
    // @ts-ignore
    window.ym(identifier, 'reachGoal', target);
  }
};

export const ymSuccessfulRegister = () => callYmReachGoal(IDENTIFIER, TARGET_REGISTRATION);

export const ymSuccessfulTelegramLinked = () => callYmReachGoal(IDENTIFIER, TARGET_TELEGRAM_LINKED);

export const ymSuccessfulPayment = () => callYmReachGoal(IDENTIFIER, TARGET_SUCCESSFUL_PAYMENT);
