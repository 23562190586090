import { useApiErrors } from 'composables/apiErrors';
import { RouteLocationNormalized } from 'vue-router';

const { showError } = useApiErrors();

export async function routerFetch(to: RouteLocationNormalized, fetchIf: () => boolean, fetchFn: () => Promise<any>): Promise<boolean | string> {
  if (to.meta.isItABackButton && !fetchIf()) return true;
  try {
    await fetchFn();
    return true;
  } catch (e) {
    showError(e);
    return '/';
  }
}
