import { INotification, NotificationColor, NotificationType } from 'api/notifications/models';
import { IconName, IconType } from 'components/Icon';
import { MainColor } from 'core/styles';

export function getBadgeIconByType(type: any) {
  switch (type) {
    case NotificationType.NewVisitor:
      return {
        type: IconType.Fill,
        name: IconName.Eye,
        color: MainColor.Violet,
      };
    case NotificationType.NewLike:
      return {
        type: IconType.Fill,
        name: IconName.Love,
        color: MainColor.Red,
      };
    case NotificationType.NewSubscriptionUser:
      return {
        type: IconType.Fill,
        name: IconName.User,
        color: MainColor.Violet,
      };
    default: return undefined;
  }
}

export function getIconByType(type: any) {
  switch (type) {
    case NotificationType.SuccessPayment:
      return {
        type: IconType.Fill,
        name: IconName.CreditCard,
      };
    case NotificationType.PaidService:
      return {
        type: IconType.Fill,
        name: IconName.CreditCard2,
      };
    default: return {
      type: IconType.Fill,
      name: IconName.BellAlt,
    };
  }
}

export function getColor(type: any) {
  switch (type) {
    case NotificationType.SuccessPayment:
      return NotificationColor.Green;
    default: return NotificationColor.Yellow;
  }
}

export function hasAvatar(notification: INotification) {
  if (!notification) return false;
  return [
    NotificationType.NewVisitor,
    NotificationType.NewLike,
    NotificationType.NewSubscriptionUser,
  ].includes(notification.type as NotificationType) || notification.data?.object?.photos?.length > 0;
}
