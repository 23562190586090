<template>
  <div class="crop-image">
    <img
      ref="imageRef"
      :src="imageSrc"
      class="crop-image__image"
      @ready="handleReady"
      @load="handleLoad"
      @cropstart="handleCropStart"
      @error="handleImageError"
    >
  </div>
</template>

<script lang="ts" src="./CropImage.ts" />
<style lang="scss" src="./cropImage.scss" />
