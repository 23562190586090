export enum NotificationColor {
  Green = 'green',
  Yellow = 'yellow',
}

export enum NotificationType {
  NewSubscriptionUser = 'App\\Notifications\\NewSubscriptionUser',
  NewLike = 'App\\Notifications\\NewLike',
  NewVisitor = 'App\\Notifications\\NewVisitor',
  PaidService = 'App\\Notifications\\PaidService',
  SuccessPayment = 'App\\Notifications\\SuccessPayment',
  Welcome = 'App\\Notifications\\Welcome',

  EmailNotValidated = 'email-not-validated',
}

export interface INotification {
  id: number
  type: string
  notifiable_type?: string
  notifiable_id?: number
  data: any
  read_at: string
  created_at: string
  updated_at: string
  dateFormat?: string
  // computed fields
  color?: NotificationColor
  message?: string
}

export interface INotificationSettings {
  unread_messages: boolean
  users_actions: boolean
  payments: boolean
}
