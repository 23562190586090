import { computed, defineComponent, reactive, ref, watch } from 'vue';
import Splash from 'components/Splash';
import { useRoute } from 'vue-router';

const KeepAliveRouter = defineComponent({
  components: {
    Splash,
  },
  setup() {
    const route = useRoute();

    const excludeName = ref('');
    const includesSet = reactive(new Set());

    watch(() => route.name, (name) => {
      if (route.meta.keepAlive) includesSet.add(name);
      if (!route.meta.isItABackButton) {
        excludeName.value = name as string;
      }
    }, { flush: 'pre' });
    watch(() => route.name, () => {
      excludeName.value = '';
    }, { flush: 'post' });

    return {
      excludeName,
      includes: computed(() => Array.from(includesSet)),
    };
  },
});

export default KeepAliveRouter;
