import { onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { StoreInjectionKey } from '../../constants';
import { USER_STORE_KEY } from '../constants';
import { UsersGetter } from '../enums/UsersGetter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useUsersWatch = (key: UsersGetter, callback: (value: any, oldValue: any) => void): void => {
  const store = useStore<typeof StoreInjectionKey>();

  const unwatch = store.watch(() => store.getters[`${USER_STORE_KEY}/${key}`], callback);

  onUnmounted(unwatch);
};
