import { AxiosInstance } from 'axios';
import { IPagesResponse } from 'core/types';

export class PagesService {
  static init(restService: AxiosInstance): PagesService {
    return new PagesService(restService);
  }

  constructor(private restService: AxiosInstance) {
  }

  getPage(page: string) {
    return this.restService.get<IPagesResponse>(`/public/pages/${page}`);
  }
}
