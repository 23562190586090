import axios, { AxiosResponse } from 'axios';
import { StorageKeys, storageService } from 'storage';

export const restService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

// Add a request interceptor
restService.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = storageService.getItem(StorageKeys.AuthToken);
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) =>
    // Do something with request error
    // eslint-disable-next-line implicit-arrow-linebreak
    Promise.reject(error),

);

// Add a response interceptor
restService.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    if (response?.data?.result === false) return Promise.reject(response.data);
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range
    // of 2xx cause this function to trigger
    // Do something with response error
    // eslint-disable-next-line implicit-arrow-linebreak

    // Unauthorize
    if (error?.response?.status === 401 && error?.response?.data?.message === 'Unauthenticated.') {
      storageService.removeItem(StorageKeys.AuthToken);
      if (window.location.pathname !== '/') window.location.href = '/';
    }

    return Promise.reject(error);
  },
);
