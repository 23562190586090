<template>
  <page-loader />
  <push-notifications />
  <keep-alive-router />
  <toasts />
  <app-cropper />
  <not-moderated-popup />
  <tutorial-tip />

  <no-prerender>
    <majority-popup />
    <errors-popup />
  </no-prerender>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, watch } from 'vue';
import { ChatsAction, ChatsMutation } from 'store/chats/enums';
import { CHATS_STORE_KEY } from 'store/chats/constants';
import { NotificationsAction, useNotificationsAction } from 'store/notifications';
import Toasts from 'components/Toasts';
import { IProfile, Sex, ThemeColor } from 'api/users/models/user';
import { USER_STORE_KEY, UsersAction, UsersGetter, UsersMutation } from 'store/users';
import { APP_STORE_KEY, AppAction } from 'store/app';
import { useStore } from 'vuex';
import { setThemeColors } from 'core/helpers';
import PageLoader from 'components/PageLoader';
import { SubscriptionAction, SUBSCRIPTIONS_STORE_KEY } from 'store/subscriptions';
import { NOTIFICATIONS_STORE_KEY } from 'store/notifications/constants';
import { useApiErrors } from 'composables/apiErrors';
import KeepAliveRouter from 'components/KeepAliveRouter';
import PushNotifications from 'components/PushNotifications';
import { usePushNotifications } from 'composables/pushNotifications';
import { StorageKeys, storageService } from 'storage';
import AppCropper from 'modules/cropper';
import MajorityPopup from 'components/MajorityPopup';
import NotModeratedPopup from 'modules/notModerated';
import ErrorsPopup from 'modules/errorsPopup';
import { useTgSubscribeWatcher } from 'composables/tgSubscribeWatcher';
import TutorialTip from 'components/TutorialTip';
import { useRoute } from 'vue-router';
import { RouteNames } from 'router/names';
import pusher, { IConversationDropped } from './pusher';

const FETCH_MESSAGES_INTERVAL = parseInt(process.env.VUE_APP_FETCH_MESSAGES_INTERVAL || '0', 10) * 1000;
const FETCH_PROFILE_INTERVAL = parseInt(process.env.VUE_APP_FETCH_PROFILE_INTERVAL || '0', 10) * 1000;

export default defineComponent({
  name: 'App',
  components: {
    Toasts,
    AppCropper,
    ErrorsPopup,
    NotModeratedPopup,
    PageLoader,
    KeepAliveRouter,
    MajorityPopup,
    PushNotifications,
    TutorialTip,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    let watchPositionId = 0;
    const { showError } = useApiErrors();
    const { showPushNotification } = usePushNotifications();
    useTgSubscribeWatcher();

    onMounted(() => {
      const app = document.querySelector('#app') as HTMLDivElement;
      app.style.removeProperty('opacity');
    });

    function onResize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    onMounted(() => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      window.addEventListener('resize', onResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize);
    });

    const localAuthToken = storageService.getItem(StorageKeys.AuthToken);
    if (localAuthToken) store.commit(`${USER_STORE_KEY}/${UsersMutation.SetAuthToken}`, localAuthToken);

    const isLoggedIn = computed(() => !!(store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]));
    const profile = computed<IProfile>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);
    const locationRequired = computed({
      get: () => store.getters[`${USER_STORE_KEY}/${UsersGetter.GeolocationRequired}`],
      set: (val: boolean) => {
        store.commit(`${USER_STORE_KEY}/${UsersMutation.SetGeolocationRequired}`, val);
      },
    });
    const authToken = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.AuthToken}`]);
    const { dispatch: listNotifications } = useNotificationsAction(NotificationsAction.ListNotifications);

    if (FETCH_PROFILE_INTERVAL) {
 setInterval(() => {
      if (isLoggedIn.value) store.dispatch(`${USER_STORE_KEY}/${UsersAction.GetProfile}`);
    }, FETCH_PROFILE_INTERVAL);
}

    let fetchMessagesInterval: any;
    pusher.onDisconnected(() => {
      if (fetchMessagesInterval || !isLoggedIn.value) return;
      fetchMessagesInterval = setInterval(async () => {
        try {
          await store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadUnread}`);
          await store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadChats}`);
        } catch (e) {
          showError(e);
        }
      }, FETCH_MESSAGES_INTERVAL);
    });
    pusher.onConnected(() => {
      if (fetchMessagesInterval) {
        clearInterval(fetchMessagesInterval);
        fetchMessagesInterval = undefined;
      }
    });

    watch(isLoggedIn, (val, oldVal) => {
      if (val) {
        if (!authToken.value) throw new Error('No auth token');
        if (!pusher.isConnected()) pusher.connect(authToken.value);
        // @todo не надо каждый по отдельности init (инициализировать), это должно происходить автоматически в цикле
        // в который если мы добавили новый эвент (класс) который надо слушать (подписаться, бинд, анбинд) - то мы не думаем о том где оно там будет инициилизироваться, биндится и анбиндится
        // потратил кучу времени пока нашел этот момент, почему у меня не работал пушер эвент
        pusher.notificationPusher.init(profile.value.id);
        pusher.roomPusher.init(profile.value.id);
        pusher.moderatedPusher.init(profile.value.id);
        pusher.roomPusher.subscribeToMessageCreated(async (data) => {
          store.commit(`${CHATS_STORE_KEY}/${ChatsMutation.AddNewMessage}`, data);
          await store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadUnread}`);
          await store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadChats}`);
        });
        pusher.roomPusher.subscribeToMessageReaded(() => {
          store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadChats}`);
        });
        pusher.roomPusher.subscribeToMessageDeleted((data) => {
          store.commit(`${CHATS_STORE_KEY}/${ChatsMutation.DeleteMessagesById}`, {
            userId: data.senderId,
            messageId: data.messageId,
          });
        });
        pusher.notificationPusher.subscribeToNotificationCreated(async () => {
          try {
            await store.dispatch(`${NOTIFICATIONS_STORE_KEY}/${NotificationsAction.ListNotifications}`);
            showPushNotification();
          } catch (e) {
            showError(e);
          }
        });
        pusher.roomPusher.subscribeToConversationDropped(async (data: IConversationDropped) => {
          store.commit(`${CHATS_STORE_KEY}/${ChatsMutation.deleteMessagesByUserId}`, data.senderId);
        });
        pusher.moderatedPusher.subscribeToModeratedChange(() => {
          store.dispatch(`${USER_STORE_KEY}/${UsersAction.GetProfile}`).catch(e => showError(e));
        });

        listNotifications();
        store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadUnread}`).catch((e) => showError(e));
        store.dispatch(`${APP_STORE_KEY}/${AppAction.FetchServiceSettings}`).catch((e) => showError(e));
        store.dispatch(`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionAction.FetchMessageSubscription}`).catch((e) => showError(e));
      } else if (typeof oldVal !== 'undefined') pusher.disconnect();
    }, { immediate: true });

    const profileCityId = computed(() => profile.value?.city_id || '');

    watch(profileCityId, () => {
      store.commit(`${USER_STORE_KEY}/${UsersMutation.ClearMainPagePhotos}`);
    });

    watch(profile, (val, oldVal) => {
      if (val) {
        if (!oldVal) {
          store.commit(`${USER_STORE_KEY}/${UsersMutation.InitGeolocationRequired}`);
          checkLocationPermission();
        }
      } else if (oldVal) {
        clearWatchGeolocation();
      }
    }, { deep: true });

    const getThemeColor = (val: string) => {
      let color = val;
      if (!color) {
        if (profile.value?.appearance?.color) {
          color = profile.value.appearance.color;
        } else {
          switch (profile.value?.gender) {
            case Sex.Male: {
              color = ThemeColor.Blue;
              break;
            }
            case Sex.Female: {
              color = ThemeColor.Red;
              break;
            }
            default: {
              color = ThemeColor.Lavender;
            }
          }
        }
      }
      return color;
    };

    watch(
      () => profile.value?.appearance?.color,
      (val: string) => {
        const color = getThemeColor(val);
        setThemeColors(color);
        storageService.setItem(StorageKeys.ThemeColor, color);
      },
    );

    onBeforeMount(() => {
      const color = storageService.getItem(StorageKeys.ThemeColor);
      setThemeColors(getThemeColor(color || ''));
    });

    watch(locationRequired, (val) => {
      if (val) {
        watchGeolocation();
      }
    });

    const profileUnwatch = watch(profile, (newProfile) => {
      if (!newProfile || !isLoggedIn.value) return;
      profileUnwatch();

      if (newProfile.available_see_contacts) return;

      const unwatch = watch(profile, () => {
        if (profile.value && profile.value.available_see_contacts) {
          if (route.name !== RouteNames.Room) store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.ResetChat}`);
          unwatch();
        }
      });
    }, { immediate: true });

    function checkLocationPermission() {
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          if (result.state === 'granted') {
            locationRequired.value = true;
          } else {
            locationRequired.value = false;
          }
        });
      }
    }

    function watchGeolocation() {
      if (watchPositionId) return;

      if (navigator.geolocation) {
        watchPositionId = navigator.geolocation.watchPosition(locationSucces, locationError);
      } else {
        // eslint-disable-next-line
        console.log('Geolocation is not supported');
      }
    }

    function clearWatchGeolocation() {
      if (watchPositionId) return;

      if (navigator.geolocation) {
        navigator.geolocation.clearWatch(watchPositionId);
        watchPositionId = 0;
      } else {
        // eslint-disable-next-line
        console.log('Geolocation is not supported');
      }
    }

    // eslint-disable-next-line no-undef
    function locationSucces(position: GeolocationPosition) {
      store.dispatch(`${USER_STORE_KEY}/${UsersAction.PutGeolocationCoords}`, position.coords);
    }

    // eslint-disable-next-line no-undef
    function locationError(error: GeolocationPositionError) {
      // eslint-disable-next-line
      console.log('Geolocation error:', error.message);
      clearWatchGeolocation();
    }
  },
});
</script>
