export enum IconName {
  Favorites = 'Favorites',
  Female = 'Female',
  Lock = 'Lock',
  Male = 'Male',
  Research = 'Research',
  ArrowBack = 'ArrowBack',
  Ban = 'Ban',
  Block = 'Block',
  Blur = 'Blur',
  Brightness = 'Brightness',
  Camera = 'Camera',
  Crop = 'Crop',
  Crosses = 'Crosses',
  Dot = 'Dot',
  EyeClosed = 'EyeClosed',
  Close = 'Close',
  Eye = 'Eye',
  Flag = 'Flag',
  Heart = 'Heart',
  MagnifyingGlass = 'MagnifyingGlass',
  Painting = 'Painting',
  Phone = 'Phone',
  Play = 'Play',
  Plus = 'Plus',
  Settings = 'Settings',
  Setting = 'Setting',
  Telegram = 'Telegram',
  TextCenter = 'TextCenter',
  TextLeft = 'TextLeft',
  TextRight = 'TextRight',
  Type = 'Type',
  Whatsapp = 'Whatsapp',
  Wheel = 'Wheel',
  Apps = 'Apps',
  ArrowRight = 'ArrowRight',
  ArrowUncover = 'ArrowUncover',
  Bell = 'Bell',
  Dots = 'Dots',
  HipMiddle = 'HipMiddle',
  HipRound = 'HipRound',
  HipSmall = 'HipSmall',
  Message = 'Message',
  Pen = 'Pen',
  Pencil = 'Pencil',
  Placemark = 'Placemark',
  Profile = 'Profile',
  Rotation = 'Rotation',
  Trash = 'Trash',
  Premium = 'Premium',
  Checked = 'Checked',
  BellAlt = 'BellAlt',
  Home = 'Home',
  Gallery = 'Gallery',
  Rectangle = 'Rectangle',
  Pin = 'Pin',
  Email = 'Email',
  Delete = 'Delete',
  Logout = 'Logout',
  Chest1 = 'Chest1',
  Chest2 = 'Chest2',
  Chest3 = 'Chest3',
  Wallet = 'Wallet',
  DeleteAlt = 'DeleteAlt',
  Copy = 'Copy',
  Copy2 = 'Copy2',
  Attachment = 'Attachment',
  Ghost = 'Ghost',
  CreditCard = 'CreditCard',
  CreditCard2 = 'CreditCard2',
  Love = 'Love',
  User = 'User',
  ChevronDown = 'ChevronDown',
  Verified = 'Verified',
  Minus = 'Minus',
  Mark = 'Mark',
  DoubleMark = 'DoubleMark',
  Image = 'Image',
  Question = 'Question',
  Tick = 'Tick',
  Cancel = 'Cancel',
  Clock = 'Clock',
  BackArrow = 'BackArrow',
  AddUser = 'AddUser',
  StoryArrowRight = 'StoryArrowRight',
  StoryArrowLeft = 'StoryArrowLeft',
  CountdownTimer = 'CountdownTimer',
  History = 'History',
  LightBulb = 'LightBulb',
  Expand = 'Expand',
  PhonePerson = 'PhonePerson',
}
