import axios, { AxiosInstance } from 'axios';
import { ChatsService } from 'api/chatsService';
import { AdminService } from 'api/adminService';
import { SubscriptionsService } from 'api/subscriptionsService';
import { CaptchaService } from 'api/captchaService';
import { restService } from './restService';
import { DadataService } from './dadataService';
import { UserService } from './userService';
import { CitiesService } from './citiesService';
import { PagesService } from './pagesService';
import { NotificationService } from './notificationService';
import { StoriesService } from './storiesService';
import { MetaPagesService } from './metaPagesService';
import { BlogsService } from './blogsService';

export class ApiService {
  constructor(private rest: AxiosInstance) {
  }

  dadataService = DadataService.init(axios);

  userService = UserService.init(this.rest);

  adminService = AdminService.init(this.rest);

  citiesService = CitiesService.init(this.rest);

  pagesService = PagesService.init(this.rest);

  metaPagesService = MetaPagesService.init(this.rest);

  notificationService = NotificationService.init(this.rest);

  storiesService = StoriesService.init(this.rest);

  chatsService = ChatsService.init(this.rest);

  subscriptionsService = SubscriptionsService.init(this.rest);

  captchaService = CaptchaService.init(this.rest);

  blogsService = BlogsService.init(this.rest)
}

export const Api = new ApiService(restService);
