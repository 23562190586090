export const setThemeColors = (color: string) => {
  document.documentElement.style.setProperty(
    '--theme-color-name',
    color,
  );
  document.documentElement.style.setProperty(
    '--theme-color',
    `var(--${color})`,
  );
  document.documentElement.style.setProperty(
    '--theme-color-lighten-xs',
    `var(--${color}-lighten-xs)`,
  );
  document.documentElement.style.setProperty(
    '--theme-color-darken-xs',
    `var(--${color}-darken-xs)`,
  );
  document.documentElement.style.setProperty(
    '--theme-color-background',
    `var(--${color}-background)`,
  );
  document.documentElement.style.setProperty(
    '--theme-color-gradient',
    `var(--${color}-gradient)`,
  );
  document.documentElement.style.setProperty(
    '--theme-color-disabled',
    `var(--${color}-disabled)`,
  );
};
