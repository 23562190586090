import { usersFilterValueDefaults } from 'core/values';

export function formatUserFilter(userFilter: Record<string, any>) {
  if (typeof userFilter !== 'object') return {};
  const newUserFilters = {};
  Object.keys(userFilter).forEach(k => {
    const val = userFilter[k];
    if (['online'].includes(k)) {
      newUserFilters[k] = val ? 1 : undefined;
      return;
    }

    if (['age', 'height'].includes(k)) {
      const bounds = usersFilterValueDefaults[k];
      if (bounds && bounds.from === val.from && bounds.to === val.to) return;

      newUserFilters[`${k}[from]`] = val.from;
      newUserFilters[`${k}[to]`] = val.to;
      return;
    }
    newUserFilters[k] = val;
  });
  return newUserFilters;
}
