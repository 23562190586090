export enum ProfileColor {
  Japonica = 'japonica',
  RoseBud = 'rose-bud',
  Celery = 'celery',
  Eden = 'eden',
  FunGreen = 'fun-green',
  Fern = 'fern',
  SnowyMint = 'snowy-mint',
  Viking = 'viking',
  PictonBlue = 'picton-blue',
  Lochmara = 'lochmara',
  Endeavour = 'endeavour',
  MidnightBlue = 'midnight-blue',
  HotPink = 'hot-pink',
  Kobi = 'kobi',
  DeepVlush = 'deep-blush',
  Shiraz = 'shiraz',
  Cardinal = 'cardinal',
  Cinnabar = 'cinnabar',
  California = 'california',
  Supernova = 'supernova',
  Starship = 'starship',
  ShuttleGray = 'shuttle-gray',
}
