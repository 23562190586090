import { AxiosInstance, AxiosPromise, AxiosResponse } from 'axios';
import { Nullable } from 'core/types';
import { Profile } from 'api/users/models/user/Profile';
import { IUserFilter } from 'api/users/models/user/interfaces/IUserFilter';
import { IApiPaginateResponse } from 'api/api-request';
import { IUserOnMainPage } from 'api/users/models/user/interfaces/IUserOnMainPage';
import { PaidPackageResponse } from 'api/users/models/paidPackages/interfaces';
import { SuccessCatalogType } from 'api/users/models/successCatalog';
import { AuthLog } from 'api/users/models/authLog/interfaces';
import { BonusState } from 'api/users/models/bonusState/interfaces';
import { IProfile, IUser, Sex, ThemeColor, User } from './users/models/user';

export enum BuyContactsType {
  Contacts1 = 'contacts.1',
  Contacts5 = 'contacts.5',
  Contacts10 = 'contacts.10',
  ContactsUnlim1 = 'contacts.unlim.1.month',
}

export class UserService {
  static init(restService: AxiosInstance) {
    return new UserService(restService);
  }

  constructor(private restService: AxiosInstance) {
  }

  register(data: {
    name: string;
    birth: string;
    city_id: number;
    gender: string;
  }): Promise<AxiosResponse<{ token?: string }>> {
    return this.restService.post('/public/register', data);
  }

  login(data: { login: string; password: string }): Promise<AxiosResponse<{ token?: string; message?: string }>> {
    return this.restService.post('/public/login', data);
  }

  logout() {
    return this.restService.post('public/logout');
  }

  async getProfile() {
    const { data } = await this.restService('/public/profile');

    return new Profile(data);
  }

  updateProfile(data: Partial<IUser>): Promise<AxiosResponse<IProfile>> {
    return this.restService.post('/public/profile', data);
  }

  changeEmail(data: { email: string, password: string }): Promise<AxiosResponse<{ result: boolean, profile: IProfile }>> {
    return this.restService.post('/public/profile/email', data);
  }

  resendEmailConfirmation() {
    return this.restService.post('/public/email/verification-notification');
  }

  changePhone(phone: string, password: string): Promise<AxiosResponse<{ result: boolean }>> {
    return this.restService.post('/public/profile/phone', { phone, password });
  }

  changePassword(password: string, password_confirmation: string, old_password: string): Promise<AxiosResponse<{ result: boolean }>> {
    return this.restService.post('/public/profile/password', { password, password_confirmation, old_password });
  }

  requestPassword(data: { login: string }): Promise<AxiosResponse<{ result: boolean }>> {
    return this.restService.post('/public/password/request', data);
  }

  passwordReset(
    login: string,
    password: string,
    password_confirmation: string,
    token: string,
  ): Promise<AxiosResponse<{ token?: string; message?: string }>> {
    return this.restService.post('/public/password/reset', { login, password, password_confirmation, token });
  }

  deleteProfile(reason: string): Promise<AxiosResponse<{ result: boolean }>> {
    return this.restService.delete('/public/profile/delete', {
      data: {
        deleted_reason: reason,
      },
    });
  }

  blockUser(userId: number) {
    return this.restService.post<{ result: boolean }>(`public/users/${userId}/block`);
  }

  unblockUser(userId: number) {
    return this.restService.post<{ result: boolean }>(`public/users/${userId}/unblock`);
  }

  async getUsers(params: IUserFilter & { page?: number }): Promise<IApiPaginateResponse<IUser[]>> {
    const { data } = await this.restService.get<IApiPaginateResponse<IUser[]>>('/public/users', { params });

    return {
      ...data,
      data: data.data?.map((raw) => new User(raw)),
    };
  }

  fetchTopUsers(city_id: number, gender: Sex, page = 1) {
    return this.restService.get<IApiPaginateResponse<IUserOnMainPage>>('/public/homepage-users', {
      params: {
        city_id,
        gender,
        page,
        sort: 'top',
      },
    });
  }

  fetchNewUsers(city_id: number, gender: Sex, page = 1) {
    return this.restService.get<IApiPaginateResponse<IUserOnMainPage>>('/public/homepage-users', {
      params: {
        city_id,
        gender,
        page,
        sort: 'new',
      },
    });
  }

  fetchFemaleUsers(page = 1) {
    return this.restService.get<IApiPaginateResponse<IUserOnMainPage>>('/public/homepage-users', {
      params: {
        gender: Sex.Female,
        sort: 'new',
        page,
      },
    });
  }

  fetchMaleUsers(page = 1) {
    return this.restService.get<IApiPaginateResponse<IUserOnMainPage>>('/public/homepage-users', {
      params: {
        gender: Sex.Male,
        sort: 'new',
        page,
      },
    });
  }

  addPhoto(formData: FormData, onUploadProgressCb?: (progress: number) => void): AxiosPromise<{ profile: IProfile }> {
    return this.restService.post('public/photo', formData, {
      onUploadProgress(progressEvent) {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null && onUploadProgressCb) {
            onUploadProgressCb(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        },
    });
  }

  deletePhoto(id: number): Promise<AxiosResponse<{ result: boolean }>> {
    return this.restService.delete(`/public/photo/${id}`);
  }

  getUserById(id: number, withoutNotice = false): AxiosPromise<Nullable<User>> {
    return this.restService.get(`public/users/${id}${withoutNotice ? '?without-notice' : ''}`).then((resp) => ({
      ...resp,
      data: new User(resp.data),
    }
    ));
  }

  addUserToFavorites(id: number) {
    return this.restService.post(`public/users/${id}/like`);
  }

  removeUserFromFavorites(id: number) {
    return this.restService.post(`public/users/${id}/unlike`);
  }

  sendClaim(id: number, type: string) {
    return this.restService.post(`public/users/${id}/claim`, { type });
  }

  geolocationCoords(coords: GeolocationCoordinates) {
    const data = {
      lat: coords.latitude,
      lng: coords.longitude,
    };
    return this.restService.post('/public/geolocation', data);
  }

  sendIssue(data: { name: string, subject: string, content: string, email?: string, attachment?: File | null, captcha?: string, key?: string }): Promise<AxiosResponse<{ result: boolean }>> {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('subject', data.subject);
    formData.append('content', data.content);
    if (data.email) {
      formData.append('email', data.email);
    }
    if (data.attachment) {
      formData.append('attachment', data.attachment);
    }
    if (data.captcha) {
      formData.append('captcha', data.captcha);
    }
    if (data.key) {
      formData.append('key', data.key);
    }
    return this.restService.post(
      '/public/issue',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  }

  walletEnroll(amount: number): Promise<AxiosResponse<{ result: boolean }>> {
    return this.restService.post('/public/payment/lovista/request', { amount });
  }

  paymentTinkoffRequest(amount: number): Promise<AxiosResponse<{ result: boolean, redirect?: string; message?: string }>> {
    return this.restService.post('/public/payment/tinkoff/request', { amount });
  }

  paymentYoupayRequest(amount: number): Promise<AxiosResponse<{ result: boolean, redirect?: string; message?: string }>> {
    return this.restService.post('/public/payment/youpay/request', { amount });
  }

  changeThemeColor(color: ThemeColor): Promise<AxiosResponse<{ result: boolean }>> {
    return this.restService.post('/public/profile/theme/color', { color });
  }

  raiseProfile() {
    return this.restService.post('/public/take-me-to-the-top');
  }

  fetchFavorites(page: number): Promise<AxiosResponse<IApiPaginateResponse<IUser[]>>> {
    return this.restService.get('/public/favorites', {
      params: {
        page,
      },
    });
  }

  selectAsMain(photoId: number) {
    return this.restService.post(`public/photos/${photoId}/set-main`);
  }

  patchProfile(profile: Partial<IProfile>): Promise<AxiosResponse<IProfile>> {
    return this.restService.patch('/public/profile', profile);
  }

  fetchPaidPackages(): Promise<AxiosResponse<PaidPackageResponse>> {
    return this.restService.get('/public/paid-package/list');
  }

  buyPaidPackage(id: number): Promise<AxiosResponse<IProfile>> {
    return this.restService.post('/public/paid-package/buy', { id });
  }

  fetchSuccessCatalog(page: number, type: SuccessCatalogType, city_id: number):
    Promise<AxiosResponse<IApiPaginateResponse<IUser[]>>> {
    return this.restService.get('/public/catalog', {
      params: {
        page,
        type,
        city_id,
      },
    });
  }

  fetchAuthLog(page: number):
    Promise<AxiosResponse<IApiPaginateResponse<AuthLog[]>>> {
    return this.restService.post('/public/profile/auth-log', null, { params: { page } });
  }

  fetchBonusState(): Promise<AxiosResponse<BonusState>> {
    return this.restService.post('/public/profile/check-bonus-state');
  }

  getReward(stage: string) {
    return this.restService.post('/public/activate-tutorial-reward', {
      stage,
    });
  }

  buyIncognito(period_months: number) {
    return this.restService.post('/public/paid-service/buy/incognito', {
      period_months,
    });
  }

  openContact(target_user_id: number): Promise<AxiosResponse<{ target_user?: IUser }>> {
    return this.restService.post('/public/paid-service/open-contact', {
      target_user_id,
    });
  }

  buyContact(price_key: BuyContactsType) {
    return this.restService.post('/public/paid-service/buy/contacts', {
      price_key,
    });
  }
}
