import { Api } from 'api/api';
import type { ActionTree } from 'vuex';
import { INotificationSettings } from 'api/users/models/user';
import { Nullable } from 'core/types';
import { IApiPagination } from 'api/api-request';
import type { IStoreState } from '../interfaces/IStoreState';
import type { INotificationsState } from './interfaces';
import { NotificationsAction, NotificationsGetter, NotificationsMutation } from './enums';

export const notificationsActions: ActionTree<INotificationsState, IStoreState> = {
  [NotificationsAction.ListNotifications]: async ({ commit, getters }, fetchMore = false) => {
    if (!fetchMore) {
      commit(NotificationsMutation.ClearNotifications);
    }

    const pagination: Nullable<IApiPagination> = getters[NotificationsGetter.NotificationsPagination];
    const page = pagination ? pagination.meta.current_page + 1 : 1;

    const { data } = await Api.notificationService.getNotifications(page);

    commit(NotificationsMutation.SetNotifications, data);
  },

  [NotificationsAction.MarkAsRead]: async ({ commit }) => {
    await Api.notificationService.markAsRead();

    commit(NotificationsMutation.MarkAsRead);
  },

  [NotificationsAction.FetchNotificationSettings]: async ({ commit }) => {
    const { data } = await Api.notificationService.fetchNotificationSettings();

    commit(NotificationsMutation.SetNotificationSettings, data);
  },

  [NotificationsAction.ChangeNotificationSettings]: async ({ commit, getters }, settings: INotificationSettings) => {
    const oldSettings = getters[NotificationsGetter.NotificationSettings];

    commit(NotificationsMutation.SetNotificationSettings, settings);

    try {
      await Api.notificationService.setNotificationSettings(settings);
    } catch (e) {
      commit(NotificationsMutation.SetNotificationSettings, oldSettings);
      throw e;
    }
  },

  [NotificationsAction.ReadById]: async ({ commit }, id: string) => {
    const { data } = await Api.notificationService.readById(id);
    if (data.result) {
      commit(NotificationsMutation.UpdateNotification, data.notification);
    }
  },
};
