import FavoritesBig from 'assets/icons/big/favorites.inline.svg';
import FemaleBig from 'assets/icons/big/female.inline.svg';
import LockBig from 'assets/icons/big/lock.inline.svg';
import BlockBig from 'assets/icons/big/block.inline.svg';
import MaleBig from 'assets/icons/big/male.inline.svg';
import ImageBig from 'assets/icons/big/image.inline.svg';
import ResearchBig from 'assets/icons/big/research.inline.svg';
import GhostBig from 'assets/icons/big/ghost.inline.svg';
import PhonePersonContour from 'assets/icons/contour/phone-person.inline.svg';
import ArrowBackContour from 'assets/icons/contour/arrow-back.inline.svg';
import ArrowRightContour from 'assets/icons/contour/arrow-right.inline.svg';
import ArrowUncoverContour from 'assets/icons/contour/arrow-uncover.inline.svg';
import BanContour from 'assets/icons/contour/ban.inline.svg';
import BellContour from 'assets/icons/contour/bell.inline.svg';
import BlurContour from 'assets/icons/contour/blur.inline.svg';
import BrightnessContour from 'assets/icons/contour/brightness.inline.svg';
import CameraContour from 'assets/icons/contour/camera.inline.svg';
import CropContour from 'assets/icons/contour/crop.inline.svg';
import LightBulbContour from 'assets/icons/contour/light-bulb.inline.svg';
import CrossesContour from 'assets/icons/contour/crosses.inline.svg';
import CloseContour from 'assets/icons/contour/close.inline.svg';
import EyeClosedContour from 'assets/icons/contour/eye-closed.inline.svg';
import UserContour from 'assets/icons/contour/user.inline.svg';
import AddUserContour from 'assets/icons/contour/add-user.inline.svg';
import EyeContour from 'assets/icons/contour/eye.inline.svg';
import FlagContour from 'assets/icons/contour/flag.inline.svg';
import HeartContour from 'assets/icons/contour/heart.inline.svg';
import MagnifyingGlassContour from 'assets/icons/contour/magnifying-glass.inline.svg';
import PaintingContour from 'assets/icons/contour/painting.inline.svg';
import PhoneContour from 'assets/icons/contour/phone.inline.svg';
import HistoryContour from 'assets/icons/contour/history.inline.svg';
import PlayContour from 'assets/icons/contour/play.inline.svg';
import CountdownTimerContour from 'assets/icons/contour/countdown-timer.inline.svg';
import PencilContour from 'assets/icons/contour/pencil.inline.svg';
import MinusContour from 'assets/icons/contour/minus.inline.svg';
import PlusContour from 'assets/icons/contour/plus.inline.svg';
import SettingsContour from 'assets/icons/contour/settings.inline.svg';
import TelegramContour from 'assets/icons/contour/telegram.inline.svg';
import TextCenterContour from 'assets/icons/contour/text-center.inline.svg';
import TextLeftContour from 'assets/icons/contour/text-left.inline.svg';
import TextRightContour from 'assets/icons/contour/text-right.inline.svg';
import TypeContour from 'assets/icons/contour/type.inline.svg';
import WhatsappContour from 'assets/icons/contour/whatsapp.inline.svg';
import WheelContour from 'assets/icons/contour/wheel.inline.svg';
import StoryArrowLeftContour from 'assets/icons/contour/story-arrow-left.inline.svg';
import StoryArrowRightContour from 'assets/icons/contour/story-arrow-right.inline.svg';
import ExpandFill from 'assets/icons/fill/expand.inline.svg';
import AppsFill from 'assets/icons/fill/apps.inline.svg';
import ArrowRightFill from 'assets/icons/fill/arrow-right.inline.svg';
import BellFill from 'assets/icons/fill/bell.inline.svg';
import DotFill from 'assets/icons/fill/dot.inline.svg';
import DotsFill from 'assets/icons/fill/dots.inline.svg';
import ClockFill from 'assets/icons/fill/clock.inline.svg';
import TickFill from 'assets/icons/fill/tick.inline.svg';
import CancelFill from 'assets/icons/fill/cancel.inline.svg';
import FemaleFill from 'assets/icons/fill/female.inline.svg';
import HeartFill from 'assets/icons/fill/heart.inline.svg';
import HipMiddleFill from 'assets/icons/fill/hip-middle.inline.svg';
import HipRoundFill from 'assets/icons/fill/hip-round.inline.svg';
import HipSmallFill from 'assets/icons/fill/hip-small.inline.svg';
import MaleFill from 'assets/icons/fill/male.inline.svg';
import MessageFill from 'assets/icons/fill/message.inline.svg';
import PenFill from 'assets/icons/fill/pen.inline.svg';
import PencilFill from 'assets/icons/fill/pencil.inline.svg';
import BackArrowFill from 'assets/icons/fill/back-arrow.inline.svg';
import PhoneFill from 'assets/icons/fill/phone.inline.svg';
import PlacemarkFill from 'assets/icons/fill/placemark.inline.svg';
import MarkFill from 'assets/icons/fill/mark.inline.svg';
import DoubleMarkFill from 'assets/icons/fill/double-mark.inline.svg';
import PlayFill from 'assets/icons/fill/play.inline.svg';
import PlusFill from 'assets/icons/fill/plus.inline.svg';
import ProfileFill from 'assets/icons/fill/profile.inline.svg';
import RotationFill from 'assets/icons/fill/rotation.inline.svg';
import TrashFill from 'assets/icons/fill/trash.inline.svg';
import PremiumFill from 'assets/icons/fill/premium.inline.svg';
import SettingsFill from 'assets/icons/fill/settings.inline.svg';
import WalletFill from 'assets/icons/fill/wallet.inline.svg';
import CheckedFill from 'assets/icons/fill/checked.inline.svg';
import BellAltFill from 'assets/icons/fill/bell-alt.inline.svg';
import HomeFill from 'assets/icons/fill/home.inline.svg';
import GalleryContour from 'assets/icons/contour/gallery.inline.svg';
import RectangleContour from 'assets/icons/contour/rectangle.inline.svg';
import PinFill from 'assets/icons/fill/pin.inline.svg';
import EmailFill from 'assets/icons/fill/email.inline.svg';
import DeleteContour from 'assets/icons/contour/delete.inline.svg';
import LogoutFill from 'assets/icons/fill/logout.inline.svg';
import Chest1Fill from 'assets/icons/fill/chest-1.inline.svg';
import Chest2Fill from 'assets/icons/fill/chest-2.inline.svg';
import Chest3Fill from 'assets/icons/fill/chest-3.inline.svg';
import PinContour from 'assets/icons/contour/pin.inline.svg';
import QuestionContour from 'assets/icons/contour/question.inline.svg';
import SettingContour from 'assets/icons/contour/setting.inline.svg';
import LockContour from 'assets/icons/contour/lock.inline.svg';
import EmailContour from 'assets/icons/contour/email.inline.svg';
import WalletContour from 'assets/icons/contour/wallet.inline.svg';
import DeleteAltContour from 'assets/icons/contour/delete-alt.inline.svg';
import CopyContour from 'assets/icons/contour/copy.inline.svg';
import Copy2Contour from 'assets/icons/contour/copy-2.inline.svg';
import AttachmentContour from 'assets/icons/contour/attachment.inline.svg';
import CreditCardFill from 'assets/icons/fill/credit-card.inline.svg';
import CreditCard2Fill from 'assets/icons/fill/credit-card-2.inline.svg';
import EyeFill from 'assets/icons/fill/eye.inline.svg';
import LoveFill from 'assets/icons/fill/love.inline.svg';
import UserFill from 'assets/icons/fill/user.inline.svg';
import VerifiedFill from 'assets/icons/fill/verified.inline.svg';
import ChevronDownContour from 'assets/icons/contour/chevron-down.inline.svg';

export const IconSet = {
  Big: {
    Favorites: FavoritesBig,
    Female: FemaleBig,
    Block: BlockBig,
    Lock: LockBig,
    Male: MaleBig,
    Research: ResearchBig,
    Ghost: GhostBig,
    Image: ImageBig,
  } as const,
  Contour: {
    AddUser: AddUserContour,
    User: UserContour,
    ArrowBack: ArrowBackContour,
    ArrowRight: ArrowRightContour,
    ArrowUncover: ArrowUncoverContour,
    Attachment: AttachmentContour,
    Ban: BanContour,
    Bell: BellContour,
    Blur: BlurContour,
    Brightness: BrightnessContour,
    Camera: CameraContour,
    Crop: CropContour,
    Crosses: CrossesContour,
    EyeClosed: EyeClosedContour,
    Pencil: PencilContour,
    Eye: EyeContour,
    Flag: FlagContour,
    Heart: HeartContour,
    MagnifyingGlass: MagnifyingGlassContour,
    Painting: PaintingContour,
    Phone: PhoneContour,
    Play: PlayContour,
    Plus: PlusContour,
    Minus: MinusContour,
    Settings: SettingsContour,
    History: HistoryContour,
    Telegram: TelegramContour,
    TextCenter: TextCenterContour,
    TextLeft: TextLeftContour,
    TextRight: TextRightContour,
    Type: TypeContour,
    Whatsapp: WhatsappContour,
    Wheel: WheelContour,
    Gallery: GalleryContour,
    Rectangle: RectangleContour,
    Delete: DeleteContour,
    LightBulb: LightBulbContour,
    Pin: PinContour,
    Lock: LockContour,
    Email: EmailContour,
    CountdownTimer: CountdownTimerContour,
    Setting: SettingContour,
    Wallet: WalletContour,
    DeleteAlt: DeleteAltContour,
    Question: QuestionContour,
    Copy: CopyContour,
    Copy2: Copy2Contour,
    ChevronDown: ChevronDownContour,
    StoryArrowLeft: StoryArrowLeftContour,
    StoryArrowRight: StoryArrowRightContour,
    Close: CloseContour,
    PhonePerson: PhonePersonContour,
  } as const,
  Fill: {
    Apps: AppsFill,
    ArrowRight: ArrowRightFill,
    Bell: BellFill,
    Dot: DotFill,
    Dots: DotsFill,
    Female: FemaleFill,
    Heart: HeartFill,
    HipMiddle: HipMiddleFill,
    HipRound: HipRoundFill,
    HipSmall: HipSmallFill,
    Male: MaleFill,
    Message: MessageFill,
    Tick: TickFill,
    Pen: PenFill,
    Pencil: PencilFill,
    Placemark: PlacemarkFill,
    Play: PlayFill,
    Settings: SettingsFill,
    Cancel: CancelFill,
    Wallet: WalletFill,
    Clock: ClockFill,
    Plus: PlusFill,
    Profile: ProfileFill,
    Rotation: RotationFill,
    Trash: TrashFill,
    Premium: PremiumFill,
    Checked: CheckedFill,
    BellAlt: BellAltFill,
    Home: HomeFill,
    Pin: PinFill,
    Email: EmailFill,
    Logout: LogoutFill,
    Chest1: Chest1Fill,
    Chest2: Chest2Fill,
    Chest3: Chest3Fill,
    CreditCard: CreditCardFill,
    CreditCard2: CreditCard2Fill,
    Eye: EyeFill,
    Love: LoveFill,
    BackArrow: BackArrowFill,
    Mark: MarkFill,
    Phone: PhoneFill,
    DoubleMark: DoubleMarkFill,
    User: UserFill,
    Verified: VerifiedFill,
    Expand: ExpandFill,
  } as const,
} as const;
