import { Api } from 'api/api';
import { IAppearance, IProfile, IUser, Sex } from 'api/users/models/user';
import { IClaimInput } from 'api/users/models/claims';
import { ActionTree } from 'vuex';
import dayjs, { Dayjs } from 'dayjs';
import { IUserFilter } from 'api/users/models/user/interfaces/IUserFilter';
import { FileSource, ICity, Nullable } from 'core/types';
import { CITIES_STORE_KEY, CitiesGetter } from 'store/cities';
import { formatUserFilter } from 'core/helpers/formatUserFilter';
import { IContact } from 'api/users/models/contact';
import { SuccessCatalogType } from 'api/users/models/successCatalog';
import { PaidPackageResponse } from 'api/users/models/paidPackages/interfaces';
import { BuyContactsType } from 'api/userService';
import { IStoreState } from '../interfaces/IStoreState';
import { UsersGetter } from './enums/UsersGetter';
import { UsersAction } from './enums/UsersAction';
import { UsersMutation } from './enums/UsersMutation';
import { IUsersState } from './interfaces/IUsersState';

function getProfileForUpdate(data: Partial<IProfile>, birth: string) {
  return {
    name: data.name,
    birth,
    city_id: data.city?.id,
    height: data.height,
    weight: data.weight,
    description: data.description,
    appearance: data.appearance,
    typical_photo: data.typical_photo,
    properties: {
      ...data.properties,
      contacts: data.contacts,
    },
  };
}

export const usersActions: ActionTree<IUsersState, IStoreState> = {
  [UsersAction.CheckAuth]: async ({ getters, dispatch }) => {
    try {
      if (getters[UsersGetter.AuthToken]) {
        if (getters[UsersGetter.Profile]) return true;

        await dispatch(UsersAction.GetProfile);

        return true;
      }

      return false;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('[Fetch Profile Error]:', error);
      return false;
    }
  },
  [UsersAction.GetProfile]: async ({ getters, commit }) => {
    if (!getters[UsersGetter.AuthToken]) return;
    let profile;
    try {
      profile = await Api.userService.getProfile();
    } finally {
      if (profile) {
        commit(UsersMutation.SetProfile, profile);
      } else {
        commit(UsersMutation.ClearAuthToken);
      }
    }
  },

  [UsersAction.ChangeTheme]: async ({ getters, commit }, appearance: IAppearance) => {
    if (!getters[UsersGetter.AuthToken]) return;
    const profile = getters[UsersGetter.Profile];
    const oldAppearance = profile.appearance;

    commit(UsersMutation.UpdateProfile, {
      appearance,
    });

    try {
      await Api.userService.patchProfile({ appearance });
    } catch (e) {
      commit(UsersMutation.UpdateProfile, {
        appearance: oldAppearance,
      });
      throw e;
    }
  },

  [UsersAction.AddContacts]: async ({ getters, commit }, contacts: IContact[]) => {
    const { data } = await Api.userService.patchProfile({
      properties: {
        // @ts-ignore
        contacts,
      },
    });
    commit(UsersMutation.SetProfile, data);
  },

  [UsersAction.EditProfile]: async (
    { getters, commit },
    data: Partial<IProfile & { birthdate: Dayjs }>,
  ) => {
    if (!getters[UsersGetter.AuthToken]) return;
    const profile = getters[UsersGetter.Profile];

    const payload =
      getProfileForUpdate(data, data.birthdate?.format('DD.MM.YYYY') || dayjs(profile.birth).format('DD.MM.YYYY'));

    const { data: newProfile, status } = await Api.userService.updateProfile(payload);

    if (status === 200) {
      commit(UsersMutation.SetProfile, newProfile);
    }
  },

  [UsersAction.GetUserById]: async (_, { id, withoutNotice }: { id: number, withoutNotice?: boolean }) => {
    const { data } = await Api.userService.getUserById(id, withoutNotice);
    return data;
  },

  [UsersAction.GetCatalog]: async ({ state, getters, commit, rootGetters }, fetchMore = false) => {
    if (!fetchMore) {
      commit(UsersMutation.ClearUserList);
    }

    const hasFilter = getters[UsersGetter.HasFilter] as boolean;
    const filter = getters[UsersGetter.Filter] as IUserFilter;
    const profile = getters[UsersGetter.Profile] as IUser;
    const page = state.listPagination?.meta.current_page ?? 1;

    const payload = {
      city_id: state.listCityId || profile?.city_id || null,
      page: fetchMore ? page + 1 : page,
      gender: (profile && (profile.gender === Sex.Male ? Sex.Female : Sex.Male)),
    } as IUserFilter & { page?: number };

    if (hasFilter) {
      Object.assign(payload, formatUserFilter(filter));
    }

    const result = await Api.userService.getUsers(payload);

    const items = result.data || [];

    if (fetchMore) {
      commit(UsersMutation.SetUserList, items);
    } else {
      commit(UsersMutation.SetUserList, state.list.slice().concat(items));
    }

    commit(UsersMutation.SetUserListPagination, result);
  },

  [UsersAction.PutClaim]: async (_, input: IClaimInput) => {
    await Api.userService.sendClaim(input.userId, input.type);
  },

  [UsersAction.PutGeolocationCoords]: async ({ getters, commit }, coords: GeolocationCoordinates) => {
    if (!getters[UsersGetter.AuthToken]) return;

    await Api.userService.geolocationCoords(coords);
    commit(UsersMutation.SetGeolocationCoords, coords);
  },

  [UsersAction.AddPhoto]: async ({ commit, getters }, { photo, onUploadProgress }:
    { photo: File, onUploadProgress?: (progress: number) => void }) => {
    if (!getters[UsersGetter.AuthToken]) return;

    const formData = new FormData();
    formData.append('photo', photo);
    const { data: { profile: updatedProfile } } = await Api.userService.addPhoto(formData, onUploadProgress);
    commit(UsersMutation.SetProfile, updatedProfile);
  },
  [UsersAction.AddPrivatePhoto]: async ({ commit, getters }, { photo, onUploadProgress }:
    { photo: File, onUploadProgress?: (progress: number) => void }) => {
    if (!getters[UsersGetter.AuthToken]) return;

    const formData = new FormData();
    formData.append('photo', photo);
    formData.append('type', 'private');
    const { data: { profile: updatedProfile } } = await Api.userService.addPhoto(formData, onUploadProgress);
    commit(UsersMutation.SetProfile, updatedProfile);
  },
  [UsersAction.DeletePhoto]: async ({ getters, commit }, id: number) => {
    if (!getters[UsersGetter.AuthToken]) return;

    const profile = getters[UsersGetter.Profile] as IProfile;

    const photos = profile.photos.filter((item) => item.id !== id);
    const private_photos = profile.private_photos.filter((item) => item.id !== id);
    commit(UsersMutation.UpdateProfile, { photos, private_photos });
    commit(UsersMutation.SetMainPhoto);
    await Api.userService.deletePhoto(id);
  },
  [UsersAction.WalletEnroll]: async ({ getters, dispatch }, amount: number) => {
    if (!getters[UsersGetter.AuthToken]) return;

    const { data } = await Api.userService.walletEnroll(amount);

    if (data) {
      await dispatch(UsersAction.GetProfile);
    }
  },
  [UsersAction.RaiseProfile]: async ({ dispatch }) => {
    await Api.userService.raiseProfile();
    await dispatch(`${UsersAction.GetProfile}`, null);
  },

  [UsersAction.FetchNewUsers]: async ({ commit, getters, state, rootGetters }, fetchMore = false) => {
    const profile = getters[UsersGetter.Profile] as Nullable<IProfile>;
    if (!profile) return;
    const defaultCity = rootGetters[`${CITIES_STORE_KEY}/${CitiesGetter.DefaultCity}`] as ICity;

    const cityId = state.listCityId || profile?.city_id || defaultCity.id;
    const page = fetchMore ? 2 : 1;
    const gender = profile.oppositeGender as Sex;

    const { data } = await Api.userService.fetchNewUsers(cityId, gender, page);
    commit(UsersMutation.SetNewUsers, data.data);
    commit(UsersMutation.SetTotalNewUsers, data.meta.total);
  },

  [UsersAction.FetchTopUsers]: async ({ commit, getters, state, rootGetters }, fetchMore = false) => {
    const profile = getters[UsersGetter.Profile] as Nullable<IProfile>;
    if (!profile) return;
    const defaultCity = rootGetters[`${CITIES_STORE_KEY}/${CitiesGetter.DefaultCity}`] as ICity;

    const cityId = state.listCityId || profile?.city_id || defaultCity.id;
    const page = fetchMore ? 2 : 1;
    const gender = profile.oppositeGender as Sex;

    const { data } = await Api.userService.fetchTopUsers(cityId, gender, page);
    commit(UsersMutation.SetTopUsers, data.data);
    commit(UsersMutation.SetTotalTopUsers, data.meta.total);
  },

  [UsersAction.FetchFemaleUsers]: async ({ commit }, fetchMore = false) => {
    const { data } = await Api.userService.fetchFemaleUsers(fetchMore ? 2 : 1);

    commit(UsersMutation.SetFemaleUsers, data.data);
    commit(UsersMutation.SetTotalFemaleUsers, data.meta.total);
  },

  [UsersAction.FetchMaleUsers]: async ({ commit }, fetchMore = false) => {
    const { data } = await Api.userService.fetchMaleUsers(fetchMore ? 2 : 1);

    commit(UsersMutation.SetMaleUsers, data.data);
    commit(UsersMutation.SetTotalMaleUsers, data.meta.total);
  },

  [UsersAction.BlockUser]: async (_, id: number) => {
    await Api.userService.blockUser(id);
  },

  [UsersAction.UnblockUser]: async (_, id: number) => {
    await Api.userService.unblockUser(id);
  },

  [UsersAction.FetchFavorites]: async ({ state, commit }, fetchMore = false) => {
    if (!fetchMore) {
      commit(UsersMutation.ClearFavorites);
    }

    const page = state.favoritesPagination?.meta.current_page ?? 1;

    const { data: result } = await Api.userService.fetchFavorites(fetchMore ? page + 1 : page);

    const items = result.data || [];

    if (fetchMore) {
      commit(UsersMutation.SetFavorites, items);
    } else {
      commit(UsersMutation.SetFavorites, state.favorites.slice().concat(items));
    }

    commit(UsersMutation.SetFavoritesPagination, result);
  },

  [UsersAction.FetchSuccessUsers]: async ({ state, commit, getters }, fetchMore = false) => {
    if (!fetchMore) {
      commit(UsersMutation.ClearSuccessUsers);
    }
    const profile = getters[UsersGetter.Profile] as Nullable<IProfile>;
    if (!profile) return;

    const page = state.successUsersPagination?.meta.current_page ?? 1;
    const type = profile.oppositeGender === Sex.Male ?
      SuccessCatalogType.TYPE_SUCCESS_MEN : SuccessCatalogType.TYPE_SUCCESS_WOMEN;

    const { data: result } =
      await Api.userService.fetchSuccessCatalog(fetchMore ? page + 1 : page, type, profile.city_id);

    const items = result.data || [];

    if (fetchMore) {
      commit(UsersMutation.SetSuccessUsers, items);
    } else {
      commit(UsersMutation.SetSuccessUsers, state.successUsers.slice().concat(items));
    }

    commit(UsersMutation.SetSuccessUsersPagination, result);
  },

  [UsersAction.SetAsMain]: async ({ commit, getters }, photoId: number) => {
    const prevMain: Nullable<FileSource> = getters[UsersGetter.ProfileMainPhoto];
    commit(UsersMutation.SetAsMain, photoId);
    try {
      await Api.userService.selectAsMain(photoId);
    } catch (e) {
      if (prevMain) commit(UsersMutation.SetAsMain, prevMain.id);
      throw e;
    }
  },

  [UsersAction.ChangeEmail]: async ({ commit }, payload: any) => {
    const { data } = await Api.userService.changeEmail(payload);
    commit(UsersMutation.SetProfile, data.profile);
  },

  [UsersAction.FetchPaidPackages]: async ({ commit }) => {
    const { data } = await Api.userService.fetchPaidPackages();
    commit(UsersMutation.SetPaidPackages, data.data);
    commit(UsersMutation.SetPaidPackageDiscount, {
      discount_for_all_expired_at: data.discount_for_all_expired_at,
      discount_percent: data.discount_percent,
    } as Omit<PaidPackageResponse, 'data'>);
  },

  [UsersAction.BuyPaidPackages]: async ({ commit }, id: number) => {
    const { data } = await Api.userService.buyPaidPackage(id);
    commit(UsersMutation.SetProfile, data);
  },

  [UsersAction.FetchAuthLog]: async ({ state, commit }, fetchMore = false) => {
    if (!fetchMore) {
      commit(UsersMutation.ClearAuthLog);
    }

    const page = state.authLogPagination?.meta.current_page ?? 1;

    const { data: result } = await Api.userService.fetchAuthLog(fetchMore ? page + 1 : page);

    const items = result.data || [];

    if (fetchMore) {
      commit(UsersMutation.SetAuthLog, items);
    } else {
      commit(UsersMutation.SetAuthLog, state.authLog.slice().concat(items));
    }

    commit(UsersMutation.SetAuthLogPagination, result);
  },

  [UsersAction.GetReward]: async ({ dispatch }, stage: string) => {
    await Api.userService.getReward(stage);
    dispatch(UsersAction.GetProfile);
  },

  [UsersAction.BuyIncognito]: async ({ dispatch }, month: number) => {
    await Api.userService.buyIncognito(month);
    dispatch(UsersAction.GetProfile);
  },

  [UsersAction.BuyContact]: async ({ dispatch }, type: BuyContactsType) => {
    await Api.userService.buyContact(type);
    await dispatch(UsersAction.GetProfile);
  },

  [UsersAction.OpenContact]: async ({ dispatch }, id: number) => {
    const { data } = await Api.userService.openContact(id);
    await dispatch(UsersAction.GetProfile);

    return data.target_user;
  },
};
