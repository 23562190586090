import { defineComponent, ref } from 'vue';
import { TxtType } from 'components/Txt';
import { errorsPopupEventBus } from '../eventBus';
import { ErrorsPopupTypes } from '../types';

const RELOAD_PAGE_ON_ERROR_TIMEOUT = parseInt(process.env.VUE_APP_RELOAD_PAGE_ON_ERROR_TIMEOUT || '0', 10) * 1000;

const Popup = defineComponent({
  setup() {
    const isShow = ref(false);
    const type = ref<ErrorsPopupTypes>();

    errorsPopupEventBus.on('show', (_type: ErrorsPopupTypes) => {
      isShow.value = true;
      type.value = _type || ErrorsPopupTypes.UnknownError;

      if (RELOAD_PAGE_ON_ERROR_TIMEOUT) setTimeout(reloadPage, RELOAD_PAGE_ON_ERROR_TIMEOUT);
    });

    function reloadPage() {
      window.location.reload();
    }

    return {
      isShow,
      type,
      reloadPage,

      ErrorsPopupTypes,
      TxtType,
    };
  },
});

export default Popup;
