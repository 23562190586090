<template>
  <svg
    :class="{
      icon: true,
      [`icon_size-${size}`]: true,
      [`icon_color-${color}`]: !!color,
    }"
  >
    <use :xlink:href="`#${iconId}`" />
  </svg>
</template>

<script lang="ts" src="./Icon.ts"></script>
<style lang="scss" src="./icon.scss"></style>
