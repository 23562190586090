import Avatar from './Avatar';
import Icon from './Icon';
import Txt from './Txt';
import Tag from './Tag';
import Btn from './Btn';
import IconBtn from './IconBtn';
import Textfield from './Textfield';
import Checkbox from './Checkbox';
import Toggler from './Toggler';
import PhotoViewer from './PhotoViewer';
import ContextMenu from './ContextMenu';
import Modal from './Modal';
import Popup from './Popup';
import CopyText from './CopyText';
import RadioGroup from './RadioGroup';
import NoPrerender from './NoPrerender';
import Logo from './Logo';
import ShortPopup from './ShortPopup';
import PremiumIcon from './PremiumIcon';
import NotificationIcon from './AppNotifications/NotificationIcon.vue';
import IsMajorityWrapper from './IsMajorityWrapper';

export const globalComponents = {
  Avatar,
  Icon,
  Txt,
  Tag,
  Btn,
  IconBtn,
  Textfield,
  Checkbox,
  Toggler,
  PhotoViewer,
  ContextMenu,
  Modal,
  Popup,
  RadioGroup,
  Logo,
  NotificationIcon,
  CopyText,
  NoPrerender,
  ShortPopup,
  IsMajorityWrapper,
  PremiumIcon,
};
