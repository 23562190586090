import { computed, defineComponent } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useI18n } from 'vue-i18n';
import { UsersGetter, useUsersGetter } from 'store/users';
import { ThemeColor } from 'api/users/models/user';

const CopyText = defineComponent({
  props: {
    copiedText: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const { t } = useI18n();
    function handleClick(event: PointerEvent) {
      let text;
      if (props.copiedText) text = props.copiedText.trim();
      else {
        const target = event.target as HTMLDivElement;
        text = (target.textContent || '').trim();
      }
      if (copyToClipboard(text)) {
        toastEmitter.emit('toast', {
          type: ToastType.Success,
          message: t('components.copyText.success'),
        });
      } else {
        toastEmitter.emit('toast', {
          type: ToastType.Error,
          message: t('components.copyText.error'),
        });
      }
    }

    function copyToClipboard(string: string) {
      let textarea;
      let result;

      try {
        textarea = document.createElement('textarea');
        textarea.setAttribute('readonly', 'true');
        textarea.setAttribute('contenteditable', 'true');
        textarea.style.position = 'fixed';
        textarea.value = string;

        document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel?.removeAllRanges();
        sel?.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        result = document.execCommand('copy');
      } catch (err) {
        result = null;
      } finally {
        if (textarea) document.body.removeChild(textarea);
      }
      return result;
    }

    const activeThemeColor = computed(() =>
      useUsersGetter<ThemeColor>(UsersGetter.ThemeColor) || ThemeColor.Lavender);

    return {
      activeThemeColor,
      handleClick,

      IconName,
      IconType,
      Size,
    };
  },
});

export default CopyText;
