import { IProfile } from 'api/users/models/user/interfaces/IProfile';
import { User } from 'api/users/models/user/User';
import { Sex } from 'api/users/models/user/enums/Sex';
import { FileSource, Nullable } from 'core/types';
import { IContact } from 'api/users/models/contact';
import { IProfilePaidParams } from 'api/users/models/user/interfaces/IProfilePaidParams';
import { IProfilePaidPackage } from 'api/users/models/paidPackages/interfaces';
import { ITutorialStages } from 'api/users/models/user/interfaces/ITutorialStages';

export class Profile extends User implements IProfile {
  oppositeGender;
  contacts: IContact[];
  geolocation;
  geolocation_updated_at;
  transactions;
  balance;
  bonus_state;
  email;
  incognito_until;
  phone;
  private_photos: FileSource[];
  notifications;
  top;
  remaining_new_dialogues;
  conversation_deletions;
  highlight;
  common_info;
  tg_token;
  tg_subscribed;
  moderated;
  moderated_last_reason;
  profile_filled;
  paid_params: IProfilePaidParams[];
  paid_packages: IProfilePaidPackage[];
  tutorial_stages : ITutorialStages;
  available_contacts_open: Nullable<number>;
  available_see_contacts: Nullable<boolean>;

  constructor(raw: any) {
    super(raw);

    this.geolocation = raw.geolocation;
    this.geolocation_updated_at = raw.geolocation_updated_at ?? null;
    this.transactions = raw.transactions ?? null;
    this.balance = raw.balance;
    this.age = raw.age;
    this.name = raw.name;
    this.email = raw.email;
    this.phone = raw.phone ?? null;
    this.private_photos = raw.private_photos ?? [];
    this.notifications = raw.notifications ?? null;
    this.top = raw.top ?? null;
    this.remaining_new_dialogues = raw.remaining_new_dialogues ?? null;
    this.conversation_deletions = raw.conversation_deletions ?? null;
    this.highlight = raw.highlight ?? null;
    this.contacts = raw.contacts ?? [];
    this.bonus_state = raw.bonus_state;
    this.incognito_until = raw.incognito_until ?? null;

    this.available_see_contacts = raw.available_see_contacts ?? null;
    this.available_contacts_open = raw.available_contacts_open ?? null;

    this.oppositeGender = this.gender === Sex.Male ? Sex.Female : Sex.Male;
    this.common_info = raw.common_info ?? null;
    this.tg_token = raw.tg_token;
    this.tg_subscribed = raw.tg_subscribed;

    this.moderated = raw.moderated;
    this.moderated_last_reason = raw.moderated_last_reason ?? null;

    this.profile_filled = raw.profile_filled;
    this.paid_params = raw.paid_params || [];
    this.paid_packages = raw.paid_packages || [];
    this.tutorial_stages = raw.tutorial_stages;
  }
}
