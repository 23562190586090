import { computed, CSSProperties, defineComponent, PropType, toRefs } from 'vue';
import { BtnType } from './enums/BtnType';

const Btn = defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<BtnType>,
      default: BtnType.Primary,
    },
    w: {
      type: [Number, String] as PropType<CSSProperties['width']>,
      default: 'auto',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: '',
    },
    actionType: {
      type: String,
      default: 'button',
    },
  },

  setup(props) {
    const { w } = toRefs(props);

    const style = computed((): CSSProperties => ({ width: w.value }));

    function handleRouterLinkClick(e: any) {
      if (props.disabled) e.preventDefault();
    }

    return { style, handleRouterLinkClick };
  },
});

export default Btn;
