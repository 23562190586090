<template>
  <teleport to="#modals">
    <div
      v-if="isShow"
      class="app-cropper"
    >
      <div class="app-cropper__header">
        <icon-btn
          @click="back"
        >
          <icon
            :name="IconName.Crosses"
            :type="IconType.Contour"
            :color="MainColor.White"
            :size="Size.S"
          />
        </icon-btn>
        <btn
          v-if="isCropStarted"
          class="app-cropper__text-btn"
          :type="BtnType.Text"
          :disabled="isBusy || !isImageReady"
          @click="crop"
        >
          {{ $t('components.cropImage.ready') }}
        </btn>
        <btn
          v-if="!isCropStarted"
          class="app-cropper__text-btn"
          :disabled="isBusy || !isImageReady"
          :type="BtnType.Text"
          @click="save"
        >
          {{ $t('components.cropImage.save') }}
        </btn>
      </div>
      <crop-image
        v-if="image"
        ref="cropImageRef"
        :style="`opacity: ${isImageReady ? 1 : 0}`"

        :image="image"
        @close="handleClose"
        @save="handleSave"
        @image-ready-change="handleImageReadyChange"
        @is-crop-started="handleIsCropStarted"
        @error="handleError"
      />
      <spinner
        v-else
        class="app-cropper__spinner"
      />
      <div class="app-cropper__footer">
        <div
          v-if="isCropStarted"
          class="app-cropper__crop-footer"
        >
          <div class="app-cropper__tooltip">
            {{ $t('components.cropImage.square') }}
          </div>
          <icon-btn
            @click="rotate"
          >
            <icon
              :name="IconName.Rotation"
              :type="IconType.Fill"
              :size="Size.M"
              :color="MainColor.White"
            />
          </icon-btn>
        </div>
        <btn
          v-else
          :disabled="!isImageReady"
          class="app-cropper__text-btn app-cropper__crop-rotate"
          :type="BtnType.Text"
          @click="startCrop"
        >
          <icon
            class="pb-1"
            :name="IconName.Crop"
            :type="IconType.Contour"
            :color="MainColor.White"
            :size="Size.S"
          />
          {{ $t('components.cropImage.cropAndRotate') }}
        </btn>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts" src="./Cropper.ts" />
<style lang="scss" src="./cropper.scss" />
