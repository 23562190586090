import JSPusher from 'pusher-js';
import { RoomPusher } from './RoomPusher';
import { PusherInstance } from './pusher';
import { TypingPusher } from './TypingPusher';
import { NotificationPusher } from './NotificationPusher';
import { ModeratedPusher } from './ModeratedPusher';

class Pusher {
  private pusher?: JSPusher;
  private onConnectedCb?: () => void;
  private onDisconnectedCb?: () => void;

  public roomPusher: RoomPusher = new RoomPusher();
  public typingPusher: TypingPusher = new TypingPusher();
  public notificationPusher: NotificationPusher = new NotificationPusher();
  public moderatedPusher: ModeratedPusher = new ModeratedPusher();

  public disconnect() {
    this.roomPusher?.unsubscribe();
    this.typingPusher?.unsubscribe();
    this.notificationPusher?.unsubscribe();
    this.moderatedPusher.unsubscribe();

    this.pusher?.unbind_all();
    this.pusher?.disconnect();
  }

  public connect(authToken: string) {
    const pusher = new PusherInstance(authToken);
    if (this.onConnectedCb) pusher.connection.bind('connected', this.onConnectedCb);
    if (this.onDisconnectedCb) pusher.connection.bind('disconnected', this.onDisconnectedCb);

    this.roomPusher.setPusher(pusher);
    this.typingPusher.setPusher(pusher);
    this.notificationPusher.setPusher(pusher);
    this.moderatedPusher.setPusher(pusher);
    this.pusher = pusher;
  }

  isConnected() {
    return !!this.pusher && this.pusher.connection.state === 'connected';
  }

  onConnected(cb: () => void) {
    this.onConnectedCb = cb;
    this.pusher?.connection.bind('connected', this.onConnectedCb);
  }
  onDisconnected(cb: () => void) {
    this.onDisconnectedCb = cb;
    this.pusher?.connection.bind('disconnected', this.onDisconnectedCb);
  }
}

const pusher = new Pusher();

export default pusher;
export * from './interfaces';
export * from './enums';
