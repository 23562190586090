import Pusher from 'pusher-js';

export class PusherInstance extends Pusher {
  constructor(authToken: string) {
    super(`${process.env.VUE_APP_PUSHER_APP_KEY}`, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      authEndpoint: `${process.env.VUE_APP_API_URL}/public/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    });
  }
}
