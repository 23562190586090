export enum UsersMutation {
  SetProfile = 'SET_PROFILE',
  UpdateProfile = 'UPDATE_PROFILE',
  ClearAuthToken = 'CLEAR_AUTH_TOKEN',
  SetUser = 'SET_USER',
  SetUserList = 'SET_USER_LIST',
  SetUserListPagination = 'SET_USER_LIST_PAGINATION',
  ClearUserList = 'CLEAR_USER_LIST',
  SetFilter = 'SET_FILTER',
  ClearFilter = 'CLEAR_FILTER',
  SetAuthToken = 'SET_AUTH_TOKEN',
  ClearState = 'CLEAR_STATE',
  SetGeolocationRequired = 'SET_GEOLOCATION_REQUIRED',
  InitGeolocationRequired = 'INIT_GEOLOCATION_REQUIRED',
  SetGeolocationCoords = 'SET_GEOLOCATION_COORDS',
  UpdateThemeColor = 'UPDATE_THEME_COLOR',
  SetTopUsers = 'SET_TOP_USERS',
  SetNewUsers = 'SET_NEW_USERS',
  SetMaleUsers = 'SET_MALE_USERS',
  SetFemaleUsers = 'SET_FEMALE_USERS',
  SetTotalNewUsers = 'SET_TOTAL_NEW_USERS',
  SetTotalTopUsers = 'SET_TOTAL_TOP_USERS',
  SetTotalMaleUsers = 'SET_TOTAL_MALE_USERS',
  SetTotalFemaleUsers = 'SET_TOTAL_FEMALE_USERS',
  ClearMainPagePhotos = 'CLEAR_MAIN_PAGE_PHOTOS',
  DecrementConversationDeletions = 'DECREMENT_CONVERSATION_DELETIONS',
  SetFavorites = 'SET_FAVORITES',
  SetFavoritesPagination = 'SET_FAVORITES_PAGINATION',
  SetSuccessUsers = 'SET_SUCCESS_USERS',
  SetSuccessUsersPagination = 'SET_SUCCESS_USERS_PAGINATION',
  ClearFavorites = 'CLEAR_FAVORITES',
  ClearSuccessUsers = 'CLEAR_SUCCESS_USERS',
  SetAsMain = 'SET_AS_MAIN',
  SetMainPhoto = 'SET_MAIN_PHOTO',
  UpdateUsersFavorite = 'UPDATE_USERS_FAVORITE',
  SetPaidPackages = 'SET_PAID_PACKAGES',
  SetPaidPackageDiscount = 'SET_PAID_PACKAGE_DISCOUNT',
  ClearAuthLog = 'CLEAR_AUTH_LOG',
  SetAuthLog = 'SET_AUTH_LOG',
  SetAuthLogPagination = 'SET_AUTH_LOG_PAGINATION',
}
