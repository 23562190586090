import { Size } from 'core/styles';
import { computed, defineComponent, PropType } from 'vue';

const Tag = defineComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    short: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<Size>,
      default: Size.M,
    },
  },

  setup(_, { slots }) {
    const hasMarker = computed((): boolean => !!slots?.marker);

    return { hasMarker };
  },
});

export default Tag;
