import { defineComponent, ref, watch } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';
import { ModeratedReason } from 'api/users/models/user/enums/ModeratedReason';
import { useI18n } from 'vue-i18n';
import { RouteNames } from 'router/names';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { Nullable } from 'core/types';
import { IProfile } from 'api/users/models/user';
import { ModeratedActions } from 'modules/notModerated/types';
import { notModeratedPopupEventBus } from '../eventBus';

const notModeratedPopup = defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();

    const text = ref();
    const actionText = ref();
    const actionTo = ref();

    const isShows = ref(false);
    function closePopup() {
      isShows.value = false;
    }

    function getMessage(action: ModeratedActions, key: string) {
      return t(`components.notModeratedPopup.${key}.message`, {
        cannotAction: t(`components.notModeratedPopup.cannotActions.${action}`),
      });
    }

    function handleEmailNotValidated(action: ModeratedActions) {
      text.value = getMessage(action, 'emailNotModerated');
      actionText.value = t('components.notModeratedPopup.emailNotModerated.action');
      actionTo.value = { name: RouteNames.TechnicalSupport };
    }

    function handleProfileNotFilled(action: ModeratedActions) {
      text.value = getMessage(action, 'profileNotFilled');
      actionText.value = t('components.notModeratedPopup.profileNotFilled.action');
      actionTo.value = { name: RouteNames.Profile, hash: '#edit' };
    }

    function handleModerateBlocked(action: ModeratedActions, reason?: Nullable<string>) {
      text.value = getMessage(action, 'moderateBlocked');
      if (reason) text.value += ` ${t('components.notModeratedPopup.moderateBlocked.reason', { reason })}`;
      actionText.value = t('components.notModeratedPopup.moderateBlocked.action');
      actionTo.value = { name: RouteNames.TechnicalSupport };
    }

    function handleNotModerated(action: ModeratedActions) {
      text.value = getMessage(action, 'notModerated');
      actionText.value = t('components.notModeratedPopup.notModerated.action');
      actionTo.value = { name: RouteNames.TechnicalSupport };
    }

    watch(() => route.path, closePopup);
    notModeratedPopupEventBus.on('show', (action: ModeratedActions) => {
      isShows.value = true;

      const profile = store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`] as Nullable<IProfile>;

      if (store.getters[`${USER_STORE_KEY}/${UsersGetter.EmailNotModerated}`]) handleEmailNotValidated(action);
      else if (store.getters[`${USER_STORE_KEY}/${UsersGetter.ProfileNotFilled}`]) handleProfileNotFilled(action);
      else if (profile?.moderated === ModeratedReason.ModeratedBlocked) handleModerateBlocked(action, profile.moderated_last_reason);
      else handleNotModerated(action);
    });

    return {
      isShows,
      text,
      actionText,
      actionTo,

      IconName,
      IconType,
      Size,
      RouteNames,
    };
  },
});

export default notModeratedPopup;
