import { Api } from 'api/api';

import type { Module } from 'vuex';
import type { ICity } from 'core/types';
import { getDefaultCitiesState } from 'store/cities/getDefaultCitiesState';
import type { IStoreState } from '../interfaces/IStoreState';

export const CITIES_STORE_KEY = 'cities';

export interface ICitiesState {
  items: ICity[]
}

export enum CitiesGetter {
  Cities = 'CITIES',
  DefaultCity = 'DEFAULT_CITY',
  CityById = 'CITY_BY_ID',
  CityBySlug = 'CITY_BY_SLUG',
}
export enum CitiesMutation {
  SetCities = 'SET_CITIES',
  ClearState = 'CLEAR_STATE',
}
export enum CitiesAction {
  ListCities = 'LIST_CITIES',
}

export const cities: Module<ICitiesState, IStoreState> = {
  namespaced: true,

  state: getDefaultCitiesState(),

  getters: {
    [CitiesGetter.Cities]: ({ items }) => items,
    [CitiesGetter.DefaultCity]: ({ items }) => items[0],
    [CitiesGetter.CityById]: ({ items }) => (id: number) => items.find((v) => v.id === id),
    [CitiesGetter.CityBySlug]: ({ items }) => (slug: string) => items.find((v) => v.slug === slug),
  },
  mutations: {
    [CitiesMutation.SetCities]: (state, items: ICity[]) => {
      state.items = items;
    },
  },
  actions: {
    [CitiesAction.ListCities]: async ({ commit, state }) => {
      if (state.items.length > 0) return;
      const { data } = await Api.citiesService.listCities();

      commit(CitiesMutation.SetCities, data);
    },
  },
};
