import { IUsersState } from 'store/users/interfaces/IUsersState';

export function getDefaultUsersState(): IUsersState {
  return {
    authToken: null,

    profile: null,

    geolocationRequired: true,

    list: [],
    listPagination: null,
    listCityId: null,

    filter: null,

    favorites: [],
    favoritesPagination: null,

    successUsers: [],
    successUsersPagination: null,

    authLog: [],
    authLogPagination: null,

    topUsers: [],
    topUsersTotal: 0,
    newUsers: [],
    newUsersTotal: 0,
    femaleUsers: [],
    femaleUsersTotal: 0,
    maleUsers: [],
    maleUsersTotal: 0,

    paidPackages: [],
    paidPackageDiscount: null,
  };
}
